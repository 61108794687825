<template>
  <section class="section-div new-custom-section" style="margin-top: 50px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <h5 class="title-abouts text-uppercase left-10">About</h5>
        </div>
        <div class="col-md-12" style="margin-top: 27px">
          <p class="text-about left-10">
            Furnich is a furniture one-stop<br />
            destination offering a wide range of<br />
            options for purchasing, designing,<br />
            manufacturing, and acquiring<br />
            furniture and accessories.<br />
          </p>
          <LocalizedLink class="text-dark-custom" :to="'/aboutus'">
            <img
              src="../../../public/assets/img/icons/right_fleche.png"
              style="width: 120px; margin: 32px 3px"
            />
          </LocalizedLink>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "../../libs/axios";
import LocalizedLink from "@/components/LocalizedLink";

export default {
  components: { LocalizedLink },

  data() {
    return {
      data: {},
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(
          process.env.VUE_APP_BASE_URL + "/api/front/concept"
        );
        this.data = response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
};
</script>

<style scoped></style>
