<template>
  <ul class="pagination pagination-md">
    <li class="page-item">
      <a
        href="#"
        @click.prevent="onClickFirstPage"
        :class="isInFirstPage ? 'disabled' : ''"
        :disabled="isInFirstPage"
        ><i class="fas fa-chevron-left"></i
      ></a>
    </li>
    <li class="page-item">
      <a
        href="#"
        @click.prevent="onClickPreviousPage"
        :class="isInFirstPage ? 'disabled ' : ''"
        :disabled="isInFirstPage"
      ></a>
    </li>
    <li :key="page.id" v-for="page in pages" class="page-item">
      <a
        href="#"
        @click.prevent="onClickPage(page.name)"
        :disabled="page.isDisabled"
        :class="{ active: isPageActive(page.name) }"
        >{{ page.name }}
      </a>
    </li>
    <li class="page-item">
      <a
        href="#"
        @click.prevent="onClickNextPage"
        :class="isInLastPage ? 'disabled' : ''"
        :disabled="isInLastPage"
        ><i class="fas fa-chevron-right"></i
      ></a>
    </li>
    <li class="page-item">
      <a
        href="#"
        @click.prevent="onClickLastPage"
        :class="isInLastPage ? 'disabled' : ''"
        :disabled="isInLastPage"
      ></a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "pagination",
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      if (this.totalPages === 0) {
        return true;
      }
      return this.currentPage === this.totalPages;
    },
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }
      // When on the last page
      if (this.totalPages < this.maxVisibleButtons) {
        return 1;
      }
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1;
      }
      // When in between
      return this.currentPage - 1;
    },
    endPage() {
      if (this.totalPages === 0) {
        return 1;
      }
      if (this.totalPages < this.maxVisibleButtons) {
        return this.totalPages;
      }
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.totalPages
      );
    },
    pages() {
      const range = [];
      for (let i = this.startPage; i <= this.endPage; i++) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }
      return range;
    },
  },
  methods: {
    onClickFirstPage() {
      if (this.isInFirstPage) {
        return false;
      }
      this.$emit("pagechanged", 1);
    },
    onClickPreviousPage() {
      if (this.isInFirstPage) {
        return false;
      }
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      if (this.isInLastPage) {
        return false;
      }
      this.$emit("pagechanged", this.currentPage + 1);
    },
    onClickLastPage() {
      if (this.isInLastPage) {
        return false;
      }
      this.$emit("pagechanged", this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
};
</script>
<style lang="scss" scoped>
.pagination {
  .page-item {
    padding: 20px;
    a {
      color: #114956;
    }
  }
  .page-link {
    background: transparent;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-family: Avenir, sans-serif;
    color: #fff;
    &:hover,
    &.active {
      background: #ffd975;
      border-radius: 50%;
      color: #121219;
    }
  }
}
button[disabled],
html input[disabled] {
  cursor: default;
  color: lightgray;
}

html[lang="ar"] .pagination {
  flex-direction: row-reverse;
}
</style>
