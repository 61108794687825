import http from "../../libs/axios";

export default {
  namespaced: true,
  state: {
    paginats: 0,
  },
  getters: {},
  mutations: {},
  actions: {
    register(ctx, userData) {
      return new Promise((resolve, reject) => {
        http
          .post("/front/register", { userData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
