var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"categories"},[_c('PageBanner'),_c('div',{staticClass:"top-page-text"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"row",staticStyle:{"background-color":"#eee9e5","width":"99.5%"}},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"div-category-first"},[_c('span',{staticClass:"text-category",on:{"click":function($event){return _vm.categoryShow()}}},[(!_vm.showcategory)?_c('span',[_vm._v("↓")]):_vm._e(),(_vm.showcategory)?_c('span',[_vm._v("↑")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("Category")))]),(_vm.showcategory)?_c('div',{staticClass:"top-10"},[_vm._m(3),_vm._m(4)]):_vm._e()]),_c('div',{staticClass:"div-category-item"},[_c('span',{staticClass:"text-category"},[_vm._v("↓ "+_vm._s(_vm.$t("Experience")))])]),_c('div',{staticClass:"div-category-item"},[_c('span',{staticClass:"text-category"},[_vm._v("↓ "+_vm._s(_vm.$t("Reviews")))])]),_c('div',{staticClass:"div-category-item"},[_c('span',{staticClass:"text-category"},[_vm._v("↓ "+_vm._s(_vm.$t("Location")))])]),_c('div',{staticClass:"div-category-item border-bottom-black"},[_c('span',{staticClass:"text-category"},[_vm._v("↓ "+_vm._s(_vm.$t("Price Range")))])])]),_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"row",staticStyle:{"padding":"0 1.5%"}},[_c('div',{staticClass:"col-md-4 mb-4 top-40"},[_c('LocalizedLink',{staticClass:"product-link",attrs:{"to":'onecustomize'}},[_c('img',{staticClass:"img-fluid img-custom-size",staticStyle:{"border-radius":"23px"},attrs:{"src":require("../../public/assets/img/page/avatar-4.jpg"),"alt":"Furniture"}}),_c('div',{staticClass:"product-info d-block"},[_c('h4',{staticClass:"text-dark"},[_vm._v("test")]),_c('div',{staticClass:"blue-dark"},[_vm._v("2 Years experience")]),_c('div',{staticClass:"price"},[_vm._v("5 Projects")])])])],1),_vm._m(5),_vm._m(6)])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-before"},[_c('span',[_vm._v("CUSTOMIZE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"first-title-page padding-100"},[_vm._v(" Request a custom design for"),_c('br'),_vm._v(" your home, office, and any space"),_c('br'),_vm._v(" you want through a list of"),_c('br'),_vm._v(" distinguished designers ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-after"},[_c('span',[_vm._v("CUSTOMIZE > VILLA > DESIGNERS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-check"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox","value":"","id":"flexCheckDefault1"}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"flexCheckDefault1"}},[_c('span',{staticClass:"text-category"},[_vm._v("Villa")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-check"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox","value":"","id":"flexCheckDefault2"}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"flexCheckDefault2"}},[_c('span',{staticClass:"text-category"},[_vm._v("Apartment")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 mb-4 top-40"},[_c('img',{staticClass:"img-fluid img-custom-size",staticStyle:{"border-radius":"23px"},attrs:{"src":require("../../public/assets/img/page/avatar-2.jpg"),"alt":"Furniture"}}),_c('div',{staticClass:"product-info d-block"},[_c('h4',{staticClass:"text-dark"},[_vm._v("test")]),_c('div',{staticClass:"blue-dark"},[_vm._v("30 Years experience")]),_c('div',{staticClass:"price"},[_vm._v("15 Projects")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 mb-4 top-40"},[_c('img',{staticClass:"img-fluid img-custom-size",staticStyle:{"border-radius":"23px"},attrs:{"src":require("../../public/assets/img/page/avatar-3.jpg"),"alt":"Furniture"}}),_c('div',{staticClass:"product-info d-block"},[_c('h4',{staticClass:"text-dark"},[_vm._v("test")]),_c('div',{staticClass:"blue-dark"},[_vm._v("14 Years experience")]),_c('div',{staticClass:"price"},[_vm._v("30 Projects")])])])
}]

export { render, staticRenderFns }