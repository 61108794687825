import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Home.vue";
import Shop from "@/views/categories/Shop";
import ContactUs from "@/views/contactUs/ContactUs";
import LoginAndRegister from "@/views/login/LoginAndRegister";
import Register from "@/views/login/Register";
import Product from "@/views/product/Product";
import Products from "@/views/product/Products";

import Categories from "@/views/categories/Categories";
import Mades from "@/views/mades/Mades";
import MadeCategories from "@/views/mades/MadeCategories";
import Unfound from "@/views/Unfound";
import Cart from "@/views/Cart";
import Checkout from "@/views/Checkout";
import Finalize from "@/views/Finalize";
import AllShop from "@/views/AllShop";
import Customize from "@/views/Customize";
import OneShop from "@/views/OneShop";
import OneCustomize from "@/views/OneCustomize";
import NewLogin from "@/views/NewLogin";
import NewRegister from "@/views/NewRegister";
import Portfolio from "@/views/Portfolio";
import newDashboard from "@/views/newDashboard";

import Error from "@/views/Error";
import Whishlist from "@/views/product/Whishlist";
import ResetPassword from "@/views/ResetPassword";
import Account from "@/views/Account";
import Dashboard from "@/views/login/Dashboard";

//abouts
import AboutUs from "@/views/abouts/AboutUs";
import PrivacyPolicy from "@/views/abouts/PrivacyPolicy";
import ReturnRefunds from "@/views/abouts/ReturnRefunds";
import TermsOfService from "@/views/abouts/TermsOfService";
import WhyShopUs from "@/views/abouts/WhyShopUs";

import Root from "./Root";
import i18n, { loadLocaleMessagesAsync } from "@/i18n";
import {
  setDocumentDirectionPerLocale,
  setDocumentLang,
  setDocumentTitle,
} from "../util/i18n/document";
import { checkProductInLocalStorage } from "@/checkProductInDatabase";

const { locale } = i18n;

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: locale,
  },
  {
    path: "/:locale",
    component: Root,
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "contact",
        name: "Contact",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: ContactUs,
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "products",
        name: "Products",
        component: Products,
      },
      {
        path: "shop/:id",
        name: "Shop",
        component: Shop,
      },
      {
        path: "cart",
        name: "Cart",
        component: Cart,
        meta: {
          requiresProductCheck: true,
        },
      },
      {
        path: "checkout",
        name: "Checkout",
        component: Checkout,
        meta: {
          requiresProductCheck: true,
        },
      },
      {
        path: "whishlist",
        name: "Whishlist",
        component: Whishlist,
      },
      {
        path: "account",
        name: "Account",
        component: Account,
      },
      {
        path: "resetPassword",
        name: "ResetPassword",
        component: ResetPassword,
      },
      {
        path: "product/:id",
        component: Product,
        name: "Product",
        // props: true,
        // meta: { title: 'Product', noCache: true },
        // hidden: true,
      },
      {
        path: "login",
        name: "Login",
        component: LoginAndRegister,
      },

      {
        path: "register",
        name: "Register",
        component: Register,
      },
      {
        path: "categories",
        name: "Categories",
        component: Categories,
      },

      {
        path: "mades",
        name: "Mades",
        component: Mades,
      },
      {
        path: "finalize/:invoiceId",
        name: "apps-finalize-checkout",
        component: Finalize,
      },

      {
        path: "errorpage",
        name: "apps-error-page",
        component: Error,
      },

      {
        path: "made/:id/categories",
        name: "Made",
        component: MadeCategories,
      },

      //abouts

      {
        path: "aboutus",
        name: "AboutUs",
        component: AboutUs,
      },

      {
        path: "privacypolicy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
      },

      {
        path: "returnrefunds",
        name: "ReturnRefunds",
        component: ReturnRefunds,
      },

      {
        path: "termsofservice",
        name: "TermsOfService",
        component: TermsOfService,
      },

      {
        path: "whyshopus",
        name: "WhyShopUs",
        component: WhyShopUs,
      },

      {
        path: "allshop",
        name: "AllShop",
        component: AllShop,
      },
      {
        path: "customize",
        name: "Customize",
        component: Customize,
      },

      {
        path: "portfolio",
        name: "Portfolio",
        component: Portfolio,
      },
      {
        path: "newLogin",
        name: "NewLogin",
        component: NewLogin,
      },
      {
        path: "newRegister",
        name: "NewRegister",
        component: NewRegister,
      },

      {
        path: "newDashboard",
        name: "newDashboard",
        component: newDashboard,
      },

      {
        path: "oneshop",
        name: "OneShop",
        component: OneShop,
      },

      {
        path: "onecustomize",
        name: "OneCustomize",
        component: OneCustomize,
      },

      //endabouts

      {
        path: ":catchAll(.*)",
        name: "Unfound",
        component: Unfound,
        meta: {
          title: "Not Found",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  linkActiveClass: "active", // active class for non-exact links.
  linkExactActiveClass: "active", // active class for *exact* links.
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
});

router.beforeEach(async (to, from, next) => {
  const { locale } = to.params;

  loadLocaleMessagesAsync(locale).then(() => {
    setDocumentLang(locale);
    setDocumentDirectionPerLocale(locale);
    setDocumentTitle(i18n.t("apps.name"));
  });

  if (to.meta.requiresProductCheck) {
    try {
      await checkProductInLocalStorage();
    } catch (error) {
      throw error;
    }
  }
  next();
});

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
});

export default router;
