<template>
  <div class="categories">
    <PageBanner />
    <section id="categories_list">
      <div class="container px-4 py-5">
        <div class="row">
          <div class="col-12 mb-5">
            <h2 class="text-uppercase text-center">
              {{ $t("Discover Our Products") }}
            </h2>
          </div>
        </div>
        <div class="row" v-if="madesData">
          <div
            class="col-md-3 mb-4"
            v-for="(category, key) in madesData.products"
            :key="category.id"
          >
            <div class="card category-miniature position-relative">
              <LocalizedLink
                :to="'/shop/' + madesData.products[key][0].category.slug"
              >
                <img
                  :src="madesData.products[key][0].category.photo"
                  class="card-img-top img-custom-size"
                  alt="..."
                />
                <div class="card-body">
                  <h5 class="card-title">
                  <span class="text-dark"> {{ key }}</span>  
                  </h5>
                </div>
              </LocalizedLink>
            </div>
          </div>
        </div>
      </div>
    </section>
    <GreenBg />
  </div>
</template>

<script>
import PageBanner from "../../components/PageBanner.vue";
import GreenBg from "../../components/BeforeFooter/GreenBg.vue";
import LocalizedLink from "@/components/LocalizedLink";
import { ref, onUnmounted, onMounted } from "vue";
import store from "@/store";
import madesStoreModule from "./madesStoreModule";
import router from "@/router";
export default {
  components: { PageBanner, GreenBg, LocalizedLink },

  setup() {
    const madesData = ref(null);
    const HOME_APP_STORE_MODULE_NAME = "app-category";
    onMounted(() => {
      getCategories();
    });

    // const router = context.root.$router;

    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) {
      store.registerModule(HOME_APP_STORE_MODULE_NAME, madesStoreModule);
    } else {
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
          store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
      });
    }
    const getCategories = () => {
      store
        .dispatch("app-category/fetchMadeCategories", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          madesData.value = response.data.data;
          console.log(response.data.data, "dddddddddddddddddddddddddd");
        })
        .catch(() => {});
    };

    return {
      madesData,
      getCategories,
    };
  },
};
</script>

<style scoped>
.categories .card {
  border-radius: 20px;
  border: none;
}
</style>
