<template>
  <div class="shop">
    <PageBanner />

    <section class="grid-products">
      <div class="container">
        <div class="search_bars">
          <div class="row align-items-center mb-5">
            <div class="col-md-3">
              <h6 class="text-brown text-uppercase">
                {{ $t("Showing") }} {{ page }}-{{ recordsPerPage }} <br />{{
                  $t("of")
                }}
                {{ totalRecords }}
                {{ $t("Results") }}
              </h6>
            </div>
            <div
              class="col-md-9 d-flex align-items-center justify-content-between"
            >
              <form class="me-3 FilterByBrands" role="search">
                <input
                  class="form-control me-2"
                  type="search"
                  :placeholder="$t('Search by Product')"
                  aria-label="Search"
                />
              </form>

              <form class="FilterByPrice me-3" role="search">
                <input
                  class="form-control me-2"
                  type="search"
                  :placeholder="$t('Search by Brand')"
                  aria-label="Search"
                />
              </form>

              <form class="filterByCategories" role="search">
                <input
                  class="form-control me-2"
                  type="search"
                  :placeholder="$t('Search by Brand')"
                  aria-label="Search"
                />
              </form>
            </div>
          </div>
        </div>
        <div class="row" v-if="productsData">
          <div
            class="col-md-3 mb-4"
            v-for="product in productsData"
            :key="product.id"
          >
            <LocalizedLink
              :to="'product/' + product.slug"
              class="text-capitalize"
            >
              <img
                :src="product.couverture"
                alt="Furniture"
                class="img-fluid img-custom-size"
              />
              <div class="product-info d-block">
                <h4 class="product-name">
                  <span class="text-dark">{{ product.name }}</span>
                </h4>
                <div class="price">{{ product.price }} SAR</div>
              </div>
            </LocalizedLink>
          </div>

          <!--pagination-->
          <nav aria-label="Page navigation example" class="mt-4">
            <!-- <ul class="pagination pagination-md">
              <li class="page-item active" aria-current="page">
                <a class="page-link" href="#">1</a>
              </li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item"><a class="page-link" href="#">4</a></li>
              <li class="page-item"><a class="page-link" href="#">5</a></li>
              <li class="page-item"><a class="page-link" href="#">6</a></li>
            </ul> -->
            <Pagination
              v-if="productsData"
              :total-pages="Math.floor(paginats / recordsPerPage)"
              :per-page="recordsPerPage"
              :current-page="page"
              @pagechanged="onPageChange"
            />
          </nav>
        </div>
      </div>
    </section>

    <GreenBg />
  </div>
</template>

<script>
import PageBanner from "../../components/PageBanner.vue";
import GreenBg from "@/components/BeforeFooter/GreenBg";
import LocalizedLink from "@/components/LocalizedLink";
import categoriesStoreModule from "./categoriesStoreModule";
import Pagination from "@/components/pagination/Pagination";
import store from "@/store";
import { mapState } from "vuex";
import { ref, onUnmounted } from "vue";
import router from "@/router";

export default {
  components: { PageBanner, GreenBg, LocalizedLink, Pagination },
  computed: {
    ...mapState("app-category", ["paginats"]),
  },

  setup() {
    const productsData = ref(null);
    const EVENTS_APP_STORE_MODULE_NAME = "app-prod";
    const page = ref(1);

    const totalRecords = ref(0);
    const recordsPerPage = ref(5);

    // Register module
    if (!store.hasModule(EVENTS_APP_STORE_MODULE_NAME))
      store.registerModule(EVENTS_APP_STORE_MODULE_NAME, categoriesStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENTS_APP_STORE_MODULE_NAME))
        store.unregisterModule(EVENTS_APP_STORE_MODULE_NAME);
    });
    const LoadEvents = (pagecount, recordsPerPage) => {
      console.log(pagecount, recordsPerPage, "xxxx");
      store
        .dispatch("app-prod/fetchCategoryProducts", {
          pagecont: pagecount,
          queryParams: recordsPerPage,
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          console.log(response.data.data, "yyyyyyyyyyyy");
          totalRecords.value = response.data.meta.total;
          productsData.value = response.data.data;
          console.log(productsData.value, "rrrrrrrrr");
        })
        .catch(() => {});
    };

    const onPageChange = (p) => {
      page.value = p;
      LoadEvents(page.value, recordsPerPage.value);
    };
    LoadEvents(page.value, recordsPerPage.value);

    return {
      productsData,
      totalRecords,
      recordsPerPage,
      page,
      onPageChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  .page-item {
    padding: 20px;
  }
  .page-link {
    background: transparent;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-family: Avenir, sans-serif;
    color: #fff;
    &:hover,
    &.active {
      background: #ffd975;
      border-radius: 50%;
      color: #121219;
    }
  }
}
</style>
