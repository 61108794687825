<template>
  <div class="payment-success">
    <br />
    <br />
    <h1 class="text-success">message</h1>

    <div class="container mt-5" style="margin-bottom: 20px">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header bg-success text-white">Success</div>
            <div class="card-body">
              <h2 class="text-success">
                <b>Payment made successfully</b>
              </h2>
              <p></p>
              <br />
              <div class="table-responsive">
                <table
                  class="table table-nowrap table-hover table-centered m-0"
                >
                  <thead class="table-light">
                    <tr>
                      <th>Product</th>
                      <th>Description</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th class="text-right">Total amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="invoice in invoiceData" :key="invoice.id">
                      <td></td>
                      <td></td>
                      <td>
                        <span> SAR</span>
                      </td>
                      <td></td>
                      <td>
                        *
                        <span> SAR</span>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="6"
                        style="text-align: center; font-size: 16px"
                      >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <br />
              <a href=" url('/')" class="btn btn-primary"> Back to homepage </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    message: Boolean,
    invoiceData: Object,
  },
};
</script>
<style scoped>
.payment-success {
  text-align: center;
  padding: 20px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

p {
  font-size: 16px;
}
</style>
