<template>
    <div>
        <PageBanner />
        <section class="py-5 bg-white">
                <div class="row mt-3 align-items-center height-100vh text-center">
                    <div class="col-lg-8 m-auto">
                        
                        <h2 class="mb-30">{{ $t('404.content.title') }}</h2>
                        <p class="font-lg text-grey-700 mb-30 text-center">
                        {{ $t('404.content.desc') }}
                        </p>
                        <LocalizedLink :to="''" class="text-white btn btn-secondary hover-up">{{ $t('404.action') }}</LocalizedLink>
                    </div>
                </div>
        </section>
        <GreenBg />
    </div>
    
</template>


<script>
import LocalizedLink from "@/components/LocalizedLink"; 
import PageBanner from '@/components/PageBanner';
import GreenBg from '@/components/BeforeFooter/GreenBg';

export default {
    components: { LocalizedLink, PageBanner, GreenBg },
}
</script>
<style scoped>
    .lead {
        font-size: 17px;
        font-weight: 400;
        padding: 20px 0;
    }

    .text-arrow-icon {
        line-height: 1;
        display: inline-flex;
        align-items: center;
        margin-bottom: 50px;
        color: #2aa5a9;
        font-size: 1.5rem;
    }

    .text-arrow-icon:hover {
        color: #9e6850;
    }

    .feather {
        height: 1.2rem;
        width: 1.2rem;
        vertical-align: middle;
    }

 .btn.font-xs:hover{
   background: #492500;
 }

</style>