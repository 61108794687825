import http from "../../libs/axios";

export default {
  namespaced: true,
  state: {
    paginats: 0,
  },
  getters: {},
  mutations: {
    setPaginats(state, data) {
      console.log(state, data, "aaa");
      state.paginats = data;
    },
  },
  actions: {
    fetchProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        http
          .get(`/front/product/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    relatedProducts(ctx, category) {
      return new Promise((resolve, reject) => {
        http
          .post("/front/related-products", { category: category })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProducts({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        http
          .get(
            `/front/products?page=${queryParams.pagecont}&size=${queryParams.queryParams}`
          )
          .then((response) => {
            console.log(response, "responseaaaaaaa");
            commit("setPaginats", response.data.meta.total), resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchProductSize(ctx, productSize) {
      return new Promise((resolve, reject) => {
        http
          .post("/front/product-size", { productSize: productSize })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    addToWishlist(ctx, wishlist) {
      return new Promise((resolve, reject) => {
        http
          .post("/front/wishlist/add", { wishlist: wishlist })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchWishLists({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        http
          .get(
            `/front/wishlists?page=${queryParams.pagecont}&size=${queryParams.queryParams}`
          )
          .then((response) => {
            console.log(response, "responseaaaaaaa");
            commit("setPaginats", response.data.meta.total), resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    RemoveWishList(ctx, wishlist) {
      return new Promise((resolve, reject) => {
        http
          .post("/front/wishlist/remove", { wishlist: wishlist })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    

  },
};
