import axios from "axios";

export async function checkProductInLocalStorage() {
  try {
    let cart = JSON.parse(window.localStorage.getItem("cart"));
    const response = await axios.get(
      process.env.VUE_APP_BASE_URL + "/api/testproduct",
      { params: cart }
    );
    const productExists = response.data.success;
    if (!productExists) {
      localStorage.removeItem("cart");
      location.reload();
    }
  } catch (error) {
    throw error;
  }
}
