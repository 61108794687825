<template>
  <div class="left_sidebar">
    <div class="offcanvas-header d-flex align-items-center mt-2">
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        @click="setToggleNav"
      >
        <img src="../../../public/assets/img/icons/menu.png" alt="Furnish" />
      </button>
    </div>
    <div class="offcanvas-body mx-3">
      <div class="action-btns mb-5">
        <LocalizedLink :to="'login'" class="nav-link">
          {{ $t("Login") }}
        </LocalizedLink>
        <LocalizedLink :to="'login'" class="nav-link">
          {{ $t("Register") }}
        </LocalizedLink>
        <LocalizedLink :to="'account'" class="nav-link">
          {{ $t("Track Order") }}
        </LocalizedLink>
        <LocalizedLink class="nav-link" :to="'/contact'">
          {{ $t("Contact Us") }}
        </LocalizedLink>
      </div>

      <!-- <ul class="nav flex-column col-12 main-menu">
        <li class="nav-item">
          <a class="nav-link active" :href="url_home()" role="button">
            {{ $t("Promotions") }}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :href="url_home()" role="button">
            {{ $t("Shopping Guides") }}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :href="url_home()" role="button">
            {{ $t("Track Order") }}
          </a>
        </li>
        <li class="nav-item">
          <LocalizedLink class="nav-link" :to="'/contact'">
            {{ $t("Contact Us") }}
          </LocalizedLink>
        </li>
      </ul> -->

      <div class="div general-info my-5">
        <div class="nav-link text-center">{{ $t("Saudi Arabia") }}</div>
        <div class="nav-link">
          <ul class="d-flex justify-content-center">
            <li v-for="locale in locales" :key="locale.code">
              <a
                @click="changeLocale(locale)"
                id="locale_btn"
                class="btn btn-outline-primary"
                :class="locale.name == currentLocale ? 'active' : ''"
                >{{ locale.name }}</a
              >&nbsp;&nbsp;
            </li>
          </ul>
        </div>
      </div>
      <div class="socialLinks px-3">
        <h6 class="mb-3">{{ $t("Follow Us") }}</h6>
        <ul class="social_media d-flex align-items-center">
          <li>
            <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
          </li>
          <li>
            <a href="#"><i class="fa-brands fa-x-twitter"></i></a>
          </li>
          <li>
            <a href="#"><i class="fa-brands fa-instagram"></i></a>
          </li>
          <li>
            <a href="#"><i class="fa-brands fa-pinterest-p"></i></a>
          </li>
          <li>
            <a href="#"><i class="fa-brands fa-youtube"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import LocalizedLink from "@/components/LocalizedLink";
import { mapMutations } from "vuex";
import { getSupportedLocales } from "@/util/i18n/supported-locales";
export default {
  components: {
    LocalizedLink,
  },
  data() {
    return {
      locales: getSupportedLocales(),
      currentLocale: this.$route.params.locale === "ar" ? "عربي" : "English",
    };
  },
  methods: {
    ...mapMutations(["setToggleNav"]),
    changeLocale(e) {
      const path = this.$route.path.substring(
        this.$route.path.indexOf(this.$route.path) + 3,
        this.$route.path.length
      );
      const locale = e.code;
      if (path) {
        this.$router.push(`/${locale}/${path}`);
      } else {
        this.$router.push(`/${locale}`);
      }
      localStorage.setItem("X-localization", locale);
      window.location.reload();
    },
    getDomain() {
      const currentURL = window.location.href;
      const urlParts = new URL(currentURL);

      const protocol = urlParts.protocol;
      const hostname = urlParts.hostname;
      const port = urlParts.port;

      const domain = `${protocol}//${hostname}${port ? `:${port}` : ""}`;
      return domain;
    },

    url_home() {
      return this.getDomain() + "/" + this.$route.params.locale;
    },
  },
};
</script>

<style lang="scss" scoped>
[dir="ltr"] .social_media li {
  margin-right: 15px;
}

[dir="rtl"] .social_media li {
  margin-left: 15px;
}

.social_media li a {
  &:hover i,
  &:focus i {
    color: var(--secondary);
    transition: all 0.4;
  }
}

#locale_btn {
  &:hover {
    background: var(--secondary) !important;
    border-color: var(--secondary) !important;
    color: #fff !important;
    transition: all 0.4s;
  }
}
</style>
