import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import store from "@/store/index";
import VeeValidate from "vee-validate";
import Toasted from "vue-toasted";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "@/libs/acl";

import Swal from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";


Vue.use(Swal);

Vue.use(Toasted, {
  duration: 1000,
});

Vue.use(VeeValidate, {
  validity: true,
});

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
