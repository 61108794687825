<template>
  <div>
    <header>
      <nav class="new-container">
        <div class="row">
          <div class="col-4">
            <LocalizedLink class="text-dark-custom" :to="'/'">
              <img
                src="../../../public/assets/img/furnish_logo_black.svg"
                alt="Furnich"
                width="300"
                class="img-logo-custom"
                v-if="currentLocale == 'English'"
              />
              <!-- <img
              src="../../../public/assets/img/white_ar.png"
              alt="Furnich"
              class="margin-left-new"
              width="180px"
              v-else
            /> -->
            </LocalizedLink>
          </div>
          <div class="col-4 col-custom">
            <ul class="horizontal-list left-45">
              <LocalizedLink class="text-dark-custom" :to="'allshop'">
                <li class="horizontal-list-item"><span>Shop</span></li>
              </LocalizedLink>
              <LocalizedLink class="text-dark-custom" :to="'customize'">
                <li class="horizontal-list-item"><span>Customize</span></li>
              </LocalizedLink>
              <LocalizedLink class="text-dark-custom" :to="'newLogin'">
                <li class="horizontal-list-item" style="margin-left: 3px">
                  <span>Login</span>
                </li>
              </LocalizedLink>
            </ul>
          </div>
          <div class="col-2 col-custom">
            <ul class="horizontal-list left-28">
              <li class="horizontal-list-item-images" @click="toggleMenuSearch">
                <div class="search-background">
                  <img
                    src="../../../public/assets/img/icons/search_new.png"
                    alt="search"
                    class="search-img"
                  />
                </div>
              </li>
              <li class="horizontal-list-item-images" @click="toggleMenuCart">
                <div class="search-background">
                  <img
                    src="../../../public/assets/img/icons/cart_new.png"
                    alt="cart"
                    class="cart-img"
                  />
                </div>
              </li>
            </ul>
          </div>
          <div class="col-2 col-custom" style="margin-top: 2px">
            <button
              type="submit"
              class="btn btn-dark btn-header btn-menu-header"
              @click="toggleMenu"
              style=""
            >
              <div>{{ $t("Menu") }}</div>
              <img
                src="../../../public/assets/img/icons/fleche_btn_header.png"
                style="width: 77.9px; margin-top: -15px; height: 67px"
                alt="Search"
              />
            </button>
          </div>
        </div>
      </nav>
      <div class="div-border-dark"></div>

      <!-- <nav class="navbar navbar-expand-lg">
      <div class="container-fluid" :class="'border-dark'">
        <div class="row w-100 main_top_menu align-items-baseline">
          <a :href="url()" class="navbar-brand col-md-2">
            <img
              src="../../../public/assets/img/white_en.png"
              alt="Furnich"
              class=" margin-left-new"
              width="300"
              v-if="currentLocale == 'English'"
            />
            <img
              src="../../../public/assets/img/white_ar.png"
              alt="Furnich"
              class=" margin-left-new"
              width="180px"
              v-else
            />
          </a>

          <div class="collapse navbar-collapse col-md-6" id="navbar">
            <ul class="navbar-nav mx-auto">
              <li class="nav-item">
                <LocalizedLink
                  :to="'allshop'"
                  :class="'text-dark-custom  weight-header'"
                  >{{ $t("Shop") }}</LocalizedLink
                >
              </li>

              <li class="nav-item">
                <LocalizedLink
                  :to="'customize'"
                  :class="'text-dark-custom  weight-header'"
                  >{{ $t("Customize") }}</LocalizedLink
                >
              </li>

              <li class="nav-item">
                <LocalizedLink
                  :to="'newLogin'"
                  :class="'text-dark-custom  weight-header'"
                  >{{ $t("Login") }}</LocalizedLink
                >
              </li>
            </ul>
          </div>

          <div
            class="menu-icons d-flex align-items-end col-md-1 col-6 justify-content-end px-0"
          >
            <div class="search_wrapper position-relative icon">
              <form class="d-flex" role="search">
                <input
                  class="form-control me-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <button
                  class="btn btn-outline-white search-header"
                  type="submit"
                >
                  <img
                    src="../../../public/assets/img/icons/Search-black.png"
                    style="width: 25px"
                    alt="Search"
                  />
                </button>
              </form>
            </div>

            <div class="cart-wrapper icon header-action-2">
              <div class="dropdown header-action-icon-2">
                <a
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="../../../public/assets/img/icons/cart_dark.png"
                    alt="Cart"
                    class="icc-image"
                  />
                  <span class="count-cart">{{ countProduct }}</span>
                </a>
                <div class="cart-dropdown-wrap cart-dropdown-hm2">
                  <ul>
                    <li v-for="(cart, key) in $store.state.cart" :key="key">
                      <div class="shopping-cart-img">
                        <LocalizedLink :to="'product/' + cart.slug"
                          ><img alt="" :src="cart.image"
                        /></LocalizedLink>
                      </div>
                      <div class="shopping-cart-title">
                        <h4>
                          <LocalizedLink :to="'product/' + cart.slug">{{
                            cart.name
                          }}</LocalizedLink>
                        </h4>
                        <h4>
                          <span> {{ cart.price }} * {{ cart.quantity }} </span>
                          <br />
                          <span>
                            ({{
                              Number((cart.price * cart.quantity).toFixed(3))
                            }}
                            )</span
                          >SAR
                        </h4>
                      </div>
                      <div class="shopping-cart-delete">
                        <a @click.prevent="removeFromCart(cart)" class="remove"
                          ><i class="fa-thin fa-xmark"></i
                        ></a>
                      </div>
                    </li>
                  </ul>
                  <div class="shopping-cart-footer" v-if="totalPrice">
                    <div class="shopping-cart-total">
                      <h4>
                        {{ $t("Total") }} <span>SAR {{ totalPrice }}</span>
                      </h4>
                    </div>
                    <div class="shopping-cart-button">
                      <LocalizedLink :to="'cart'" class="outline">{{
                        $t("View cart")
                      }}</LocalizedLink>
                      <LocalizedLink :to="'checkout'">{{
                        $t("Checkout")
                      }}</LocalizedLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </nav> -->

      <!-- <div class="div-border-dark"></div> -->

      <!-- /Menu Cart-->
      <!-- <div>
              <button
                class="navbar-toggler icon"
                type="button"
                @click="toggleMenu"
                style="margin-right: -13px"
              >
                <img
                  src="../../../public/assets/img/icons/menu.png"
                  alt="Furnish"
                  class="icc-image"
                  v-if="!testUrl()"
                />

                <img
                  src="../../../public/assets/img/icons/menu_dark.png"
                  alt="Furnish"
                  class="icc-image"
                  v-if="testUrl()"
                />
              </button>
              <div v-if="isMenuOpen" class="full-page-menu">

                <div>
                  <MenuFullPage :closeMenu="closeMenu" />
                </div>
              </div>
            </div> -->
      <!--sidebar-->

      <!-- menu -->
      <div
        v-if="isMenuOpen"
        class="menu new-container"
        :class="{ 'menu-open': isMenuOpen, 'bg-ea': !isMenuOpen }"
      >
        <div class="row">
          <div class="col-4">
            <a :href="url()">
              <img
                src="../../../public/assets/img/furnish_logo_black.svg"
                alt="Furnich"
                width="300"
                class="img-logo-custom"
                v-if="currentLocale == 'English'"
              />
              <!-- <img
              src="../../../public/assets/img/white_ar.png"
              alt="Furnich"
              class="margin-left-new"
              width="180px"
              v-else
            /> -->
            </a>
          </div>
          <div class="col-4 col-custom">
            <ul class="horizontal-list left-29">
              <li class="horizontal-list-item" @click="goTo('AllShop')">Shop</li>

              <li class="horizontal-list-item"  @click="goTo('Customize')">Customize</li>
              <li class="horizontal-list-item"  @click="goTo('NewLogin')" style="margin-left: 3px">
                Login
              </li>
            </ul>
          </div>

          <div class="col-4 col-custom">
            <span @click="closeMenu()">
              <img
                src="../../../public/assets/img/icons/close_icon.png"
                class="close-menu-img"
                alt="Search"
            /></span>
          </div>
          <div class="col-12">
            <div class="div-border-dark-menu"></div>
          </div>
        </div>
        <div class="row top-60-header width-menu-content">
          <div class="col-5">
            <h2 class="title-menu-new" @click="goTo('AllShop')">Shop</h2>
            <div class="border-menu"></div>
            <h2 class="title-menu-new padding-top-10" @click="goTo('Customize')">Customize</h2>
            <div class="border-menu"></div>
            <h2 class="title-menu-new padding-top-10" @click="goTo('AboutUs')">About</h2>
            <div class="border-menu"></div>

            <h2 class="title-menu-new padding-top-10" @click="goTo('Contact')">Contact</h2>
          </div>
          <div class="col-7 col-img-header row">
            <div class="col-6 image-container" style="cursor: pointer;">
              <img
                src="../../../public/assets/img/right.png"
                alt="Furniture"
                class="img-menu"
              />
              <div class="position-image-text"  @click="goTo('AllShop')">
                <span class="color-text" style="margin-left: 30px">Shop</span>
                <img
                  src="../../../public/assets/img/icons/fleche_menu.png"
                  alt="Furniture"
                  class="img-fluid img-custom-size"
                  style="width: 80px; margin-top: -20px; margin-left: 30%"
                />
              </div>
            </div>
            <div class="col-6"  @click="goTo('Customize')" style="cursor: pointer;">
              <img
                src="../../../public/assets/img/right.png"
                alt="Furniture"
                class="img-menu left-7"
              />
              <div class="position-image-text">
                <span class="color-text" style="margin-left: 50px"
                  >Customize</span
                >
                <img
                  src="../../../public/assets/img/icons/fleche_menu.png"
                  alt="Furniture"
                  class="img-fluid img-custom-size"
                  style="width: 80px; margin-top: -20px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- search -->

      <div
        v-if="isMenuOpenSearch"
        class="menu-search new-container"
        :class="{ 'menu-open-search': isMenuOpenSearch }"
      >
        <div class="row">
          <div class="col-4">
            <h2 class="title-search text-dark-custom">Search</h2>
          </div>

          <div class="col-8">
            <span @click="closeMenuSearch()">
              <img
                src="../../../public/assets/img/icons/close_search.png"
                class="close-menu-img-cart width-img-90"
                alt="Search"
            /></span>
          </div>
          <div class="col-12">
            <div class="div-search-header">
              <div style="margin-bottom: -35px">
                <img
                  src="../../../public/assets/img/icons/fleche_search.png"
                  alt="Search"
                  class="fleche-search"
                  style="margin: 0 94.5%"
                />
              </div>

              <input
                type="text"
                class="search-header"
                style="margin: -170px 0; padding: 40px 0"
                placeholder="Look for a specific product or designer"
              />
            </div>
            <!-- <h2 class="text-search-header">
              Look for a specific product or designer
            </h2> -->
          </div>
          <!-- <div class="col-2">
           
          </div> -->
          <!-- <div class="line-custom-search"></div> -->
        </div>
      </div>

      <!-- Cart -->
      <div
        v-if="isMenuOpenCart"
        class="menu-cart new-container"
        :class="{ 'menu-open-cart': isMenuOpenCart }"
      >
        <div @click="closeMenuCart()">
          <img
            src="../../../public/assets/img/icons/close_icon2.png"
            class="close-menu-img-cart"
            alt="Search"
          />
        </div>
        <div class="row margin-cart">
          <div class="col-6">
            <h2 class="cart-title">Your Order</h2>
          </div>
          <div class="col-6"><div class="nb_cart">1</div></div>
          <div class="col-12">
            <div class="border-menu-cart"></div>
          </div>
          <div class="col-6">
            <img
              src="../../../public/assets/img/right.png"
              alt="Furniture"
              class="img-menu-cart margin-img-cart"
            />
          </div>

          <div class="col-6 col-detail-cart">
            <h2 class="product-name-cart">Rug from Italy</h2>
            <h2 class="category-cart">Category</h2>
            <h2 class="price-cart">SAR 1,360</h2>
            <div class="border-small-cart">
              <input type="number" class="input-cart" v-model="nb_cart" />
              <span class="remove-text">Remove</span>
            </div>
          </div>
          <div class="col-12">
            <div class="border-menu-cart custom-margin-cart-1"></div>
          </div>
          <div class="col-8 padding-cart-col">
            <h2 class="text-cart-bottom">Continue Shopping</h2>
          </div>
          <div class="col-4 padding-cart-col-1">
            <h2 class="text-cart-bottom">Checkout</h2>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import LocalizedLink from "@/components/LocalizedLink";
import MenuFullPage from "../HomeComponents/MenuFullPage";
import { getSupportedLocales } from "@/util/i18n/supported-locales";
import { mapMutations } from "vuex";

import categoriesStoreModule from "../../views/categories/categoriesStoreModule";
import madesStoreModule from "../../views/mades/madesStoreModule";
import { onMounted, ref, onUnmounted } from "vue";
import store from "@/store";
import router from "@/router";

export default {
  components: {
    LocalizedLink,
    MenuFullPage,
  },

  data() {
    return {
      isMenuOpen: false,
      isMenuOpenCart: false,
      isMenuOpenSearch: false,
      toggleSidebar: false,
      nb_cart: 1,
      locales: getSupportedLocales(),
      currentLocale: this.$route.params.locale === "ar" ? "عربي" : "English",
    };
  },

  computed: {
    totalPrice() {
      let total = 0;
      for (let item of this.$store.state.cart) {
        total += item.quantity * item.price;
      }

      return total.toFixed(3);
    },
    countProduct() {
      return this.$store.state.cart.length;
    },
  },
  methods: {
    testUrl() {
      if (
        this.$route.name == "Mades" ||
        this.$route.name == "Categories" ||
        this.$route.name == "Products" ||
        this.$route.name == "AboutUs" ||
        this.$route.name == "Customize" ||
        this.$route.name == "Portfolio" ||
        this.$route.name == "AllShop"
      ) {
        return true;
      } else {
        return false;
      }
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      if (this.isMenuOpen) {
        document.body.style.backgroundColor = "#eae1db";
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.backgroundColor = "#eee9e5";
      }
    },
    toggleMenuSearch() {
      this.isMenuOpenSearch = !this.isMenuOpenSearch;
      if (this.isMenuOpenSearch) {
        document.body.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
      } else {
        document.body.style.backgroundColor = "#eee9e5";
      }
    },

    toggleMenuCart() {
      this.isMenuOpenCart = !this.isMenuOpenCart;
      if (this.isMenuOpenCart) {
        document.body.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
      }
    },
    testUrlBody() {
      if (
        this.$route.name === "NewLogin" ||
        this.$route.name === "NewRegister" ||
        this.$route.name === "newDashboard"
      ) {
        document.body.style.backgroundColor = "#729db1";
      } else if (
        this.$route.name === "OneShop" ||
        this.$route.name === "OneCustomize"
      ) {
        document.body.style.backgroundColor = "#d4e1e7";
      } else {
        document.body.style.backgroundColor = "#eee9e5";
      }
    },
    closeMenu() {
      this.isMenuOpen = false;
      this.testUrlBody()
    },
    closeMenuSearch() {
      this.isMenuOpenSearch = false;
      document.body.style.backgroundColor = "#eee9e5";
      this.testUrlBody()

    },
    closeMenuCart() {
      this.isMenuOpenCart = false;
      document.body.style.backgroundColor = "#eee9e5";
      this.testUrlBody()

    },

    goTo(routeName) {
      this.isMenuOpen = false;
      this.$router.push({ name: routeName });
    },
    ...mapMutations(["setToggleNav"]),
    changeLocale(e) {
      const path = this.$route.path.substring(
        this.$route.path.indexOf(this.$route.path) + 3,
        this.$route.path.length
      );
      const locale = e.code;
      if (path) {
        this.$router.push(`/${locale}/${path}`);
      } else {
        this.$router.push(`/${locale}`);
      }
      localStorage.setItem("X-localization", locale);
      window.location.reload();
    },
    triggerSidebar() {
      this.$emit("triggerSidebar");
    },
    getDomain() {
      const currentURL = window.location.href;
      const urlParts = new URL(currentURL);

      const protocol = urlParts.protocol;
      const hostname = urlParts.hostname;
      const port = urlParts.port;

      const domain = `${protocol}//${hostname}${port ? `:${port}` : ""}`;
      return domain;
    },

    url(link = "") {
      return this.getDomain() + "/" + this.$route.params.locale + "/" + link;
    },

    removeFromCart(item) {
      this.$store.commit("removeFromCart", item);
    },
  },
  setup() {
    //categories

    const categoriesData = ref(null);
    const HOME_APP_STORE_MODULE_NAME = "app-category";
    // const router = context.root.$router;

    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) {
      store.registerModule(HOME_APP_STORE_MODULE_NAME, categoriesStoreModule);
    } else {
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
          store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
      });
    }

    store
      .dispatch("app-category/fetchCategories", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        categoriesData.value = response.data.data;
      })
      .catch(() => {});

    //Mades
    const madesData = ref(null);
    const MADE_APP_STORE_MODULE_NAME = "app-made";
    // const router = context.root.$router;

    // Register module
    if (!store.hasModule(MADE_APP_STORE_MODULE_NAME)) {
      store.registerModule(MADE_APP_STORE_MODULE_NAME, madesStoreModule);
    } else {
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(MADE_APP_STORE_MODULE_NAME))
          store.unregisterModule(MADE_APP_STORE_MODULE_NAME);
      });
    }

    store
      .dispatch("app-made/fetchMades", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        madesData.value = response.data.data;
      })
      .catch(() => {});
    return {
      madesData,
      categoriesData,
    };
  },
};
</script>

<style scoped>
.search-header {
  border: none !important;
  border-bottom: 1px solid black !important;
  border-radius: 0 !important;
  background: none !important;
}
.search-header :focus {
  border: none !important;
  border-radius: 0 !important;
  background-color: none !important ;
}

.search-header :hover {
  border: none !important;
  border-radius: 0 !important;
  background-color: none !important;
}

.main_top_menu {
  justify-content: space-between;
}
.header-action-2 .header-action-icon-2:hover .cart-dropdown-wrap {
  opacity: 1;
  visibility: visible;
  top: calc(100% + 10px) !important;
}
.dropdown-menu {
  background: transparent;
  border: none;
  min-width: 320px;
}
.dropdown-menu li {
  text-align: initial;
}
.header-action-2 .header-action-icon-2:last-child {
  padding: 0 0px 0 8px;
}
.header-action-2 .header-action-icon-2 {
  position: relative !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
  padding: 0 10px !important;
}
[dir="ltr"] .dropdown-menu {
  right: 0 !important;
  left: auto !important;
}
.cart-dropdown-wrap ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  margin: 0 0 20px !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.cart-dropdown-wrap ul li img {
  width: 60px !important;
}
.cart-dropdown-wrap ul li .shopping-cart-img a {
  display: block;
}
.cart-dropdown-wrap ul li .shopping-cart-img a img {
  max-width: 100%;
}
.cart-dropdown-wrap ul li .shopping-cart-img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
  margin-right: 20px;
}
.cart-dropdown-wrap {
  position: absolute;
  right: 0 !important;
  top: calc(100% + 20px);
  z-index: 99;
  width: 320px;
  background-color: #fff;
  -webkit-box-shadow: 0 8px 8px rgb(0 0 0 / 5%);
  box-shadow: 0 8px 8px rgb(0 0 0 / 5%);
  padding: 30px 20px 27px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 4px;
  border: 1px solid #eef0ee;
}
[dir="rtl"] .cart-dropdown-wrap.cart-dropdown-hm2 {
  right: -22px !important;
  left: auto !important;
}
[dir="ltr"] .cart-dropdown-wrap.cart-dropdown-hm2 {
  left: -261px;
  right: auto;
}

.cart-dropdown-wrap ul li .shopping-cart-title h4 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 9px !important;
}
.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-total {
  border-top: 2px solid #f3f3f3;
  margin: 25px 0;
  padding: 17px 0 0;
}
.shopping-cart-footer .shopping-cart-total {
  border-top: 2px solid #f3f3f3;
  margin: 25px 0 !important;
  padding: 17px 0 0 !important;
}
.shopping-cart-footer .shopping-cart-total h4 {
  color: #9b9b9b;
  font-weight: 700;
  font-size: 15px;
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.shopping-cart-footer .shopping-cart-total h4 span {
  font-size: 18px;
  float: right;
  color: var(--primary);
}
.shopping-cart-footer .shopping-cart-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button a {
  display: inline-block !important;
  font-size: 14px !important;
  color: #ffffff !important;
  border-radius: 4px !important;
  line-height: 1 !important;
  padding: 10px 20px !important;
  background-color: var(--primary);
  border: 2px solid var(--primary);
}
.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button a:hover,
.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button a:focus {
  background-color: var(--secondary);
  border: 2px solid var(--secondary);
}
.search_wrapper form {
  height: 35px;
}
.remove {
  cursor: pointer;
}
.border-new {
  border-bottom: 1px white solid;
  padding-bottom: 8px;
}
/* .border-dark {
  border-bottom: 1px black solid;
  padding-bottom: 8px;
} */
.categorie-div {
  border: 1px black solid;
  border-bottom: none;
  border-top: none;
  /* padding-bottom: 300px; */
}

.weight-header {
  font-weight: 500 !important;
}
/* .weight-header:hover{
  background-color: #f4624f;
} */
</style>
