<template>
  <section class="new-custom-section bottom-45">
    <div class="row">
      <div class="col-md-6">
        <div class="custom-col-6">
          <div>
            <h3 class="mb-10 text-uppercase contact_home">
              {{ $t("Contact") }}
            </h3>
          </div>
          <div class="text-contact">
            <p>
              Any question about our products or customization? Feel free to
              contact us by email at hello@furnich.com or directly by phone:
              KSA: +966 55 808 59 06
            </p>
            <LocalizedLink class="text-dark-custom" :to="'/contact'">
              <img
                src="../../../public/assets/img/icons/right_fleche.png"
                class="fleche-contact"
              />
            </LocalizedLink>
          </div>
        </div>
      </div>
      <div class="col-md-6 newsletter" style="padding-left: 5px">
        <div class="custom-col-6-newsletter">
          <h3 class="contact_home text-uppercase">
            {{ $t("Newsletter") }}
          </h3>
          <div class="text-contact">
            <p style="width: 85%">
              {{
                $t(
                  "Subscribe to our newsletter to get the latest news, including our newest products and exclusive collaborations."
                )
              }}
            </p>
          </div>
          <form
            @submit.prevent="subscribe(subscribeData)"
            style="border-radius: 15px"
          >
            <div class="input-group">
              <input
                v-validate="'required|email'"
                name="email"
                type="email"
                class="padding-input-place newsletter-input form-control"
                :placeholder="$t('Add your email')"
                v-model="subscribeData.email"
                style="color: black"
              />

              <span
                class="input-group-btn btn-subscribe"
                :class="
                  $route.params.locale === 'en'
                    ? 'border-btn-left'
                    : 'border-btn-right'
                "
              >
                <button
                  class="submit btn btn-secondary"
                  style="background: #f4624f"
                >
                  {{ $t("Send") }}
                </button>
              </span>
            </div>
          </form>
          <span class="">{{ errors.first("email") }}</span>
        </div>
      </div>
      <!-- <div class="col-md-6"><div class="border-new"></div></div>
      <div class="col-md-6"><div class="border-new"></div></div> -->
    </div>
  </section>
  <!-- <section
    class="section-padding contact"
    data-aos="fade-up"
    id="home_contact"
    :class="
      $route.params.locale === 'en'
        ? 'left-contact-section'
        : 'right-contact-section'
    "
    style="margin-top: -60px"
  >
    <div class="">
      <div class="row mb-5">
        <div class="col-md-6 newsletter">
          <div>
            <h3
              class="mb-10 text-uppercase contact_home"
              style="font-weight: 400"
            >
              {{ $t("Contact") }}
            </h3>
          </div>
          <p style="width: 60%; margin-top: -15px">
            {{
              $t(
                "Any question about our products or customization ? Feel free to contact us by email at hello@furnish.com or directly by phone : KSA :+966 55 808 59 06"
              )
            }}
          </p>
        </div>
        <div class="col-md-6 newsletter">
          <h3 class="contact_home text-uppercase" style="font-weight: 400">
            {{ $t("Newsletter") }}
          </h3>
          <p class="" style="width: 55%; margin-top: -15px">
            {{
              $t(
                "Subscribe to our newsletter to get the latest news, including our newest products and exclusive collaborations."
              )
            }}
          </p>
          <form
            @submit.prevent="subscribe(subscribeData)"
            style="width: 65%; border-radius: 17px"
          >
            <div class="input-group">
              <input
                v-validate="'required|email'"
                name="email"
                type="email"
                class="form-control"
                :placeholder="$t('Enter your email address')"
                v-model="subscribeData.email"
                style="color: black"
              />

              <span
                class="input-group-btn btn-subscribe"
                :class="
                  $route.params.locale === 'en'
                    ? 'border-btn-left'
                    : 'border-btn-right'
                "
              >
                <button
                  class="submit btn btn-secondary"
                  style="background: #f4624f"
                >
                  {{ $t("Subscribe") }}
                </button>
              </span>
            </div>
          </form>
          <span class="">{{ errors.first("email") }}</span>
        </div>
        <div class="col-md-6"><div class="border-new"></div></div>
        <div class="col-md-6"><div class="border-new"></div></div>
      </div>
    </div>
  </section> -->
</template>
<script>
import { ref, onUnmounted } from "vue";
import store from "@/store";
import contactStoreModule from "./contactStoreModule";
import ContactHome from "../../components/ContactHome";
import LocalizedLink from "@/components/LocalizedLink";

export default {
  components: { ContactHome, LocalizedLink },
  methods: {
    subscribe() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          store
            .dispatch("app-subscribe/subscribe", this.subscribeData)
            .then(() => {
              this.$toasted.show("you are subscribed successfully");
            })
            .catch(() => {});
        }
      });
    },
  },
  setup() {
    const subscribeData = ref({
      email: "",
    });

    const HOME_APP_STORE_MODULE_NAME = "app-subscribe";

    // const router = context.root.$router;

    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) {
      store.registerModule(HOME_APP_STORE_MODULE_NAME, contactStoreModule);
    } else {
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
          store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
      });
    }

    return {
      subscribeData,
    };
  },
};
</script>

<style scoped>
.newsletter .form-control {
  border: none;
}

input:valid {
  border-color: var(--primary);
}

input:invalid {
  border-color: red;
}

.newsletter .form-control:focus {
  box-shadow: none;
  background-color: transparent;
}
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-radius: 35px;
}

/* input::placeholder {
  color: black !important;
} */
.border-new {
  border-bottom: black solid 1px;
  margin-top: 120px;
}
.input-group-btn {
  padding: 1% 9%;
  background: #f4624f;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.newsletter-input::placeholder {
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
}
.padding-input-place {
  padding: 6% 3% 5%;
}
</style>
