<template>
  <!-- <div class="categories">
          <PageBanner />
          <section id="categories_list">
            <div class="container px-4 py-5">
              <div class="row">
                <div class="col-12 mb-5">
                  <h2 class="text-uppercase text-center">
                    {{ $t("Discover Our Products") }}
                  </h2>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-md-3 mb-4"
                  v-for="category in categoriesData"
                  :key="category.id"
                >
                  <LocalizedLink :to="'shop/' + category.slug">
                    <div class="card category-miniature position-relative">
                      <img
                        :src="category.photo"
                        class="card-img-top img-custom-size"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title">
                          <span class="text-dark">{{ category.name }}</span>
                        </h5>
                      </div>
                    </div>
                  </LocalizedLink>
                </div>
              </div>
            </div>
          </section>
          <GreenBg />
        </div> -->
  <div class="categories">
    <PageBanner />
    <div class="top-page-text">
      <div class="div-before">
        <span class="text-dark-custom">ACCOUNT > DASHBOARD</span>
      </div>

      <div class="row top-80">
        <div class="col-md-6">
          <div class="div-item-dashboard">
            <span class="title-dashboard"> Your Profile </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="div-item-dashboard">
            <span class="title-dashboard"> Your Order </span>
          </div>
        </div>
      </div>
      <div class="row top-dashboard">
        <div class="col-md-6">
          <div class="div-item-dashboard-second">
            <span class="title-dashboard"> Your Wishlist </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="div-item-dashboard-second">
            <span class="title-dashboard"> Your Submission </span>
          </div>
        </div>
      </div>

      <div class="row top-dashboard">
        <div class="col-md-6">
          <div class="div-item-dashboard-third">
            <span class="title-dashboard">  </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="div-item-dashboard-third">
            <span class="title-dashboard">  </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopularCategories from "../components/HomeComponents/PopularCategories.vue";
import Products from "./product/Products.vue";
import PageBanner from "../components/PageBanner.vue";
import GreenBg from "../components/BeforeFooter/GreenBg";
import LocalizedLink from "@/components/LocalizedLink";
import { ref, onUnmounted } from "vue";
import store from "@/store";
import categoriesStoreModule from "../views/categories/categoriesStoreModule";

import router from "@/router";
export default {
  components: {
    PageBanner,
    GreenBg,
    LocalizedLink,
    PopularCategories,
    Products,
  },
  data() {
    return {
      showcategory: false,
    };
  },
  methods: {
    categoryShow() {
      this.showcategory = !this.showcategory;
    },
  },

  setup() {
    const categoriesData = ref(null);
    const HOME_APP_STORE_MODULE_NAME = "app-category";

    // const router = context.root.$router;

    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) {
      store.registerModule(HOME_APP_STORE_MODULE_NAME, categoriesStoreModule);
    } else {
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
          store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
      });
    }

    store
      .dispatch("app-category/fetchCategories", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        categoriesData.value = response.data.data;
      })
      .catch(() => {});

    return {
      categoriesData,
    };
  },
};
</script>

<style scoped>
.categories .card {
  border-radius: 20px;
  border: none;
}
.title-discover {
  display: none !important;
}
.title-categories {
  margin-top: 40px;
  margin-left: 20px;
}
</style>
