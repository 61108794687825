<template>
  <section class="grid-products">
    <div>
      <div class="row products-gird-custom">
        <div class="search_bars">
          <div class="row align-items-center mb-3">
            <div class="col-md-12">
              <h5 class="text-dark text-uppercase">{{ $t("Products") }}</h5>
            </div>
            <br />
            <div
              class="col-md-12 d-flex align-items-center justify-content-center"
            >
              <form class="me-3 byProduct">
                <input
                  class="form-control me-2 size-input"
                  type="text"
                  v-model="product"
                  :placeholder="$t('Categories')"
                  aria-label="Search"
                  @keyup="getSearchedProducts()"
                />
              </form>

              <form class="byBrand" role="search">
                <input
                  class="form-control me-2 custom-input size-input"
                  type="text"
                  :placeholder="$t('Mades')"
                  aria-label="Search"
                  v-model="made"
                  @keyup="getSearchedProductsByMade()"
                />
              </form>
            </div>
          </div>
        </div>
        <div class="row" v-if="productsData">
          <div
            class="col-md-3 mb-4"
            v-for="product in productsData"
            :key="product.id"
          >
            <LocalizedLink
              :to="'product/' + product.slug"
              class="text-capitalize"
            >
              <img
                :src="product.couverture"
                alt="Furniture"
                class="img-fluid img-custom-size"
                style="border-radius: 4%"
              />
              <div class="product-info d-block">
                <h4 class="text-dark">
                  {{ product.name }}
                </h4>
                <div class="price">{{ product.price }} {{ $t("SAR") }}</div>
              </div>
            </LocalizedLink>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LocalizedLink from "@/components/LocalizedLink";
import { ref, onUnmounted } from "vue";
import store from "@/store";
import homeStoreModule from "../../views/home/homeStoreModule";
import Banner from "../../components/HomeComponents/Banner.vue";
import Contact from "@/views/newsletter/Contact";

export default {
  components: {
    LocalizedLink,
    Banner,
    Contact,
  },
  props: {
    productsData: {
      required: true,
    },
  },

  setup(props, { emit }) {
    const product = ref(null);
    const made = ref(null);
    const HOME_APP_STORE_MODULE_NAME = "app-product-grid";

    // const router = context.root.$router;

    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) {
      store.registerModule(HOME_APP_STORE_MODULE_NAME, homeStoreModule);
    } else {
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
          store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
      });
    }

    const getSearchedProducts = () => {
      store
        .dispatch("app-product-grid/fetchSearchedProducts", product.value)
        .then((response) => {
          emit("update:products-data", response.data.data);
        });
    };

    const getSearchedProductsByMade = () => {
      store
        .dispatch("app-product-grid/fetchSearchedProductsByMade", made.value)
        .then((response) => {
          emit("update:products-data", response.data.data);
        });
    };

    return {
      product,
      made,
      getSearchedProducts,
      getSearchedProductsByMade,
    };
  },
};
</script>
<style>

</style>
