<template>
  <div>
    <PageBanner />
    <div class="payment-page">
      <div class="payment-success">
        <br />
        <br />

        <div class="container mt-5" style="margin-bottom: 20px">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header bg-success text-white"></div>
                <div class="card-body">
                  <h2 class="text-success">
                    <b>{{ $t("Payment made successfully") }}</b>
                  </h2>
                  <p style="text-align: center">
                    {{
                      $t(
                        "Your purchase has been successfully completed, Your invoice number:"
                      )
                    }}
                    <b>{{ invoiceData[0].InvoiceId }}</b>
                    {{ $t(",Here are the details of your order:") }}
                  </p>
                  <p></p>
                  <br />
                  <div class="table-responsive">
                    <table
                      class="table table-nowrap table-hover table-centered m-0"
                    >
                      <thead class="table-light">
                        <tr>
                          <th>{{ $t("Product") }}</th>
                          <th>{{ $t("Description") }}</th>
                          <th>{{ $t("Size") }}</th>
                          <th>{{ $t("Color") }}</th>
                          <th>{{ $t("Price") }}</th>
                          <th>{{ $t("quantity") }}</th>
                          <th>{{ $t("Total amount") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="product in products[0]">
                          <td>
                            <img :src="product.couverture" height="70" />
                          </td>
                          <td>{{ product.desctiption.en }}</td>
                          <td>
                            <span> {{ product.pivot.size }} </span>
                          </td>
                          <td :style="'background:' + product.pivot.color"></td>
                          <td>
                            <span> {{ product.price }} {{ $t("SAR") }}</span>
                          </td>

                          <td>{{ product.pivot.quantity }}</td>
                          <td>
                            {{
                              Number(
                                (
                                  product.price * product.pivot.quantity
                                ).toFixed(3)
                              )
                            }}
                            {{ $t("SAR") }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan="8"
                            style="text-align: center; font-size: 16px"
                          >
                            <b>{{ $t("Total amount") }} :</b>
                            {{
                              invoiceData[0].InvoiceValue -
                              invoiceData[0].Shippement
                            }}
                            {{ $t("SAR") }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan="8"
                            style="text-align: center; font-size: 16px"
                          >
                            <b>{{ $t("Shipment") }} : </b>
                            {{ invoiceData[0].Shippement }} {{ $t("SAR") }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan="8"
                            style="text-align: center; font-size: 16px"
                          >
                            <b>{{ $t("Final amount") }}: </b>
                            {{ invoiceData[0].InvoiceValue }} {{ $t("SAR") }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <br />
                  <a href="/" class="btn btn-primary">
                    {{ $t("Back to homepage") }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentContent from "@/components/PaymentContent.vue";
import axios from "../libs/axios";
import PageBanner from "../components/PageBanner.vue";

export default {
  props: ["invoiceId"],
  data() {
    return {
      paymentSuccess: true,
      invoiceData: [],
      products: [],
    };
  },
  created() {
    this.testInvoice();
  },
  methods: {
    async testInvoice() {
      try {
        const invoiceId = this.$route.params.invoiceId;
        const response = await axios.get(
          process.env.VUE_APP_BASE_URL + "/api/testinvoice/" + invoiceId
        );
        console.log(response.data);
        this.invoiceData.push(response.data.invoice);
        this.products.push(this.invoiceData[0].products);
        localStorage.removeItem("cart");
      } catch (error) {
        console.error("Error fetching payment status:", error);
      }
    },
  },
  components: {
    PaymentContent,
    PageBanner,
  },
};
</script>

<style scoped>
.payment-success {
  text-align: center;
  padding: 20px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

p {
  font-size: 16px;
}
</style>
