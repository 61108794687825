<template>
    <div>
      <PageBanner />
  
      <div class="container px-4 py-5">
        <div class="row">
          <div class="col-12 mb-5">
            <h2 class="text-uppercase text-center">
              {{ $t("Why shop with Us") }}
            </h2>
            <div>
              <p>
                {{
                  $route.params.locale === "en"
                    ? data.why_shop_en
                    : data.why_shop_ar
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import axios from "../../libs/axios";
  import PageBanner from "../../components/PageBanner.vue";
  export default {
    components: { PageBanner },
    data() {
      return {
        data: {},
      };
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        try {
          const response = await axios.get(
            process.env.VUE_APP_BASE_URL + "/api/front/websitesetting"
          );
          this.data = response.data.data;
          console.log("sttings", this.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    },
  };
  </script>
  <style scoped>
  p {
    font-size: 20px;
  }
  </style>
  