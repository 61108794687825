<template>
  <footer class="new-custom-section">
    <div class="row top-section">
      <div class="col-md-6 padding-logo-footer">
        <img
          src="../../../public/assets/img/footer_logo_new.png"
          alt="Furnich"
          class="img-fluid"
          width="250"
          v-if="currentLocale == 'English'"
        />
        <img
          src="../../../public/assets/img/footer_logo_new.png"
          alt="Furnich"
          class="img-fluid"
          width="250"
          v-else
        />
        <!-- <h4 class="mt-3">
            {{
              $route.params.locale === "en"
                ? data.title_footer_en
                : data.title_footer_ar
            }}
          </h4>
          <p class="w-75 mt-4">
            <br />
            {{
              $route.params.locale === "en"
                ? data.description_footer_en
                : data.description_footer_ar
            }}
          </p> -->

        <!-- <p class="w-75 mt-4">
            {{ $t("Welcome to the Furnich, that stays in touch!") }} <br />
            {{
              $t(
                "Explore all the exciting rewards and benefits of being a Friends For Life."
              )
            }}
          </p> -->
      </div>
      <div class="col">
        <h3 class="title-footer">
          {{ $t("Categories") }}
        </h3>
        <ul class="unstyled margin-top-ul">
          <li
            v-for="category in categoriesData"
            :key="category.id"
            class="margin-top-li"
          >
            <LocalizedLink :to="'shop/' + category.slug">{{
              category.name
            }}</LocalizedLink>
          </li>

          <!-- <li>
                        <LocalizedLink :to="'contact'">{{ $t("Shipping") }}</LocalizedLink>
                    </li> -->
          <!-- <li>
              <LocalizedLink :to="'contact'">{{ $t("FAQs") }}</LocalizedLink>
            </li> -->
          <!-- 
            <li>
              <LocalizedLink :to="'contact'">{{
                $t("Customer Feedback")
              }}</LocalizedLink>
            </li> -->

          <!-- <li>
                        <LocalizedLink :to="'contact'">{{ $t("Pricing") }}</LocalizedLink>
                    </li> -->
        </ul>
      </div>

      <div class="col">
        <!-- <h3>
            {{ $t("We accept") }}
          </h3>
          <ul class="unstyled">
            <li>Mada</li>
            <li>VISA</li>
            <li>MasterCard</li>
            <li>ApplePay</li>
            <li>STCpay</li>
            <li>AMEX</li>

            <li>GooglePay</li>

            <li>Tabby & Tamara</li>
          </ul> -->
        <h3 class="title-footer">
          {{ $t("Pages") }}
        </h3>
        <ul class="unstyled margin-top-ul">
          <li class="margin-top-li">
            <LocalizedLink :to="'aboutus'">{{ $t("About us") }}</LocalizedLink>
          </li>

          <li class="margin-top-li">
            <LocalizedLink :to="'contact'">{{ $t("News") }}</LocalizedLink>
          </li>

          <li class="margin-top-li">
            <LocalizedLink :to="'contact'">{{ $t("FAQ") }}</LocalizedLink>
          </li>

          <li class="margin-top-li">
            <LocalizedLink :to="'contact'">{{ $t("Contact") }}</LocalizedLink>
          </li>

          <li class="margin-top-li">
            <LocalizedLink :to="'contact'">{{ $t("Login") }}</LocalizedLink>
          </li>
        </ul>
      </div>
    </div>

    <!--footer bottom section -->
    <div class="row bottom-section">
      <div class="instagram col-6 py-4">
        <div class="d-flex align-items-center justify-content-center">
          <a :href="data.instagram_link" target="_blank">
            <img
              src="../../../public/assets/img/icons/instagram_new.png"
              alt="instagram"
              width="145"
              height="115"
            />
            <!-- <span class="instagram">{{ $t("Instagram") }}</span> -->
          </a>
        </div>
      </div>

      <div class="twitter col-6 py-4" style="background: black">
        <div class="d-flex align-items-center justify-content-center">
          <a :href="data.twitter_link" target="_blank">
            <img
              src="../../../public/assets/img/icons/twitter-x.png"
              alt="twitter"
              width="140"
              height="90"
              style="
                margin-top: 10px;
                margin-left: 25px;
                height: 98px;
                width: 140px;
              "
            />
            <!-- <span>{{ $t("Twitter") }}</span> -->
          </a>
        </div>
      </div>
    </div>

    <div class="copyright">
      <div class="row pt-3 d-flex">
        <div class="col-8 padding-footer">
          <ul class="d-flex no-style flex-wrap links">
            <li class="text-footer">
              &copy; {{ new Date().getFullYear() }} {{ $t("apps.name") }}
            </li>
            <li class="text-footer">{{ $t("All rights reserved") }}</li>
            <li class="text-footer">Shahad & Hessa Co Ltd.</li>
            <li class="text-footer">C.R 10101541990965</li>
            <li class="text-footer">CF 09301670965</li>
          </ul>
        </div>

        <div class="col-4 padding-footer">
          <ul class="d-flex no-style flex-wrap links">
            <li class="text-footer custom-ul" @click="openModal('refund')">
              Refund Policy
            </li>
            <li class="text-footer custom-ul" @click="openModal('privacy')">
              Privacy Policy
            </li>
            <li class="text-footer custom-ul" @click="openModal('terms')">
              Terms of Service
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- ModalFooter -->
    <div class="custom-modal" v-if="isOpen">
      <div class="modal-overlay" @click="closeModal"></div>
      <div class="modal-content" :class="dataModal.backgroundColor">
        <div class="modal-header">
          <h2
            class="title-modal"
            :class="{ 'white-color': dataModal.title === 'Refund Policy' }"
          >
            {{ dataModal.title }}
          </h2>
          <img
            :src="dataModal.srcImage"
            alt="Close"
            width="40"
            height="40"
            class="close-icon"
            @click="closeModal"
          />
        </div>
        <div class="modal-body">
          <span :class="{ 'white-color': dataModal.title === 'Refund Policy' }">
            Shipping
          </span>
          <p :class="{ 'white-color': dataModal.title === 'Refund Policy' }">
            We offer free domestic shipping on all orders over $150. Excluding
            Artwork by Todd Clare. Pricing will be added at the checkout. We use
            couriers and do NOT send to parcel lockers and PO Boxes.
          </p>
          <span :class="{ 'white-color': dataModal.title === 'Refund Policy' }">
            Returns
          </span>
          <p :class="{ 'white-color': dataModal.title === 'Refund Policy' }">
            Unfortunately we don't offer free returns so please choose
            carefully. You may send your order back at your own expense after
            you have emailed us: hello@furnich.sa If you item is bespoke, you
            will not be able to return it unless the product has been deemed
            faulty. If your item is damaged you will need to send an image to
            hello@furnich.sa - the damage will be assessed by the Provider team.
            If you wish to exchange your item, you may need to pay an additional
            shipping cost to send your new product out. For more information
            please email hello@furnich.sa If products are misused we will not
            accept responsibility for any damage caused. Please read care
            instructions on items, especially for dyed and printed cushions. If
            you do not follow care labels, we will not accept responsibly for
            any damage done.
          </p>
          <span :class="{ 'white-color': dataModal.title === 'Refund Policy' }">
            Cancellations
          </span>
          <p :class="{ 'white-color': dataModal.title === 'Refund Policy' }">
            If you wish to cancel your item you have a 24 hour window to do so
            to receive a full refund. Any longer and we will not accept for
            change of mind.
          </p>
          <span :class="{ 'white-color': dataModal.title === 'Refund Policy' }">
            Discounts
          </span>
          <p :class="{ 'white-color': dataModal.title === 'Refund Policy' }">
            Our pricing is very transparent so therefore we will never go on
            sale. Please don't take advantage of us as a small business and use
            the sign up code more than once. The 10% off code is not valid on
            commission pieces and artwork.
          </p>
        </div>
      </div>
    </div>
  </footer>
  <!-- <div class="modal-footer"></div> -->
</template>

<script>
import LocalizedLink from "@/components/LocalizedLink";
import { getSupportedLocales } from "@/util/i18n/supported-locales";
import axios from "../../libs/axios";
import { ref, onUnmounted } from "vue";
import store from "@/store";
import categoriesStoreModule from "../../views/categories/categoriesStoreModule";
import router from "@/router";

export default {
  components: {
    LocalizedLink,
  },
  data() {
    return {
      locales: getSupportedLocales(),
      currentLocale: this.$route.params.locale === "ar" ? "عربي" : "English",
      data: {},
      isOpen: false,
      testModal: "refund",
      srcImage: "",
      titleModal: "",
      dataModal: {
        title: "",
        backgroundColor: "",
        srcImage: "",
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(
          process.env.VUE_APP_BASE_URL + "/api/front/websitesetting"
        );
        this.data = response.data.data;
        console.log("sttings", this.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    openModal(title) {
      this.isOpen = true;

      if (title === "refund") {
        this.dataModal.srcImage = require("../../../public/assets/img/icons/close_modal.png");
        this.dataModal.backgroundColor = "bg-modal-blue";
        this.dataModal.title = "Refund Policy";
      } else if (title === "privacy") {
        this.dataModal.srcImage = require("../../../public/assets/img/icons/close_red.png");
        this.dataModal.backgroundColor = "bg-modal-red";
        this.dataModal.title = "Privacy Policy";
      } else if (title === "terms") {
        this.dataModal.srcImage = require("../../../public/assets/img/icons/close_beige.png");
        this.dataModal.backgroundColor = "bg-modal-beige";
        this.dataModal.title = "Terms of service";
      }
      document.body.style.overflow = "hidden";
      document.body.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    },
    closeModal() {
      this.isOpen = false;
      document.body.style.backgroundColor = "#eee9e5";
    },
  },
  setup() {
    const categoriesData = ref(null);
    const HOME_APP_STORE_MODULE_NAME = "app-category";

    // const router = context.root.$router;

    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) {
      store.registerModule(HOME_APP_STORE_MODULE_NAME, categoriesStoreModule);
    } else {
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
          store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
      });
    }

    store
      .dispatch("app-category/fetchCategories", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        categoriesData.value = response.data.data;
      })
      .catch(() => {});

    return {
      categoriesData,
    };
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 991px) {
  .w-75 {
    width: 100% !important;
  }
}
[dir="rtl"] {
  .top-section {
    div {
      padding-left: 0;
    }
  }
}
[dir="ltr"] {
  .top-section {
    div {
      padding-right: 0;
    }
  }
}
</style>
<style scoped>
.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 42px 45px;
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
  width: 63% !important;
  height: 100% !important;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
}

.close-icon {
  cursor: pointer;
}

.modal-body {
  margin-top: 10px;
}
.bg-modal-blue {
  background-color: #114956;
}
.bg-modal-red {
  background-color: #f4624f;
}
.bg-modal-beige {
  background-color: #e4bca2;
}
.title-modal {
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.white-color {
  color: #fff !important;
}

.modal-body span {
  /* font-weight: 700; */
  font-size: 18px;
  line-height: 22px;
}

.modal-body p {
  font-weight: 100;
  font-size: 15px;
  line-height: 20px;
  /* width: 85%; */
}
</style>
