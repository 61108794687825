<template>
  <div class="contact">
    <PageBanner />
    <div class="top-page-text">
      <div class="div-before">
        <span>CONTACT</span>
      </div>
      <h2 class="first-title-page">
        One-stop destination offering a wide range of options for purchasing,
        designing, manufacturing, and acquiring furniture and accessories.
      </h2>
      <div class="div-about-us">
        <section class="section-div-contact bg-new-white">
          <form @submit.prevent="sendMessage(messageData)" id="contact-form">
            <div class="row">
              <div class="col-md-12">
                <h5 class="title-abouts text-uppercase">CONTACT US</h5>
              </div>
              <div class="col-md-6 top-100">
                <div>
                  <label class="label-contact">NAME</label>
                  <input
                    type="text"
                    class="input-contact"
                    v-validate="'required'"
                    v-model="messageData.fullName"
                  />
                  <span class="error_msg">{{ errors.first("name") }}</span>
                </div>

                <div class="top-15">
                  <label class="label-contact">Email</label>
                  <input
                    type="text"
                    class="input-contact"
                    v-validate="'required'"
                    v-model="messageData.email"
                  />
                </div>

                <div class="top-15 ">
                  <label class="label-contact">PHONE</label>
                  <input
                    type="text"
                    class="input-contact"
                    v-validate="'required'"
                    v-model="messageData.phone"
                  />
                </div>
              </div>

              <div class="col-md-6 top-100">
                <label class="label-contact">MESSAGE</label>
                <textarea
                  type="text"
                  class="message-contact"
                  v-validate="'required'"
                  v-model="messageData.message"
                ></textarea>
              </div>
              <div class="col-md-9">
                <p class="text-contact">
                  Furnich is a furniture one-stop destination offering a wide
                  range of options for purchasing, designing, manufacturing, and
                  acquiring furniture and accessories.
                </p>
              </div>
              <div class="col-md-3">
                <div class="padidng-custom-btn">
                  <button
                    type="submit"
                    class="submit btn btn-dark btn-block new-btn-custom-dark-contact"
                  >
                    {{ $t("Submit") }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
    <!-- <section class="py-5 bg-white">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-10 m-auto">
            <div
              class="contact-from-area padding-20-row-col wow FadeInUp animated"
              style="visibility: visible"
            >
              <h3 class="mb-10 text-center">{{ $t("Drop Us a Line") }}</h3>
              <p class="text-muted mb-30 text-center font-sm">
                {{ $t("We're open for any suggestion or just to have a chat") }}
              </p>
              <form
                @submit.prevent="sendMessage(messageData)"
                class="contact-form-style text-center"
                id="contact-form"
              >
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="input-style mb-20">
                      <input
                        name="name"
                        v-validate="'required'"
                        v-model="messageData.fullName"
                        :placeholder="$t('Name')"
                        type="text"
                      />
                      <span class="error_msg">{{ errors.first("name") }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="input-style mb-20">
                      <input
                        name="email"
                        v-validate="'required|email'"
                        :placeholder="$t('Your Email')"
                        type="email"
                        v-model="messageData.email"
                      />
                      <span class="error_msg">{{ errors.first("email") }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="input-style mb-20">
                      <input
                        name="telephone"
                        :placeholder="$t('Your Phone')"
                        type="text"
                        v-validate="'required'"
                        v-model="messageData.phone"
                      />
                      <span class="error_msg">{{ errors.first("telephone") }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="input-style mb-20">
                      <input
                        name="subject"
                        :placeholder="$t('Subject')"
                        type="text"
                        v-validate="'required'"
                        v-model="messageData.subject"
                      />
                      <span class="error_msg">{{ errors.first("subject") }}</span>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="textarea-style mb-30">
                      <textarea
                        name="message"
                        :placeholder="$t('Message')"
                        v-validate="'required'"
                        v-model="messageData.message"
                      ></textarea>
                      <span class="error_msg">{{ errors.first("message") }}</span>
                    </div>
                    <button class="submit btn btn-primary submit-auto-width">
                      {{ $t("Send message") }}
                    </button>
                  </div>
                </div>
              </form>
              <p class="form-messege"></p>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- <GreenBg /> -->
  </div>
</template>

<script>
import PageBanner from "@/components/PageBanner";
import GreenBg from "@/components/BeforeFooter/GreenBg";
import { ref, onUnmounted } from "vue";
import store from "@/store";
import contactStoreModule from "./contactStoreModule";

export default {
  components: { PageBanner, GreenBg },

  methods: {
    sendMessage(messageData) {
      this.$validator.validateAll().then((success) => {
        if (success) {
          store
            .dispatch("app-contact/sendMessage", messageData)
            .then(() => {
              store.dispatch("addNotification", {
                type: "success",
                message: "Message sent",
              });
            })
            .catch(() => {});
        }
      });
    },
  },
  setup() {
    const messageData = ref({
      fullName: "",
      email: "",
      phone: "",
      subject: "test",
      message: "",
    });

    const HOME_APP_STORE_MODULE_NAME = "app-contact";

    // const router = context.root.$router;

    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) {
      store.registerModule(HOME_APP_STORE_MODULE_NAME, contactStoreModule);
    } else {
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
          store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
      });
    }

    return {
      messageData,
    };
  },
};
</script>

<style scoped>
input:valid {
  border-color: var(--primary);
}

input:invalid {
  border-color: red;
}

textarea:valid {
  border-color: var(--primary);
}

textarea:invalid {
  border-color: red;
}

.error_msg {
  color: red;
  text-align: initial;
  display: block;
  font-size: smaller;
}
</style>
