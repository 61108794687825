import http from "../../libs/axios";

export default {
  namespaced: true,
  state: {
    paginats: 0,
  
  },
  getters: {},
  mutations: {
    setPaginats(state, data) {
      console.log(state, data, "aaa");
      state.paginats = data;
    },
 
  },
  actions: {
    fetchMades(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        http
          .get("/front/mades", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchMadeCategories(ctx, { id }) {
      return new Promise((resolve, reject) => {
        http
          .get(`/front/made/${id}/categories`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  
   
  },

 
};
