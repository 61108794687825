<template>
  <div class="new-custom-section" style="margin-top: -6px">
    <div id="main_slider" class="top-15 row">
      <div class="col-md-12">
        <div class="swiper" id="homeSlider">
          <div class="swiper-wrapper">
            <!-- <div class="swiper-slide" v-for="slider in data" :key="slider.id">
              <img
                :src="slider.image"
                alt=""
                class="w-100 img-fluid slide"
                style="height: 748px; border-radius: 23px"
              />
             
            </div> -->
            <div class="swiper-slide">
              <img
                src="../../../public/assets/img/slider.png"
                alt=""
                class="w-100 img-fluid slide"
                style="height: 748px; border-radius: 23px"
              />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "../../libs/axios";

export default {
  data() {
    return {
      data: [],
    };
  },
  mounted() {
    this.fetchData();
    this.getLanguageFromURL();
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(
          process.env.VUE_APP_BASE_URL + "/api/front/homebanners"
        );
        this.data = response.data.data;
        console.log("langg", this.$route.params.locale);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
};
</script>
<style scoped>
.slide {
  height: 650px;
}
</style>
