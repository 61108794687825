<template>
  <div id="app">
    <Header />
    <div class="container-fluid">
      <div class="row reverse-rtl">
        <div :class="getToggleNav ? 'col-md-3 animatedLeft' : 'hidden'">
          <Sidebar />
        </div>

        <div
          :class="getToggleNav ? 'col-md-9 animatedLeft' : 'col-md-12 animated'"
          class="px-0 position-relative"
        >
          <notifications-list />

          <router-view />
          <!-- <Contact /> -->
          <Footer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Layout/Header";
import Footer from "@/components/Layout/Footer";
import Contact from "@/views/newsletter/Contact";
import Sidebar from "@/components/Layout/Sidebar";
import NotificationsList from "@/components/Notifications/NotificationsList";
import { mapGetters } from "vuex";
import { checkProductInLocalStorage } from "@/checkProductInDatabase";

export default {
  components: {
    Header,
    Footer,
    Contact,
    Sidebar,
    NotificationsList,
  },
  computed: {
    ...mapGetters(["getToggleNav"]),
  },
  created() {
    this.testProduct();
    this.testUrlBody();
  },
  watch: {
    $route: "testUrlBody", // Watch for route changes and update body background
  },
  methods: {
    testUrlBody() {
      if (
        this.$route.name === "NewLogin" ||
        this.$route.name === "NewRegister" ||
        this.$route.name === "newDashboard"
      ) {
        document.body.style.backgroundColor = "#729db1";
      } else if (
        this.$route.name === "OneShop" ||
        this.$route.name === "OneCustomize"
      ) {
        document.body.style.backgroundColor = "#d4e1e7";
      } else {
        document.body.style.backgroundColor = "#eee9e5";
      }
    },
    toggleNavEvt(val) {
      console.log(val);
      this.toggleNav = !this.toggleNav; // Assuming you want to toggle this.toggleNav, but it's not defined in your data
      console.log(this.toggleNav);
    },
    async testProduct() {
      try {
        await checkProductInLocalStorage();
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>
<style>
.hidden {
  display: none;
}
#nav a.router-link-exact-active {
  color: #f4624f;
}
.bg-blue-dark {
  background: #729db1 !important;
}
</style>
