import http from "../../libs/axios";

export default {
  namespaced: true,
  state: {
    paginats: 0,
  
  },
  getters: {},
  mutations: {
    setPaginats(state, data) {
      console.log(state, data, "aaa");
      state.paginats = data;
    },
 
  },
  actions: {
    fetchCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        http
          .get("/front/categories", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchCategoryProducts({ commit}, queryParams) {
      return new Promise((resolve, reject) => {
        http
          .get(
            `/front/shop?page=${queryParams.pagecont}&size=${queryParams.queryParams}&slug=${queryParams.id}`
          )
          .then((response) => {
            console.log(response.data.meta.total, "responseaaaaaaa");
            commit("setPaginats", response.data.meta.total), resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  
   
  },

 
};
