<template>
    <div class="login">
        <PageBanner />
        <section class="py-5 bg-white">
            <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-12 mx-auto">
                                <div
                                    class="login_wrap widget-taber-content p-30 background-white border-radius-10 mb-md-5 mb-lg-0 mb-sm-5">
                                    <div class="padding_eight_all bg-white">
                                        <div class="heading_s1">
                                            <h3 class="mb-30 text-center">{{ $t("Reset Password") }}</h3>
                                        </div>
                                        <form method="post">
                                            <div class="form-group">
                                                <input type="text" required="" name="email" :placeholder="$t('Your Email')">
                                            </div>
                                            <div class="form-group">
                                                <input required="" type="password" name="password" :placeholder="$t('Password')">
                                            </div>
                                            
                                            <div class="form-group mt-5 text-center">
                                                <button type="submit" class="btn btn-primary btn-block hover-up mx-auto"
                                                    name="login">{{ $t("Send Reset Link") }}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>          
                </div>
            </div>
        </section>
        <GreenBg />
    </div>
</template>

<script>
import PageBanner from '../components/PageBanner.vue';
import GreenBg from '@/components/BeforeFooter/GreenBg';
export default {
    components: {
        PageBanner,
        GreenBg
    }
}
</script>