<template>
  <div>
    <div
      class="contact-from-area padding-20-row-col wow FadeInUp animated"
      style="visibility: visible"
    >
      <div>
        <h3 class="mb-10 text-uppercase contact_home">
          {{ $t("Contact Us") }}
        </h3>
      </div>
      <form
        @submit.prevent="sendMessage(messageData)"
        class="contact-form-style"
        id="contact-form"
        style="border: none"
      >
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="input-style mb-20">
              <input
                name="name"
                v-validate="'required'"
                v-model="messageData.fullName"
                :placeholder="$t('Name')"
                type="text"
              />
              <span class="error_msg">{{ errors.first("name") }}</span>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="input-style mb-20">
              <input
                name="email"
                v-validate="'required|email'"
                :placeholder="$t('Your Email')"
                type="email"
                v-model="messageData.email"
              />
              <span class="error_msg">{{ errors.first("email") }}</span>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="input-style mb-20">
              <input
                name="telephone"
                :placeholder="$t('Your Phone')"
                type="text"
                v-validate="'required'"
                v-model="messageData.phone"
              />
              <span class="error_msg">{{ errors.first("telephone") }}</span>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="input-style mb-20">
              <input
                name="subject"
                :placeholder="$t('Subject')"
                type="text"
                v-validate="'required'"
                v-model="messageData.subject"
              />
              <span class="error_msg">{{ errors.first("subject") }}</span>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="textarea-style mb-30">
              <textarea
                name="message"
                :placeholder="$t('Message')"
                v-validate="'required'"
                v-model="messageData.message"
                style="background: none;"
              ></textarea>
              <span class="error_msg">{{ errors.first("message") }}</span>
            </div>
          </div>
          <div class="col-lg-12 col-md-12" style="text-align: center;">
            <button class="submit btn btn-secondary send-btn">
              {{ $t("Send message") }}
            </button>
          </div>
        </div>
      </form>
      <p class="form-messege"></p>
    </div>
  </div>
</template>

<script>
import PageBanner from "@/components/PageBanner";
import GreenBg from "@/components/BeforeFooter/GreenBg";
import { ref, onUnmounted } from "vue";
import store from "@/store";
import contactStoreModule from "../views/contactUs/contactStoreModule";

export default {
  components: { PageBanner, GreenBg },

  methods: {
    sendMessage(messageData) {
      this.$validator.validateAll().then((success) => {
        if (success) {
          store
            .dispatch("app-contact/sendMessage", messageData)
            .then(() => {
              store.dispatch("addNotification", {
                type: "success",
                message: "Message sent",
              });
            })
            .catch(() => {});
        }
      });
    },
  },
  setup() {
    const messageData = ref({
      fullName: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });

    const HOME_APP_STORE_MODULE_NAME = "app-contact";

    // const router = context.root.$router;

    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) {
      store.registerModule(HOME_APP_STORE_MODULE_NAME, contactStoreModule);
    } else {
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
          store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
      });
    }

    return {
      messageData,
    };
  },
};
</script>

<style scoped>
input:valid {
  border-color: var(--primary);
}

input:invalid {
  border-color: red;
}

textarea:valid {
  border-color: var(--primary);
}

textarea:invalid {
  border-color: red;
}

.error_msg {
  color: red;
  text-align: initial;
  display: block;
  font-size: smaller;
}
.send-btn {
  border-radius: inherit !important;
}
</style>
