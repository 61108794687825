<template>
  <section
    class="green-bg-padding bg-green new-custom-section section-padding top-40"
  >
    <!-- <div>
      <div class="row">
        <div class="col-md-3" v-for="service in data" :key="service.id">
          <div class="card w-100">
            <img :src="service.image" class="card-img-top img-size-green" alt="..." />
            <div class="card-body">
              <h5 class="card-title color-orange" style="font-size: 16px">
                {{
                  $route.params.locale === "en"
                    ? service.title_en
                    : service.title_ar
                }}
              </h5>
              <p
                class="card-text text-center text-service text-banner-custom text-banner-custom-rtl"
              >
                {{
                  $route.params.locale === "en"
                    ? service.description_en
                    : service.description_ar
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="row">
      <div class="col-md-3">
        <div class="green-bg-col-img">
          <img
            src="../../../public/assets/img/icons/user.png"
            class="green-bg-img-1"
            alt="..."
          />
        </div>

        <div class="card-body-custom">
          <h5 class="card-title color-orange">
            {{ $t("Premium Partners") }}
          </h5>
        </div>

        <div class="card-text-div">
          <p class="card-text text-center">
            We only stock the best. Shop thousands of brands vetted for quality
            and reliability.
          </p>
        </div>
      </div>

      <div class="col-md-3">
        <div class="green-bg-col-img">
          <img
            src="../../../public/assets/img/icons/truck.png"
            class="green-bg-img-2"
            alt="..."
          />
        </div>

        <div class="card-body-custom" style="padding-top: 75px">
          <h5 class="card-title color-orange">
            {{ $t("Shipping & Delivery") }}
          </h5>
        </div>

        <div class="card-text-div">
          <p class="card-text text-center">
            We only stock the best. Shop thousands of brands vetted for quality
            and reliability.
          </p>
        </div>
      </div>

      <div class="col-md-3">
        <div class="green-bg-col-img">
          <img
            src="../../../public/assets/img/icons/support.png"
            class="green-bg-img-3"
            alt="..."
          />
        </div>

        <div class="card-body-custom" >
          <h5 class="card-title color-orange">
            {{ $t("Shipping & Delivery") }}
          </h5>
        </div>

        <div class="card-text-div">
          <p class="card-text text-center">
            We only stock the best. Shop thousands of brands vetted for quality
            and reliability.
          </p>
        </div>
      </div>

      <div class="col-md-3">
        <div class="green-bg-col-img">
          <img
            src="../../../public/assets/img/icons/home.png"
            class="green-bg-img-3"
            alt="..."
          />
        </div>

        <div class="card-body-custom">
          <h5 class="card-title color-orange">
            {{ $t("Premium Membership") }}
          </h5>
        </div>

        <div class="card-text-div">
          <p class="card-text text-center">
            We only stock the best. Shop thousands of brands vetted for quality
            and reliability.
          </p>
        </div>
      </div>

      <!-- <div class="col-md-3">
        <div class="card w-100">
          <img
            src="../../../public/assets/img/icons/truck.png"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title color-orange">
              {{ $t("Shipping & Delivery") }}
            </h5>
            <p class="card-text text-center">
              {{
                $t(
                  "We only stock the best. Shop thousands of brands vetted for quality and reliability."
                )
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card w-100">
          <img
            src="../../../public/assets/img/icons/support.png"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title color-orange">
              {{ $t("Customer Support") }}
            </h5>
            <p class="card-text text-center">
              {{
                $t(
                  "We only stock the best. Shop thousands of brands vetted for quality and reliability."
                )
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card w-100">
          <img
            src="../../../public/assets/img/icons/home.png"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title color-orange">
              {{ $t("Premium Membership") }}
            </h5>
            <p class="card-text text-center">
              {{
                $t(
                  "We only stock the best. Shop thousands of brands vetted for quality and reliability."
                )
              }}
            </p>
          </div>
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>
import http from "../../libs/axios";

export default {
  data() {
    return {
      data: {},
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await http.get(
          process.env.VUE_APP_BASE_URL + "/api/front/services"
        );
        this.data = response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
};
</script>

<style scoped>
/* .card-body {
  padding: 1rem 0;
} */

.card-text {
  font-weight: 100;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #f9f8f7;
}
@media screen and (max-width: 767px) {
  .bg-green .card-text {
    text-align: center;
  }
}
</style>
