<template>
  <div class="shopping_cart">
    <PageBanner />
    <section class="py-5 bg-white">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive" v-if="wishListsData">
              <table class="table table-bordered shopping-summery text-center">
                <thead>
                  <tr class="main-heading">
                    <th scope="col" colspan="2">{{ $t("Product") }}</th>
                    <th scope="col">{{ $t("Price") }}</th>
                    <th scope="col">{{ $t("Stock Status") }}</th>
                    <th scope="col">{{ $t("Size") }}</th>
                    <th scope="col">{{ $t("Color") }}</th>
                    <th scope="col">{{ $t("Action") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(wishlist, index) in wishListsData" :key="index">
                    <td class="image product-thumbnail">
                      <img
                        :src="wishlist.product.couverture"
                        alt="#"
                        width="100"
                      />
                    </td>
                    <td class="product-des product-name">
                      <h5 class="product-name  product-name-cart">
                        <LocalizedLink :to="'product/' + wishlist.product.slug">{{
                          wishlist.product.name
                        }}</LocalizedLink>
                      </h5>
                      <p class="font-xs">
                        {{ wishlist.product.description }}
                      </p>
                    </td>
                    <td class="price" data-title="Price">
                      <span>{{ wishlist.product.price }}</span>
                    </td>
                    <td class="text-center" data-title="Stock">
                      <span
                        :class="
                          wishlist.product.quantity > 0
                            ? 'color3 font-weight-bold'
                            : 'text-danger font-weight-bold'
                        "
                        >{{
                          wishlist.product.quantity > 0
                            ? $t("In Stock")
                            : $t("Out of Stock")
                        }}</span
                      >
                    </td>

                    <td class="text-center">
                      <span class="color3">{{
                        wishlist.size ? wishlist.size : "Not Found"
                      }}</span>
                    </td>
                    <td class="text-center">
                      <span class="color3">{{
                        wishlist.color ? wishlist.color : "Not Found"
                      }}</span>
                    </td>

                    <td class="action" data-title="Remove">
                      <a @click.prevent="removeFromCart(wishlist)"
                        ><i class="fa-solid fa-trash">&nbsp;</i></a
                      >
                    </td>
                  </tr>

                  <!-- <tr>
                                        <td class="image"><img src="../../../public/assets/img/prods/prod3.png" alt="#" width="100"></td>
                                        <td class="product-des">
                                            <h5 class="product-name">
                                                <LocalizedLink :to="'product'">
                                                    {{ $t('Red Casual Chair') }}
                                                </LocalizedLink>
                                            </h5>
                                            <p class="font-xs">
                                                Erat amet et et amet diam et et.<br> Justo amet at dolore
                                            </p>
                                        </td>
                                        <td class="price" data-title="Price"><span>SAR 3,900</span></td>
                                        <td class="text-center" data-title="Stock">
                                            <span class="text-danger font-weight-bold">{{ $t("Out of Stock") }}</span>
                                        </td>
                                        <td class="text-right" data-title="Cart">
                                            <button class="btn btn-sm btn-secondary"><i class="fa-solid fa-cart-plus mr-5"></i>
                                                {{ $t("Contact Us") }}
                                            </button>
                                        </td>
                                        <td class="action" data-title="Remove"><a href="#"><i class="fa-solid fa-trash">&nbsp;</i></a></td>
                                    </tr> -->
                </tbody>
              </table>
            </div>

            <nav aria-label="Page navigation example" class="mt-4">
              <!-- <ul class="pagination pagination-md">
                <li class="page-item active" aria-current="page">
                  <a class="page-link" href="#">1</a>
                </li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item"><a class="page-link" href="#">4</a></li>
                <li class="page-item"><a class="page-link" href="#">5</a></li>
                <li class="page-item"><a class="page-link" href="#">6</a></li>
              </ul> -->
              <Pagination
                v-if="wishListsData"
                :total-pages="Math.floor(paginats / recordsPerPage)"
                :per-page="recordsPerPage"
                :current-page="page"
                @pagechanged="onPageChange"
              />
            </nav>
          </div>
        </div>
      </div>
    </section>
    <GreenBg />
  </div>
</template>

<script>
import PageBanner from "../../components/PageBanner.vue";
import GreenBg from "../../components/BeforeFooter/GreenBg.vue";
import LocalizedLink from "@/components/LocalizedLink";
import productsStoreModule from "./productsStoreModule";
import Pagination from "@/components/pagination/Pagination";
import store from "@/store";
import { mapState } from "vuex";
import { onMounted, ref, onUnmounted } from "vue";
export default {
  components: { PageBanner, GreenBg, LocalizedLink, Pagination },
  computed: {
    ...mapState("app-wishlist-detail", ["paginats"]),
  },

  methods: {
    removeFromCart(item) {
      store
        .dispatch("app-wishlist-detail/RemoveWishList", item.id)
        .then(() => {
            this.$toasted.show("wishlist deleted successfully");

        
        });

      let index = this.wishListsData.indexOf(item);

      if (index > -1) {
        // let product = addToWishlistData.value[index];
        // state.cartCount -= product.quantity;

        this.wishListsData.splice(index, 1);
      }
    },
  },

  setup() {
    const wishListsData = ref(null);
    const EVENTS_APP_STORE_MODULE_NAME = "app-wishlist-detail";
    const page = ref(1);
    const count = ref(1);
    const maxQuantity = ref(null);

    const addToCartData = ref({
      id: "",
      name: "",
      quantity: "",
      price: "",
      color: "",
      size: "",
      maxQuantity: "",
      image: "",
      description: "",
      slug: "",
    });

    const totalRecords = ref(0);
    const recordsPerPage = ref(5);

    // Register module
    if (!store.hasModule(EVENTS_APP_STORE_MODULE_NAME))
      store.registerModule(EVENTS_APP_STORE_MODULE_NAME, productsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENTS_APP_STORE_MODULE_NAME))
        store.unregisterModule(EVENTS_APP_STORE_MODULE_NAME);
    });
    const LoadEvents = (pagecount, recordsPerPage) => {
      console.log(pagecount, recordsPerPage, "xxxx");
      store
        .dispatch("app-wishlist-detail/fetchWishLists", {
          pagecont: pagecount,
          queryParams: recordsPerPage,
        })
        .then((response) => {
          totalRecords.value = response.data.meta.total;
          wishListsData.value = response.data.data;
          console.log(wishListsData.value, "rrrrrrrrr");
        })
        .catch(() => {});
    };

    const onPageChange = (p) => {
      page.value = p;
      LoadEvents(page.value, recordsPerPage.value);
    };
    onMounted(() => {
      LoadEvents(page.value, recordsPerPage.value);
    });
    const quantityUp = (data) => {
      if (count.value < data) {
        count.value += 1;
      }
    };
    const quantityDown = () => {
      if (count.value >= 2) {
        count.value -= 1;
      }
    };

    // const addToCart = (wishlist) => {
    //   console.log(wishlist, "eeeeee");
    //   store
    //     .dispatch("app-wishlist-detail/fetchProductSize", {
    //       id: wishlist.id,
    //       size: wishlist.size,
    //       color: wishlist.color,
    //     })
    //     .then((response) => {
    //       console.log(response.data.data, "zezezeze");
    //       maxQuantity.value =
    //         maxQuantity.value != ""
    //           ? response.data.data[0].quantity
    //           : wishlist.produc.quantity;
    //     })
    //     .catch(() => {});
    //   addToCartData.value.id = wishlist.product.id;
    //   addToCartData.value.name = wishlist.product.name;
    //   addToCartData.value.quantity = count.value;
    //   addToCartData.value.price = wishlist.product.price;
    //   //   addToCartData.value.maxQuantity = maxQuantity;
    //   addToCartData.value.image = wishlist.product.couverture;
    //   addToCartData.value.description = wishlist.product.description;
    //   addToCartData.value.slug = wishlist.product.slug;

    //   store.commit("addToCartGlobal", addToCartData.value);

    //   count.value = 1;
    //   addToCartData.value = {
    //     id: "",
    //     name: "",
    //     quantity: "",
    //     price: "",
    //     color: "",
    //     size: "",
    //     maxQuantity: "",
    //     image: "",
    //     description: "",
    //     slug: "",
    //   };
    // };

    return {
      wishListsData,
      totalRecords,
      recordsPerPage,
      page,
      onPageChange,
      LoadEvents,
      quantityUp,
      quantityDown,
      count,
      //   addToCart,
      addToCartData,
      maxQuantity,
    };
  },
};
</script>