<template>
  <div class="categories">
    <PageBanner />
    <section id="categories_list">
      <div class="container px-4 py-5">
        <div class="row">
          <div class="col-12 mb-5">
            <h2 class="text-uppercase text-center">
              {{ $t("Discover Our Products") }}
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 mb-4" v-for="made in madesData" :key="made.id">
            <LocalizedLink
              :to="'made/' + made.slug + '/categories'"
              :title="made.name"
            >
              <div class="card made-miniature position-relative bg_transparent">
                <img
                  :src="made.photo"
                  class="card-img-top img-custom-size"
                  :alt="made.name"
                />
                <div class="card-body">
                  <h5 class="product-name">
                    <span class="text-dark"> {{ made.name }} </span>
                  </h5>
                </div>
              </div>
            </LocalizedLink>
          </div>
        </div>
      </div>
    </section>
    <GreenBg />
  </div>
</template>

<script>
import PageBanner from "../../components/PageBanner.vue";
import GreenBg from "../../components/BeforeFooter/GreenBg.vue";
import LocalizedLink from "@/components/LocalizedLink";
import { ref, onUnmounted } from "vue";
import store from "@/store";
import madesStoreModule from "./madesStoreModule";
import router from "@/router";
export default {
  components: { PageBanner, GreenBg, LocalizedLink },

  setup() {
    const madesData = ref(null);
    const HOME_APP_STORE_MODULE_NAME = "app-made";

    // const router = context.root.$router;

    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) {
      store.registerModule(HOME_APP_STORE_MODULE_NAME, madesStoreModule);
    } else {
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
          store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
      });
    }

    store
      .dispatch("app-made/fetchMades", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        madesData.value = response.data.data;
      })
      .catch(() => {});

    return {
      madesData,
    };
  },
};
</script>

<style scoped>
.made-miniature .card-img-top {
  max-height: 240px;
}

.made-miniature .card-body {
  /* position: absolute;
    bottom: 0; */
  width: 100%;
  /* background-color: rgba(34, 23, 23, 0.49); */
  opacity: 0.9;
  border-radius: 0 0 13px 15px;
  padding: 7px 0;
}

.made-miniature .card-body h5 a {
  color: var(--brown);
}

.made-miniature .card-body h5 a:hover {
  text-decoration: none;
  color: var(--secondary);
}
.categories .card {
  border-radius: 20px;
  border: none;
}
.bg_transparent {
  background-color: transparent;
}
</style>
