<template>
  <div>
    <PageBanner />
    <div class="payment-page">
      <div class="payment-success">
        <br />
        <br />

        <div class="container mt-5" style="margin-bottom: 20px">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header bg-danger text-white"></div>
                <div class="card-body">
                  <h2 class="text-danger">
                    <b>{{ $t("Payment not made, please try again") }}</b>
                  </h2>
                  <p></p>
                  <br />
                  <br />
                  <a href="/" class="btn btn-primary">
                    {{ $t("Back to homepage") }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentContent from "@/components/PaymentContent.vue";
import axios from "../libs/axios";
import PageBanner from "../components/PageBanner.vue";

export default {
  props: ["invoiceId"],
  data() {
    return {
      paymentSuccess: true,
      invoiceData: [],
      products: [],
    };
  },
  created() {
    this.testInvoice();
  },
  methods: {
    async testInvoice() {
      try {
        const invoiceId = this.$route.params.invoiceId;
        const hashId = this.$route.params.hashId;
        const response = await axios.get(
          process.env.VUE_APP_BASE_URL +
            "/api/testinvoice/" +
            invoiceId +
            "/" +
            hashId
        );
        console.log(response.data);
        this.invoiceData.push(response.data.invoice);
        this.products.push(this.invoiceData[0].products);
      } catch (error) {
        console.error("Error fetching payment status:", error);
      }
    },
  },
  components: {
    PaymentContent,
    PageBanner,
  },
};
</script>

<style scoped>
.payment-success {
  text-align: center;
  padding: 20px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

p {
  font-size: 16px;
}
</style>
