<template>
  <div>
    <section
      id="categories_banner"
      :style="{ backgroundImage: 'url(' + data.image + ')' }"
      v-if="!testUrl()"
    >
      <h2 class="text-white">
        {{ $route.params.locale === "en" ? data.title_en : data.title_ar }}
      </h2>
    </section>

    <section style="height: 300px; background: none" class="page-nav"></section>
  </div>
</template>
<script>
import LocalizedLink from "@/components/LocalizedLink";
import { getSupportedLocales } from "@/util/i18n/supported-locales";
import axios from "../libs/axios";

export default {
  components: {
    LocalizedLink,
  },
  data() {
    return {
      currentLocale: this.$route.params.locale === "ar" ? "عربي" : "English",
      data: {},
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(
          process.env.VUE_APP_BASE_URL + "/api/front/websitesetting"
        );
        this.data = response.data.data;
        console.log("sttings", this.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    testUrl() {
      if (
        this.$route.name == "Mades" ||
        this.$route.name == "Categories" ||
        this.$route.name == "Products" ||
        this.$route.name == "AboutUs" ||
        this.$route.name == "Customize" ||
        this.$route.name == "Portfolio" ||
        this.$route.name == "NewLogin" ||
        this.$route.name == "OneShop" ||
        this.$route.name == "newDashboard" ||
        this.$route.name == "NewRegister" ||
        this.$route.name == "OneCustomize" ||
        this.$route.name == "Contact" ||
        this.$route.name == "AllShop"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
