<template>
  <div class="col-lg-5">
    <div
      class="login_wrap widget-taber-content p-30 background-white border-radius-10 mb-md-5 mb-lg-0 mb-sm-5"
    >
      <div class="padding_eight_all bg-white">
        <div class="heading_s1">
          <h3 class="mb-30">{{ $t("Login") }}</h3>
        </div>
        <form @submit.prevent="login">
          <div class="form-group">
            <input
              v-model="userEmail"
              type="text"
              required=""
              name="emailLogin"
              v-validate="'required|email'"
              :placeholder="$t('Your Email')"
            />
            <span class="">{{ errors.first("emailLogin") }}</span>
          </div>
          <div class="form-group">
            <input
              v-model="password"
              type="password"
              name="passwordLogin"
              v-validate="'required'"
              :placeholder="$t('Password')"
            />
            <span class="">{{ errors.first("passwordLogin") }}</span>
          </div>
          <div class="login_footer form-group">
            <div class="chek-form">
              <div class="custome-checkbox">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="checkbox"
                  id="exampleCheckbox1"
                  value=""
                />
                <label class="form-check-label" for="exampleCheckbox1"
                  ><span> {{ $t("Remember me") }}</span></label
                >
              </div>
            </div>
            <LocalizedLink class="text-muted" :to="'resetPassword'">
              {{ $t("Forgot password?") }}
            </LocalizedLink>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary btn-block hover-up">
              {{ $t("Log In") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted } from "vue";
import store from "@/store";
import loginStoreModule from "./loginStoreModule";
import useJwt from "@/auth/jwt/useJwt";
import { getHomeRouteForLoggedInUser } from "../../auth/utils";

export default {
  data() {
    return {
      password: "",
      userEmail: "",
    };
  },

  mounted() {
    const user = JSON.parse(localStorage.getItem("userData"));
    if (user) {
      this.$router.push(`/${this.$route.params.locale}/account`);
    }
  },

  methods: {
    login() {
      useJwt
        .login({
          email: this.userEmail,
          password: this.password,
        })
        .then((response) => {
          useJwt.setToken(response.data.accessToken);
          useJwt.setRefreshToken(response.data.refreshToken);
          localStorage.setItem(
            "userData",
            JSON.stringify(response.data.userData)
          );
          this.$ability.update(response.data.userData.ability);

          this.$router
            .replace(getHomeRouteForLoggedInUser(response.data.userData.role))
            .then(() => {
              this.$store.commit("setAuthUser", response.data.userData);
            });
        })
        .catch((error) => {
          console.log(error);
          //   this.$refs.loginForm.setErrors(error.response.data.error)
          // this.$refs.loginForm.setErrors(error);
        });
    },
  },
  setup() {
    const userData = ref({
      userName: "",
      email: "",
      password: "",
    });

    const HOME_APP_STORE_MODULE_NAME = "app-login";

    // const router = context.root.$router;

    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) {
      store.registerModule(HOME_APP_STORE_MODULE_NAME, loginStoreModule);
    } else {
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
          store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
      });
    }

    return {
      userData,
    };
  },
};
</script>

<style scoped>
input:valid {
  border-color: var(--primary);
}

input:invalid {
  border-color: red;
}

textarea:valid {
  border-color: var(--primary);
}

textarea:invalid {
  border-color: red;
}

span {
  color: red;
}
</style>
