import http from "../../libs/axios";
import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    paginats: 0,
  },
  getters: {},
  mutations: {},
  actions: {
    subscribe(ctx, contact) {
      return new Promise((resolve, reject) => {
        http
          .post("front/newsletter/add", { contact: contact })
          .then((response) => {
            Swal.fire({
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
            resolve(response);
            setTimeout(() => {
              location.reload();
            }, 2000);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
