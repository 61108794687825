<template>
  <div class="shopping_cart">
    <PageBanner />
    <section class="py-5 bg-white">
      <div class="container">
        <!-- <div class="row">
          <div class="col-lg-6 mb-sm-15">
            <div class="toggle_info">
              <span
                ><i class="fa-regular fa-user mr-10"></i
                ><span class="text-muted">
                  {{ $t("Already have an account?") }}
                </span>
                <a
                  href="#loginform"
                  data-bs-toggle="collapse"
                  class="collapsed"
                  aria-expanded="false"
                  >{{ $t("Click here to login") }}</a
                ></span
              >
            </div>
            <div class="panel-collapse collapse login_form" id="loginform">
              <div class="panel-body">
                <p class="mb-30 font-sm">
                  {{
                    $t(
                      "If you have shopped with us before, please enter your details below. If you are a new customer, please proceed to the Billing &amp; Shipping section."
                    )
                  }}
                </p>
                <form method="post">
                  <div class="form-group">
                    <input
                      type="text"
                      name="email"
                      :placeholder="$t('Username Or Email')"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      name="password"
                      :placeholder="$t('Password')"
                    />
                  </div>
                  <div class="login_footer form-group">
                    <div class="check-form">
                      <div class="custome-checkbox">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          id="remember"
                          value=""
                        />
                        <label class="form-check-label" for="remember"
                          ><span>{{ $t("Remember me") }}</span></label
                        >
                      </div>
                    </div>
                    <a href="#">{{ $t("Forgot password?") }}</a>
                  </div>
                  <div class="form-group">
                    <button class="btn btn-sm btn-primary" name="login">
                      {{ $t("Log in") }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="toggle_info">
              <span
                ><i class="fa-regular fa-tag mr-10"></i
                ><span class="text-muted">{{ $t("Have a coupon?") }}</span>
                <a
                  href="#coupon"
                  data-bs-toggle="collapse"
                  class="collapsed"
                  aria-expanded="false"
                >
                  {{ $t("Click here to enter your code") }}</a
                ></span
              >
            </div>
            <div class="panel-collapse collapse coupon_form" id="coupon">
              <div class="panel-body">
                <p class="mb-30 font-sm">
                  {{ $t("If you have a coupon code, please apply it below.") }}
                </p>
                <form method="post">
                  <div class="form-group">
                    <input
                      type="text"
                      :placeholder="$t('Enter Coupon Code...')"
                    />
                  </div>
                  <div class="form-group">
                    <button class="btn btn-primary btn-md" name="login">
                      {{ $t("Apply Coupon") }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col-12">
            <div class="divider mt-50 mb-50"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-25">
              <h4>{{ $t("Billing Details") }}</h4>
            </div>
            <form method="post">
              <div class="form-group">
                <input
                  v-model="billingData.name"
                  type="text"
                  name="fname"
                  :placeholder="$t('First name *')"
                  v-validate="'required'"
                />
                <span class="">{{ errors.first("fname") }}</span>
              </div>

              <div class="form-group">
                <input
                  v-model="billingData.email"
                  type="email"
                  name="email"
                  :placeholder="$t('Email address *')"
                  v-validate="'required|email'"
                />
                <span class="">{{ errors.first("email") }}</span>
              </div>

              <div class="form-group">
                <div class="custom_select">
                  <b-form-select
                    v-model="billingData.country"
                    :options="countries"
                    @input="changeCountry(billingData.country)"
                    v-validate="'required'"
                  ></b-form-select>
                </div>
                <span class="">{{ errors.first("country") }}</span>
              </div>
              <div class="form-group">
                <b-form-select
                  v-model="billingData.city"
                  :options="cities"
                  v-validate="'required'"
                ></b-form-select>
                <!-- <input
                  v-model="billingData.state"
                  type="text"
                  name="state"
                  :placeholder="$t('State / County *')"
                  v-validate="'required'"
                /> -->
                <span class="">{{ errors.first("state") }}</span>
              </div>
              <div class="form-group">
                <input
                  v-model="billingData.postalCode"
                  type="text"
                  name="zipcode"
                  :placeholder="$t('Postcode / ZIP *')"
                  v-validate="'required'"
                />
                <span class="">{{ errors.first("zipcode") }}</span>
              </div>
              <div class="form-group">
                <input
                  type="text"
                  name="billing_address"
                  v-model="billingData.address"
                  :placeholder="$t('Address *')"
                  v-validate="'required'"
                />
                <span class="">{{ errors.first("billing_address") }}</span>
              </div>

              <div class="form-group">
                <input
                  v-model="billingData.phone"
                  type="text"
                  name="phone"
                  :placeholder="$t('Phone *')"
                  v-validate="'required'"
                />
                <span class="">{{ errors.first("phone") }}</span>
              </div>

              <!-- <div class="form-group">
                <div class="checkbox">
                  <div class="custome-checkbox">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="checkbox"
                      id="createaccount"
                    />
                    <label
                      class="form-check-label label_info"
                      data-bs-toggle="collapse"
                      href="#collapsePassword"
                      data-target="#collapsePassword"
                      aria-controls="collapsePassword"
                      for="createaccount"
                      ><span>{{ $t("Create an account?") }}</span></label
                    >
                  </div>
                </div>
              </div> -->
              <!-- <div
                id="collapsePassword"
                class="form-group create-account collapse in"
              >
                <input type="password" placeholder="Password" name="password" />
              </div>
              <div class="ship_detail">
                <div class="form-group">
                  <div class="chek-form">
                    <div class="custome-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="checkbox"
                        id="differentaddress"
                      />
                      <label
                        class="form-check-label label_info"
                        data-bs-toggle="collapse"
                        data-target="#collapseAddress"
                        href="#collapseAddress"
                        aria-controls="collapseAddress"
                        for="differentaddress"
                        ><span>{{
                          $t("Ship to a different address?")
                        }}</span></label
                      >
                    </div>
                  </div>
                </div>
                <div id="collapseAddress" class="different_address collapse in">
                  <div class="form-group">
                    <input
                      type="text"
                      name="fname"
                      :placeholder="$t('First name *')"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      name="lname"
                      :placeholder="$t('Last name *')"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      name="cname"
                      :placeholder="$t('Company Name')"
                    />
                  </div>
                  <div class="form-group">
                    <div class="custom_select">
                      <select class="form-control select-active">
                        <option value="">
                          {{ $t("Select an option...") }}
                        </option>
                        <option value="AX">Aland Islands</option>
                        <option value="AF">Afghanistan</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AU">Australia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="PW">Belau</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BM">Bermuda</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia</option>
                        <option value="BQ">
                          Bonaire, Saint Eustatius and Saba
                        </option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BV">Bouvet Island</option>
                        <option value="BR">Brazil</option>
                        <option value="IO">
                          British Indian Ocean Territory
                        </option>
                        <option value="VG">British Virgin Islands</option>
                        <option value="BN">Brunei</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CV">Cape Verde</option>
                        <option value="KY">Cayman Islands</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CX">Christmas Island</option>
                        <option value="CC">Cocos (Keeling) Islands</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo (Brazzaville)</option>
                        <option value="CD">Congo (Kinshasa)</option>
                        <option value="CK">Cook Islands</option>
                        <option value="CR">Costa Rica</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CW">CuraÇao</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FK">Falkland Islands</option>
                        <option value="FO">Faroe Islands</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GF">French Guiana</option>
                        <option value="PF">French Polynesia</option>
                        <option value="TF">French Southern Territories</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GI">Gibraltar</option>
                        <option value="GR">Greece</option>
                        <option value="GL">Greenland</option>
                        <option value="GD">Grenada</option>
                        <option value="GP">Guadeloupe</option>
                        <option value="GT">Guatemala</option>
                        <option value="GG">Guernsey</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HM">
                          Heard Island and McDonald Islands
                        </option>
                        <option value="HN">Honduras</option>
                        <option value="HK">Hong Kong</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran</option>
                        <option value="IQ">Iraq</option>
                        <option value="IM">Isle of Man</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="CI">Ivory Coast</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JE">Jersey</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA">Laos</option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MO">Macao S.A.R., China</option>
                        <option value="MK">Macedonia</option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MQ">Martinique</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="YT">Mayotte</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">Micronesia</option>
                        <option value="MD">Moldova</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MS">Montserrat</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="AN">Netherlands Antilles</option>
                        <option value="NC">New Caledonia</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="NU">Niue</option>
                        <option value="NF">Norfolk Island</option>
                        <option value="KP">North Korea</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PS">Palestinian Territory</option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PN">Pitcairn</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="QA">Qatar</option>
                        <option value="IE">Republic of Ireland</option>
                        <option value="RE">Reunion</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russia</option>
                        <option value="RW">Rwanda</option>
                        <option value="ST">São Tomé and Príncipe</option>
                        <option value="BL">Saint Barthélemy</option>
                        <option value="SH">Saint Helena</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="SX">Saint Martin (Dutch part)</option>
                        <option value="MF">Saint Martin (French part)</option>
                        <option value="PM">Saint Pierre and Miquelon</option>
                        <option value="VC">
                          Saint Vincent and the Grenadines
                        </option>
                        <option value="SM">San Marino</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="GS">
                          South Georgia/Sandwich Islands
                        </option>
                        <option value="KR">South Korea</option>
                        <option value="SS">South Sudan</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SJ">Svalbard and Jan Mayen</option>
                        <option value="SZ">Swaziland</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syria</option>
                        <option value="TW">Taiwan</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TK">Tokelau</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TC">Turks and Caicos Islands</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB">United Kingdom (UK)</option>
                        <option value="US">USA (US)</option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VA">Vatican</option>
                        <option value="VE">Venezuela</option>
                        <option value="VN">Vietnam</option>
                        <option value="WF">Wallis and Futuna</option>
                        <option value="EH">Western Sahara</option>
                        <option value="WS">Western Samoa</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      name="billing_address"
                      :placeholder="$t('Address *')"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      name="billing_address2"
                      :placeholder="$t('Address line2')"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      name="city"
                      :placeholder="$t('City / Town *')"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      name="state"
                      :placeholder="$t('State / County *')"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      name="zipcode"
                      :placeholder="$t('Postcode / ZIP *')"
                    />
                  </div>
                </div>
              </div> -->
              <div class="mb-20">
                <h5>{{ $t("Additional information") }}</h5>
              </div>
              <div class="form-group mb-30">
                <textarea
                  v-model="billingData.additionnalInfo"
                  rows="5"
                  :placeholder="$t('Order notes')"
                ></textarea>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <div class="order_review">
              <div class="mb-20">
                <h4>{{ $t("Your Orders") }}</h4>
              </div>
              <div class="table-responsive order_table text-center">
                <table class="table">
                  <thead>
                    <tr>
                      <th colspan="2">{{ $t("Product") }}</th>
                      <th>{{ $t("Total") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(cart, key) in $store.state.cart" :key="key">
                      <td class="image product-thumbnail">
                        <img
                          :src="cart.image"
                          alt="#"
                          class="img-thumbnail"
                          width="75"
                        />
                      </td>
                      <td>
                        <h5>
                          <LocalizedLink :to="'product'"
                            >{{ cart.name }} ({{
                              cart.quantity
                            }})</LocalizedLink
                          >
                        </h5>
                        <span class="product-qty"
                          >{{ cart.price.toFixed(2) }}
                        </span>
                      </td>
                      <td>
                        {{ Number((cart.price * cart.quantity).toFixed(3)) }}
                        {{ $t("SAR") }}
                      </td>
                    </tr>

                    <tr>
                      <th>{{ $t("SubTotal") }}</th>
                      <td class="product-subtotal" colspan="2">
                        {{ $t("SAR") }} {{ totalPrice }}
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t("Shipping") }}</th>
                      <td colspan="2">
                        {{ shippment }}
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t("Total") }}</th>
                      <td colspan="2" class="product-subtotal">
                        <span class="font-xl text-brand fw-900"
                          >{{ $t("SAR") }} {{ finalTotal }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="bt-1 border-color-1 mt-30 mb-30"></div>
              <div class="payment_method">
                <div class="mb-25">
                  <h5>{{ $t("Payment") }}</h5>
                </div>
                <!-- <div class="payment_option">
                  <div class="custome-radio">
                    <input
                      class="form-check-input"
                     
                      type="radio"
                      name="payment_option"
                      id="exampleRadios3"
                      checked=""
                    />
                    <label
                      class="form-check-label"
                      for="exampleRadios3"
                      data-bs-toggle="collapse"
                      data-target="#bankTranfer"
                      aria-controls="bankTranfer"
                      >{{ $t("Direct Bank Transfer") }}</label
                    >
                    <div class="form-group collapse in" id="bankTranfer">
                      <p class="text-muted mt-5">
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have suffered alteration.
                      </p>
                    </div>
                  </div>
                  <div class="custome-radio">
                    <input
                      class="form-check-input"
                     
                      type="radio"
                      name="payment_option"
                      id="exampleRadios4"
                      checked=""
                    />
                    <label
                      class="form-check-label"
                      for="exampleRadios4"
                      data-bs-toggle="collapse"
                      data-target="#checkPayment"
                      aria-controls="checkPayment"
                      >{{ $t("Check Payment") }}</label
                    >
                    <div class="form-group collapse in" id="checkPayment">
                      <p class="text-muted mt-5">
                        Please send your cheque to Store Name, Store Street,
                        Store Town, Store State / County, Store Postcode.
                      </p>
                    </div>
                  </div>
                  <div class="custome-radio">
                    <input
                      class="form-check-input"
                     
                      type="radio"
                      name="payment_option"
                      id="exampleRadios5"
                      checked=""
                    />
                    <label
                      class="form-check-label"
                      for="exampleRadios5"
                      data-bs-toggle="collapse"
                      data-target="#paypal"
                      aria-controls="paypal"
                      >{{ $t("Paypal") }}</label
                    >
                    <div class="form-group collapse in" id="paypal">
                      <p class="text-muted mt-5">
                        {{
                          $t(
                            "Pay via PayPal; you can pay with your credit card if you don't have a PayPal account."
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </div> -->
                <div id="card-element"></div>
              </div>
              <button
                class="btn btn-outline-primary btn-block mt-3"
                @click="pay"
              >
                {{ $t("Place Order") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <GreenBg />
  </div>
</template>

<script>
import PageBanner from "../components/PageBanner.vue";
import GreenBg from "../components/BeforeFooter/GreenBg.vue";
import LocalizedLink from "@/components/LocalizedLink";
import axios from "../libs/axios";
import { BFormSelect } from "bootstrap-vue";

export default {
  data() {
    return {
      show: true,
      // payement
      amount: null,
      brand: "",

      config: {
        cardBrand: "",
        countryCode: "SAU", // Here, add your Country Code.
        sessionId: "", // Here you add the "SessionId" you receive from InitiateSession Endpoint.
        cardViewId: "card-element",
        // The following style is optional.
        style: {
          hideCardIcons: false,
          direction: "ltr",
          cardHeight: 270,
          input: {
            color: "black",
            fontSize: "13px",
            fontFamily: "robbot",
            inputHeight: "50px",
            inputMargin: "4px",
            borderColor: "c7c7c7",
            borderWidth: "1px",
            borderRadius: "8px",
            boxShadow: "",
            placeHolder: {
              holderName: "Name On Card",
              cardNumber: "Number",
              expiryDate: "MM / YY",
              securityCode: "CVV",
            },
          },
          label: {
            display: true,
            color: "#141b4dfa",
            fontSize: "13px",
            fontWeight: "normal",
            fontFamily: "robbot",
            text: {
              holderName: "Card Holder Name",
              cardNumber: "Card Number",
              expiryDate: "Expiry Date",
              securityCode: "Security Code",
            },
          },
          error: {
            borderColor: "red",
            borderRadius: "8px",
            boxShadow: "0px",
          },
        },
      },
      billingData: {
        firstName: "",
        lastName: "",
        companyName: "",
        country: null,
        address: "",
        addressTwo: "",
        city: null,
        postalCode: "",
        phone: "",
        email: "",
        additionnalInfo: "",
      },
      countriesData: null,
      countries: [],
      cities: [],
      shippment: 0,
    };
  },
  mounted() {
    //prod
    //"https://sa.myfatoorah.com/cardview/v2/session.js"
    // const script = document.createElement("script");
    // script.src = "https://demo.myfatoorah.com/cardview/v2/session.js";
    // document.head.appendChild(script);
    this.initiatePayement();
    this.getCounties();

    const user = JSON.parse(localStorage.getItem("userData"));

    this.billingData.email = user.email;
    this.billingData.name = user.name;

    const cart = this.$store.state.cart;
    if (!user) {
      this.$router.push(`/${this.$route.params.locale}/login`);
    }
    if (cart.length == 0) {
      this.$router.push(`/${this.$route.params.locale}/products`);
    }
  },
  components: { PageBanner, GreenBg, LocalizedLink, BFormSelect },
  computed: {
    totalPrice() {
      let total = 0;

      for (let item of this.$store.state.cart) {
        total += item.quantity * item.price;
      }

      return total.toFixed(3);
    },

    finalTotal() {
      return (Number(this.totalPrice) + this.shippment).toFixed(3);
    },
  },
  methods: {
    // pay() {
    //   this.$validator.validateAll().then((success) => {
    //     if (success) {
    //       myFatoorah
    //         .submit()
    //         .then((response) => {
    //           this.config.sessionId = response.sessionId;
    //           this.config.cardBrand = response.cardBrand;
    //           this.ExecutePayment();
    //         })
    //         .catch((error) => {
    //           console.error(error);
    //         });
    //     }
    //   });
    // },
    pay() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          let data = {
            brand: this.config.cardBrand,
            cart: this.$store.state.cart,
            totalprice: this.totalPrice,
            finalamount: this.finalTotal,
            user_id: localStorage.getItem("userData"),
            // sessionId: this.config.sessionId,
            billing: this.billingData,
            shippment: this.shippment,
            CallBackUrl: "/finalize",
          };
          console.log(data);
          axios
            .get(process.env.VUE_APP_BASE_URL + "/api/payement", {
              params: data,
            })
            .then((response) => {
              window.location.replace(response.data.Data.invoiceURL);
              // invoiceId
            })
            .catch(() => {});
        }
      });
    },
    ExecutePayment() {
      this.show = true;
      axios
        .post(process.env.VUE_APP_BASE_URL + "/api/embeddedPayment", {
          brand: this.config.cardBrand,
          cart: this.$store.state.cart,
          amount: (this.totalPrice * 1.15 + Number(this.shippment)).toFixed(2),
          user_id: localStorage.getItem("userData"),
          sessionId: this.config.sessionId,
          billing: this.billingData,
          shippment: this.shippment,
          CallBackUrl: "/finalize",
        })
        .then((response) => {
          window.location.replace(response.data.data.invoiceURL);
          // invoiceId
        })
        .catch(() => {});
    },
    initiatePayement() {
      axios
        .get(process.env.VUE_APP_BASE_URL + "/api/initialPayement")
        .then(({ data }) => {
          this.config.countryCode = data.data.CountryCode;
          this.config.sessionId = data.data.SessionId;
          myFatoorah.init(this.config);
        })
        .finally(() => {
          this.show = false;
        })
        .catch(() => {});
    },
    getCounties() {
      axios
        .get(process.env.VUE_APP_BASE_URL + "/api/shippements")
        .then((response) => {
          (this.countriesData = response.data.data),
            this.updateEventsSelect(this.countriesData);
        })
        .catch(() => {});
    },

    changeCountry(country) {
      this.countriesData.forEach((item) => {
        if (item.id == country) {
          console.log(item.free);
          if (item.free === "true") {
            this.shippment = 0;
          } else {
            this.shippment = item.price;
          }
          //cities
          this.cities = [];

          axios
            .post(process.env.VUE_APP_BASE_URL + "/api/shippement/countries", {
              cities: item.cities,
            })
            .then((response) => {
              response.data.data.forEach((elem) => {
                this.cities.push({
                  value: elem.id,
                  text: elem.name.en,
                });
              });
            })
            .catch(() => {});
        }
      });
    },

    updateEventsSelect(data) {
      this.countries.push({ value: null, text: "Please select country" });

      data.forEach((elem) => {
        this.cities.push({ value: null, text: "Please select city" });
        this.countries.push({
          value: elem.id.toString(),
          text: elem.name,
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.toggle_info {
  padding: 20px;
  background-color: #f7f8f9;
  border-radius: 10px;
  border: 1px solid #e2e9e1;
}
.toggle_info a {
  color: var(--primary);
}
.mb-50 {
  margin-bottom: 50px !important;
}

.mt-50 {
  margin-top: 50px !important;
}
.divider {
  position: relative;
  overflow: hidden;
  height: 4px;
  z-index: 9;
}
.login_form .panel-body,
.register_form .panel-body {
  border: 1px solid #e2e9e1;
  padding: 30px;
  margin-top: 30px;
  border-radius: 10px;
}

.login_footer a {
  color: #114956;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.font-sm {
  font-size: 14px;
}
label {
  margin-bottom: 5px;
}
.payment_option .custome-radio .form-check-label {
  color: #292b2c;
  font-weight: 600;
}
.custome-radio .form-check-label,
.custome-checkbox .form-check-label {
  position: relative;
  cursor: pointer;
}

.order_table h5 a {
  color: #114956;

  &:hover {
    color: #f4624f;
  }
}

input:valid {
  border-color: var(--primary);
}

input:invalid {
  border-color: red;
}

textarea:valid {
  border-color: var(--primary);
}

textarea:invalid {
  border-color: red;
}
</style>
