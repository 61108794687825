<template>
  <div>
    <PageBanner />
    <div class="top-page-text">
      <h2 class="title-top width-custom-title font-65">Yasmin Interior</h2>
    </div>

    <section
      class="section-div width-96"
      style="margin-top: 130px; margin-bottom: 80px; border-radius: 15px"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12" style="margin-top: -60px">
            <img
              src="../../public/assets/img/test_image.png"
              alt="Furniture"
              class="img-fluid img-size"
            />
          </div>
          <div class="col-md-12" style="margin-top: 50px">
            <p class="text-about">
              Furnish is a furniture one-stop destination<br />
              offering a wide range of options for purchasing,<br />
              designing, manufacturing , and acquiring furniture<br />
              and accessories.Additonnaly, you can also<br />
              connect with architects , consultants , or handymen<br />
              to serve you in various needs and spaces such as <br />your
              home,office, hotel,restaurant, and more !<br />
            </p>
          </div>
        </div>
      </div>
    </section>

    <section
      class="grid-products"
      style="margin-top: -35px; margin-bottom: -45px; width: 100.5%"
    >
      <div>
        <div class="row products-gird-custom">
          <div class="row" style="margin-top: -30px">
            <div
              class="col-md-12"
              style="margin-left: 17px; margin-bottom: 21px"
            >
              <h5 class="portfolio-title">Portfolio</h5>
            </div>
            <div class="col-md-3 mb-4">
              <LocalizedLink :to="'portfolio'">
                <div>
                  <img
                    src="../../public/assets/img/test_image.png"
                    alt="Furniture"
                    class="img-fluid img-custom-size"
                    style="border-radius: 15px"
                  />
                  <div class="product-info d-block" style="line-height: 1rem">
                    <h4 class="text-dark font-16">Project name</h4>
                  </div>
                </div>
              </LocalizedLink>
            </div>
            <div class="col-md-3 mb-4">
              <div>
                <img
                  src="../../public/assets/img/test_image.png"
                  alt="Furniture"
                  class="img-fluid img-custom-size left-20"
                  style="border-radius: 15px"
                />
                <div
                  class="product-info d-block left-20"
                  style="line-height: 1rem"
                >
                  <h4 class="text-dark font-16">Project name</h4>
                </div>
              </div>
            </div>

            <div class="col-md-3 mb-4">
              <div>
                <img
                  src="../../public/assets/img/test_image.png"
                  alt="Furniture"
                  class="img-fluid img-custom-size left-9"
                  style="border-radius: 15px"
                />
                <div
                  class="product-info d-block left-9"
                  style="line-height: 1rem"
                >
                  <h4 class="text-dark font-16">Project name</h4>
                </div>
              </div>
            </div>
            <div class="col-md-3 mb-4">
              <div>
                <img
                  src="../../public/assets/img/test_image.png"
                  alt="Furniture"
                  class="img-fluid img-custom-size left-28"
                  style="border-radius: 15px"
                />
                <div
                  class="product-info d-block left-28"
                  style="line-height: 1rem"
                >
                  <h4 class="text-dark font-16">Project name</h4>
                </div>
              </div>
            </div>

            <div class="col-md-3 mb-4">
              <div>
                <img
                  src="../../public/assets/img/test_image.png"
                  alt="Furniture"
                  class="img-fluid img-custom-size"
                  style="border-radius: 15px"
                />
                <div class="product-info d-block" style="line-height: 1rem">
                  <h4 class="text-dark font-16">Project name</h4>
                </div>
              </div>
            </div>
            <div class="col-md-3 mb-4">
              <div>
                <img
                  src="../../public/assets/img/test_image.png"
                  alt="Furniture"
                  class="img-fluid img-custom-size left-20"
                  style="border-radius: 15px"
                />
                <div
                  class="product-info d-block left-20"
                  style="line-height: 1rem"
                >
                  <h4 class="text-dark font-16">Project name</h4>
                </div>
              </div>
            </div>

            <div class="col-md-3 mb-4">
              <div>
                <img
                  src="../../public/assets/img/test_image.png"
                  alt="Furniture"
                  class="img-fluid img-custom-size left-9"
                  style="border-radius: 15px"
                />
                <div
                  class="product-info d-block left-9"
                  style="line-height: 1rem"
                >
                  <h4 class="text-dark font-16">Project name</h4>
                </div>
              </div>
            </div>
            <div class="col-md-3 mb-4">
              <div>
                <img
                  src="../../public/assets/img/test_image.png"
                  alt="Furniture"
                  class="img-fluid img-custom-size left-28"
                  style="border-radius: 15px"
                />
                <div
                  class="product-info d-block left-28"
                  style="line-height: 1rem"
                >
                  <h4 class="text-dark font-16">Project name</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="pagination-custom" style="margin-bottom: 100px; padding: 38px">
      <div class="pagination-div">
        <button class="btn btn-dark btn-custom">Work with this designer</button>
      </div>
    </div>

    <section
      class="section-div width-96"
      style="
        margin-top: -40px;
        margin-bottom: 80px;
        border-radius: 15px;
        padding-bottom: 50px;
      "
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <h5 class="title-abouts text-uppercase">MORE LIKE THIS</h5>
          </div>
          <div class="col-md-12" style="margin-top: -20px">
            <div class="row margin-top-45">
              <div class="col-md-3 row">
                <div class="col-md-5">
                  <img
                    src="../../public/assets/img/test_image.png"
                    alt="Furniture"
                    class="option-image"
                    style="border-radius: 0"
                  />
                </div>
                <div class="col-md-7 product-info d-block div-info">
                  <h4 class="text-dark font-16">
                    7th Dimension <br />البعد السابع
                  </h4>
                  <div class="price font-14">Riadh</div>
                </div>
              </div>
              <div class="col-md-3 row left-32">
                <div class="col-md-5">
                  <img
                    src="../../public/assets/img/test_image.png"
                    alt="Furniture"
                    class="option-image"
                    style="border-radius: 0"
                  />
                </div>
                <div class="col-md-7 product-info d-block div-info">
                  <h4 class="text-dark font-16">
                    7th Dimension <br />البعد السابع
                  </h4>
                  <div class="price font-14">Riadh</div>
                </div>
              </div>
              <div class="col-md-3 row left-5">
                <div class="col-md-5">
                  <img
                    src="../../public/assets/img/test_image.png"
                    alt="Furniture"
                    class="option-image"
                    style="border-radius: 0"
                  />
                </div>
                <div class="col-md-7 product-info d-block div-info">
                  <h4 class="text-dark font-16">
                    7th Dimension <br />البعد السابع
                  </h4>
                  <div class="price font-14">Riadh</div>
                </div>
              </div>
              <div class="col-md-3 row left-14">
                <div class="col-md-5">
                  <img
                    src="../../public/assets/img/test_image.png"
                    alt="Furniture"
                    class="option-image"
                    style="border-radius: 0"
                  />
                </div>
                <div class="col-md-7 product-info d-block div-info">
                  <h4 class="text-dark font-16">
                    7th Dimension <br />البعد السابع
                  </h4>
                  <div class="price font-14">Riadh</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import PageBanner from "../components/PageBanner";
export default {
  components: { PageBanner },
};
</script>
<style scoped>
.font-65 {
  font-size: 65px !important;
}
.section-div {
  background-color: white;
  padding: 20px 0 20px;
}

.text-about {
  font-size: 30px;
  line-height: 2.2rem;
}
.title-abouts {
  font-size: 12px;
}
.img-size {
  width: 110px;
  height: 110px;
}
.portfolio-title {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
}
.top-15 {
  margin-top: -15px;
}
.btn-custom {
  background: black;
  padding: 9px 39px 13px 39px;
  text-transform: uppercase;
  border-radius: 10px;
}
</style>
