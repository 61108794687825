<template>
  <!-- <div class="shop">
    <PageBanner />

    <section class="grid-products">
      <div class="container">
        <div class="search_bars">
          <div class="row align-items-center mb-5">
            <div class="col-md-3">
              <h6 class="text-brown ">
                {{ $t("Showing") }} {{ page }}-{{ recordsPerPage }} {{
                  $t("of")
                }}
                {{ totalRecords }}
                {{ $t("Results") }}
              </h6>
            </div>
            <div
              class="col-md-9 d-flex align-items-center justify-content-between"
            >
              <input
                class="form-control me-2"
                type="search"
                :placeholder="$t('Search by Product')"
                aria-label="Search"
                v-model="searchQuery"
              />
            </div>
          </div>
        </div>
        <div class="row" v-if="productsData">
          <div
            class="col-md-3 mb-4"
            v-for="product in filteredItems"
            :key="product.id"
          >
            <LocalizedLink
              :to="'product/' + product.slug"
              class="text-capitalize"
            >
              <img
                :src="product.couverture"
                alt="Furniture"
                class="img-fluid img-custom-size"
              />
              <div class="product-info d-block">
                <h4 class="product-name">
                  <span class="text-dark"> {{ product.name }} </span>
                </h4>
                <div class="price">{{ product.price }} {{ $t("SAR") }}</div>
              </div>
            </LocalizedLink>
          </div>

          <nav aria-label="Page navigation example" class="mt-4">
            <Pagination
              v-if="productsData"
              :total-pages="Math.floor(paginats / recordsPerPage)"
              :per-page="recordsPerPage"
              :current-page="page"
              @pagechanged="onPageChange"
            />
          </nav>
        </div>
      </div>
    </section>

    <GreenBg />
  </div> -->
  <div>
    <!-- <PageBanner /> -->
    <!-- <div class="row" style="margin-top: 15px; margin-bottom: 25px">
      <div class="col-md-2">
        <h2 class="title-categories right-title-products text-uppercase">
          {{ $t("Categories") }}
        </h2>
        <div
          class="form-check custom-margin-left row"
          v-for="categorie in categoriesData"
          :key="categorie.id"
        >
          <div class="col-md-4">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="categorie.selected"
              :id="'flexCheckChecked_' + categorie.id"
              @change="handleCheckboxChangeCategorie(categorie)"
            />
          </div>
          <div class="col-md-8">
            <label
              class="form-check-label label-filter label-right"
              style="white-space: nowrap"
              :for="'flexCheckChecked_' + categorie.id"
            >
              {{ categorie.name }}
            </label>
          </div>
        </div>
        <br />
        <hr class="custom-hr" />
        <h2 class="title-categories right-title-products text-uppercase">
          {{ $t("Mades") }}
        </h2>
        <div
          class="form-check custom-margin-left row"
          v-for="made in madesData"
          :key="made.id"
        >
          <div class="col-md-3">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="made.selected"
              :id="'flexCheckCheckedMade_' + made.id"
              @change="handleCheckboxChangeMade(made)"
            />
          </div>
          <div class="col-md-9">
            <label
              class="form-check-label label-filter label-right"
              style="white-space: nowrap"
              :for="'flexCheckCheckedMade_' + made.id"
            >
              {{ made.name }}
            </label>
          </div>
        </div>
      </div>
      <div class="col-md-10">
        <div class="row" v-if="productsData">
          <div
            class="col-md-3 mb-4"
            v-for="product in filteredItems"
            :key="product.id"
          >
            <LocalizedLink
              :to="'product/' + product.slug"
              class="text-capitalize"
            >
              <img
                :src="product.couverture"
                alt="Furniture"
                class="img-fluid img-custom-size"
                style="border-radius: 4%"
              />
              <div class="product-info d-block">
                <h4 class="product-name">
                  <span class="text-dark"> {{ product.name }} </span>
                </h4>
                <div class="price">{{ product.price }} {{ $t("SAR") }}</div>
              </div>
            </LocalizedLink>
          </div>

        </div>
      </div>
    </div> -->

    <section class="grid-products" style="margin-top: -67px">
      <div>
        <div class="row products-gird-custom">
          <div class="search_bars">
            <div class="row align-items-center mb-3">
              <div class="col-md-12" style="margin-bottom: 10px;">
                <h5 class="text-dark text-uppercase" style="font-size: 17px">
                  {{ $t("Products") }}
                </h5>
              </div>
              <br />
              <div
                class="col-md-12 d-flex align-items-center justify-content-center"
                style="margin-top: 20px"
              >
                <!-- <form class="me-3 byProduct">
                  <input
                    class="form-control me-2 size-input"
                    type="text"
                    v-model="product"
                    :placeholder="$t('Categories')"
                    aria-label="Search"
                    @keyup="getSearchedProducts()"
                  />
                </form>

                <form class="byBrand" role="search">
                  <input
                    class="form-control me-2 custom-input size-input"
                    type="text"
                    :placeholder="$t('Mades')"
                    aria-label="Search"
                    v-model="made"
                    @keyup="getSearchedProductsByMade()"
                  />
                </form> -->
                <form class="me-3 byProduct">
                  <div class="custom-dropdown">
                    <img
                      src="../../../public/assets/img/icons/new_caret_down.png"
                      width="28"
                      alt="Caret Down"
                      class="category-down"
                    />
                    <select
                      class="form-control me-2 size-input select-categorie"
                      v-model="selectcategory"
                    >
                      <option value="" selected>CATEGORY</option>
                      <option
                        v-for="categorie in categoriesData"
                        :key="categorie.id"
                        :value="categorie.id"
                      >
                        {{ categorie.name }}
                      </option>
                    </select>
                  </div>
                </form>

                <form class="me-3 byProduct">
                  <div class="custom-dropdown">
                    <img
                      src="../../../public/assets/img/icons/new_caret_down.png"
                      width="28"
                      alt="Caret Down"
                      class="made-down"
                    />
                    <select
                      class="form-control me-2 size-input select-mades"
                      v-model="selectMade"
                      style="width: 102.6%"
                    >
                      <option value="" selected>Made in</option>
                      <option
                        :value="made.id"
                        v-for="made in madesData"
                        :key="made.id"
                      >
                        {{ made.name }}
                      </option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="row" v-if="productsData">
            <div
              class="col-md-3 mb-4"
              v-for="product in filteredItems"
              :key="product.id"
            >
              <LocalizedLink
                :to="'product/' + product.slug"
                class="text-capitalize"
              >
                <img
                  :src="product.couverture"
                  alt="Furniture"
                  class="img-fluid img-custom-size"
                  style="border-radius: 4%"
                />
                <div class="product-info d-block">
                  <h4 class="text-dark">
                    {{ product.name }}
                  </h4>
                  <div class="price">{{ product.price }} {{ $t("SAR") }}</div>
                </div>
              </LocalizedLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ProductsGrid from "@/components/HomeComponents/ProductsGrid";
import categoriesStoreModule from "../categories/categoriesStoreModule";
import madesStoreModule from "../mades/madesStoreModule";

import PageBanner from "../../components/PageBanner";
import GreenBg from "@/components/BeforeFooter/GreenBg";
import LocalizedLink from "@/components/LocalizedLink";
import productsStoreModule from "./productsStoreModule";
import Pagination from "@/components/pagination/Pagination";
import store from "@/store";
import { mapState } from "vuex";
import { onMounted, ref, onUnmounted } from "vue";

import router from "@/router";
export default {
  components: { PageBanner, GreenBg, LocalizedLink, Pagination, ProductsGrid },
  data() {
    return {
      searchQuery: "",
      selectMade: "",
      selectcategory: "",
    };
  },

  computed: {
    ...mapState("app-product", ["paginats"]),

    filteredItems() {
      let newData = this.productsData.filter((element) => {
        return this.selectcategory
          ? element.category.id == this.selectcategory
          : element;
      });
      return newData.filter((element) => {
        return this.selectMade ? element.made.id == this.selectMade : element;
      });
    },
  },
  methods: {
    handleCheckboxChangeCategorie(category) {
      if (!category.selected) {
        this.categorieSelected = this.categorieSelected.filter((element) => {
          return element !== category.id;
        });
      } else if (
        !this.categorieSelected.includes(category.id) &&
        category.selected
      ) {
        this.categorieSelected.push(category.id);
      }
    },
    handleCheckboxChangeMade(made) {
      if (!made.selected) {
        this.madeSelected = this.madeSelected.filter((element) => {
          return element !== made.id;
        });
      } else if (!this.madeSelected.includes(made.id) && made.selected) {
        this.madeSelected.push(made.id);
      }
      console.log("selcmade", this.madeSelected);
    },
  },
  setup() {
    const productsData = ref(null);
    const EVENTS_APP_STORE_MODULE_NAME = "app-product";
    const page = ref(1);

    const totalRecords = ref(0);
    const recordsPerPage = ref(5);

    // Register module
    if (!store.hasModule(EVENTS_APP_STORE_MODULE_NAME))
      store.registerModule(EVENTS_APP_STORE_MODULE_NAME, productsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENTS_APP_STORE_MODULE_NAME))
        store.unregisterModule(EVENTS_APP_STORE_MODULE_NAME);
    });
    const LoadEvents = (pagecount, recordsPerPage) => {
      console.log(pagecount, recordsPerPage, "xxxx");
      store
        .dispatch("app-product/fetchProducts", {
          pagecont: pagecount,
          queryParams: recordsPerPage,
        })
        .then((response) => {
          totalRecords.value = response.data.meta.total;
          productsData.value = response.data.data;
          console.log(productsData.value, "rrrrrrrrr");
        })
        .catch(() => {});
    };

    const onPageChange = (p) => {
      page.value = p;
      LoadEvents(page.value, recordsPerPage.value);
    };
    onMounted(() => {
      LoadEvents(page.value, recordsPerPage.value);
    });

    //categories

    const categoriesData = ref(null);
    const HOME_APP_STORE_MODULE_NAME = "app-category";
    // const router = context.root.$router;

    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) {
      store.registerModule(HOME_APP_STORE_MODULE_NAME, categoriesStoreModule);
    } else {
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
          store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
      });
    }
    const categorieSelected = ref([]);

    store
      .dispatch("app-category/fetchCategories", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        categoriesData.value = response.data.data;
        categoriesData.value.forEach((category) => {
          console.log("test_cat", category);
          category.selected = true;
          categorieSelected.value.push(category.id);
        });
      })
      .catch(() => {});

    //Mades
    const madesData = ref(null);
    const MADE_APP_STORE_MODULE_NAME = "app-made";
    const madeSelected = ref([]);
    // const router = context.root.$router;

    // Register module
    if (!store.hasModule(MADE_APP_STORE_MODULE_NAME)) {
      store.registerModule(MADE_APP_STORE_MODULE_NAME, madesStoreModule);
    } else {
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(MADE_APP_STORE_MODULE_NAME))
          store.unregisterModule(MADE_APP_STORE_MODULE_NAME);
      });
    }

    store
      .dispatch("app-made/fetchMades", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        madesData.value = response.data.data;
        madesData.value.forEach((made) => {
          made.selected = true;
          madeSelected.value.push(made.id);
        });
      })
      .catch(() => {});

    return {
      madesData,
      madeSelected,
      categoriesData,
      categorieSelected,
      productsData,
      totalRecords,
      recordsPerPage,
      page,
      onPageChange,
      LoadEvents,
    };
  },
};
</script>
<style>
.title-categories {
  margin-left: 20px;
}
/* Unchecked state */
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  background-color: #fff;
  outline: none;
}

/* Checked state */
input[type="checkbox"]:checked {
  background-color: #f4624f;
  border: none;
}
input[type="checkbox"]:hover {
  background-color: #f4624f;
}

/* 
select */
.custom-dropdown {
  position: relative;
  /* display: inline-block; */
}

.custom-dropdown select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 0 0 4px 10px;
}

.custom-dropdown img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  width: 35px;
  height: 50px;
}
.category-down {
  right: 7px;
}
.made-down {
  right: -8px;
}
</style>
