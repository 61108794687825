var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"green-bg-padding bg-green new-custom-section section-padding top-40"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_vm._m(0),_c('div',{staticClass:"card-body-custom"},[_c('h5',{staticClass:"card-title color-orange"},[_vm._v(" "+_vm._s(_vm.$t("Premium Partners"))+" ")])]),_vm._m(1)]),_c('div',{staticClass:"col-md-3"},[_vm._m(2),_c('div',{staticClass:"card-body-custom",staticStyle:{"padding-top":"75px"}},[_c('h5',{staticClass:"card-title color-orange"},[_vm._v(" "+_vm._s(_vm.$t("Shipping & Delivery"))+" ")])]),_vm._m(3)]),_c('div',{staticClass:"col-md-3"},[_vm._m(4),_c('div',{staticClass:"card-body-custom"},[_c('h5',{staticClass:"card-title color-orange"},[_vm._v(" "+_vm._s(_vm.$t("Shipping & Delivery"))+" ")])]),_vm._m(5)]),_c('div',{staticClass:"col-md-3"},[_vm._m(6),_c('div',{staticClass:"card-body-custom"},[_c('h5',{staticClass:"card-title color-orange"},[_vm._v(" "+_vm._s(_vm.$t("Premium Membership"))+" ")])]),_vm._m(7)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"green-bg-col-img"},[_c('img',{staticClass:"green-bg-img-1",attrs:{"src":require("../../../public/assets/img/icons/user.png"),"alt":"..."}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-text-div"},[_c('p',{staticClass:"card-text text-center"},[_vm._v(" We only stock the best. Shop thousands of brands vetted for quality and reliability. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"green-bg-col-img"},[_c('img',{staticClass:"green-bg-img-2",attrs:{"src":require("../../../public/assets/img/icons/truck.png"),"alt":"..."}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-text-div"},[_c('p',{staticClass:"card-text text-center"},[_vm._v(" We only stock the best. Shop thousands of brands vetted for quality and reliability. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"green-bg-col-img"},[_c('img',{staticClass:"green-bg-img-3",attrs:{"src":require("../../../public/assets/img/icons/support.png"),"alt":"..."}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-text-div"},[_c('p',{staticClass:"card-text text-center"},[_vm._v(" We only stock the best. Shop thousands of brands vetted for quality and reliability. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"green-bg-col-img"},[_c('img',{staticClass:"green-bg-img-3",attrs:{"src":require("../../../public/assets/img/icons/home.png"),"alt":"..."}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-text-div"},[_c('p',{staticClass:"card-text text-center"},[_vm._v(" We only stock the best. Shop thousands of brands vetted for quality and reliability. ")])])
}]

export { render, staticRenderFns }