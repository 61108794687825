<template>
  <div class="home">
    <Header />
    <!-- <MainSlider /> -->
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>

    <MainSlider />
    <Motif />
    <!-- <PopularCategories :categories-data="categoriesData" :showTitle="true" /> -->

    <GreenBg />
    <!-- <ProductsGrid :products-data.sync="productsData" /> -->
    <Banner />
    <Contact />
  </div>
</template>

<script>
import Header from "@/components/Layout/Header";
import LocalizedLink from "@/components/LocalizedLink";

import MainSlider from "../../components/HomeComponents/MainSlider.vue";
import PopularCategories from "../../components/HomeComponents/PopularCategories.vue";
import Motif from "../../components/HomeComponents/Motif.vue";
import GreenBg from "@/components/BeforeFooter/GreenBg";
import ProductsGrid from "@/components/HomeComponents/ProductsGrid";
import { ref, onUnmounted, onMounted } from "vue";
import store from "@/store";
import homeStoreModule from "./homeStoreModule";

import Banner from "../../components/HomeComponents/Banner.vue";
import Contact from "@/views/newsletter/Contact";

// @ is an alias to /src

export default {
  name: "Home",
  components: {
    MainSlider,
    PopularCategories,
    Motif,
    GreenBg,
    ProductsGrid,
    Banner,
    Contact,
  },
  setup() {
    const categoriesData = ref(null);
    const productsData = ref(null);
    const HOME_APP_STORE_MODULE_NAME = "app-home";
    const page = ref(1);

    const totalRecords = ref(0);
    const recordsPerPage = ref(50);

    // const router = context.root.$router;

    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) {
      store.registerModule(HOME_APP_STORE_MODULE_NAME, homeStoreModule);
    } else {
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
          store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
      });
    }

    store
      .dispatch("app-home/fetchCategories")
      .then((response) => {
        categoriesData.value = response.data.data;
      })
      .catch(() => {});

    const LoadEvents = (pagecount, recordsPerPage) => {
      console.log(pagecount, recordsPerPage, "xxxx");
      store
        .dispatch("app-home/fetchProducts", {
          pagecont: pagecount,
          queryParams: recordsPerPage,
        })
        .then((response) => {
          totalRecords.value = response.data.meta.total;
          productsData.value = response.data.data;
          console.log(productsData.value, "rrrrrrrrr");
        })
        .catch(() => {});
    };

    const onPageChange = (p) => {
      page.value = p;
      LoadEvents(page.value, recordsPerPage.value);
    };
    onMounted(() => {
      LoadEvents(page.value, recordsPerPage.value);
    });

    return {
      categoriesData,
      productsData,
      page,
      totalRecords,
      recordsPerPage,
      onPageChange,
    };
  },
};
</script>
