<template>
  <div class="shopping_cart">
    <PageBanner />
    <section class="py-5 bg-white">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 m-auto">
            <div class="row">
              <div class="col-md-4">
                <div class="dashboard-menu">
                  <ul class="nav flex-column" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="dashboard-tab"
                        data-bs-toggle="tab"
                        href="#dashboard"
                        role="tab"
                        aria-controls="dashboard"
                        aria-selected="false"
                        ><i class="fa-solid fa-sliders">&nbsp;&nbsp;</i
                        >{{ $t("Dashboard") }}</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="orders-tab"
                        data-bs-toggle="tab"
                        href="#orders"
                        role="tab"
                        aria-controls="orders"
                        aria-selected="false"
                        ><i class="fa-solid fa-cart-shopping">&nbsp;&nbsp;</i
                        >{{ $t("Orders") }}</a
                      >
                    </li>
                    <!-- <li class="nav-item">
                      <a
                        class="nav-link"
                        id="track-orders-tab"
                        data-bs-toggle="tab"
                        href="#track-orders"
                        role="tab"
                        aria-controls="track-orders"
                        aria-selected="false"
                        ><i class="fa-solid fa-cart-arrow-down">&nbsp;&nbsp;</i
                        >{{ $t("Track Your Order") }}</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="address-tab"
                        data-bs-toggle="tab"
                        href="#address"
                        role="tab"
                        aria-controls="address"
                        aria-selected="true"
                        ><i class="fa-solid fa-bookmark">&nbsp;&nbsp;</i
                        >{{ $t("My Address") }}</a
                      >
                    </li> -->
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="account-detail-tab"
                        data-bs-toggle="tab"
                        href="#account-detail"
                        role="tab"
                        aria-controls="account-detail"
                        aria-selected="true"
                        ><i class="fa-solid fa-user">&nbsp;&nbsp;</i
                        >{{ $t("Account details") }}</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        href="#"
                        class="nav-link"
                        aria-controls="account-detail"
                        aria-selected="true"
                        @click="logout()"
                      >
                        <i class="fa-solid fa-right-from-bracket"
                          >&nbsp;&nbsp;</i
                        >{{ $t("Logout") }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-8">
                <div class="tab-content dashboard-content">
                  <div
                    class="tab-pane fade active show"
                    id="dashboard"
                    role="tabpanel"
                    aria-labelledby="dashboard-tab"
                  >
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">{{ $t("Hello") }} {{ user.name }}</h5>
                      </div>
                      <div class="card-body">
                        <p>
                          {{
                            $t(
                              "From your account dashboard. you can easily check & view your"
                            )
                          }}
                          <a href="#">{{ $t("recent orders") }}</a
                          >, {{ $t("manage your") }}

                          {{ $t("and") }}
                          <a href="#">{{
                            $t("edit your password and account details.")
                          }}</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="orders"
                    role="tabpanel"
                    aria-labelledby="orders-tab"
                  >
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">{{ $t("Your Orders") }}</h5>
                      </div>
                      <div class="card-body">
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>{{ $t("Order") }}</th>
                                <th>{{ $t("Date") }}</th>
                                <th>{{ $t("Status") }}</th>
                                <th>{{ $t("Total") }}</th>
                                <!-- <th>{{ $t("Actions") }}</th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(order, index) in data" :key="index">
                                <td>#{{ order.reference }}</td>
                                <td>{{ formatDate(order.created_at) }}</td>
                                <td>
                                  <span  :class="statusClass(order.statut_order.name)">{{
                                    order.statut_order.name
                                  }}</span>
                                </td>
                                <td>{{ $t("SAR") }} {{ order.price }}</td>
                                <!-- <td>
                                  <a href="#" class="btn-small d-block">{{
                                    $t("View")
                                  }}</a>
                                </td> -->
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="track-orders"
                    role="tabpanel"
                    aria-labelledby="track-orders-tab"
                  >
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">{{ $t("Orders tracking") }}</h5>
                      </div>
                      <div class="card-body contact-from-area">
                        <p>
                          {{
                            $t(
                              'To track your order please enter your OrderID in the box below and press "Track" button. This was given to you on your receipt and in the confirmation email you should have received.'
                            )
                          }}
                        </p>
                        <div class="row">
                          <div class="col-lg-8">
                            <form
                              class="contact-form-style mt-30 mb-50"
                              action="#"
                              method="post"
                            >
                              <div class="input-style mb-20">
                                <label>{{ $t("Order ID") }}</label>
                                <input
                                  name="order-id"
                                  :placeholder="
                                    $t('Found in your order confirmation email')
                                  "
                                  type="text"
                                  class="square"
                                />
                              </div>
                              <div class="input-style mb-20">
                                <label>{{ $t("Billing email") }}</label>
                                <input
                                  name="billing-email"
                                  :placeholder="
                                    $t('Email you used during checkout')
                                  "
                                  type="email"
                                  class="square"
                                />
                              </div>
                              <button
                                class="btn btn-primary submit submit-auto-width"
                                type="submit"
                              >
                                {{ $t("Track") }}
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div
                    class="tab-pane fade"
                    id="address"
                    role="tabpanel"
                    aria-labelledby="address-tab"
                  >
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="card mb-3 mb-lg-0">
                          <div class="card-header">
                            <h5 class="mb-0">{{ $t("Billing Address") }}</h5>
                          </div>
                          <div class="card-body">
                            <address>
                              3522 Interstate<br />
                              75 Business Spur,<br />
                              Sault Ste. <br />Marie, MI 49783
                            </address>
                            <p>New York</p>
                            <a href="#" class="btn btn-primary btn-small">{{
                              $t("Edit")
                            }}</a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="card">
                          <div class="card-header">
                            <h5 class="mb-0">{{ $t("Shipping Address") }}</h5>
                          </div>
                          <div class="card-body">
                            <address>
                              4299 Express Lane<br />
                              Sarasota, <br />FL 34249 USA <br />Phone:
                              1.941.227.4444
                            </address>
                            <p>Sarasota</p>
                            <a href="#" class="btn btn-primary btn-small">{{
                              $t("Edit")
                            }}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div
                    class="tab-pane fade"
                    id="account-detail"
                    role="tabpanel"
                    aria-labelledby="account-detail-tab"
                  >
                    <div class="card">
                      <div class="card-header">
                        <h5>{{ $t("Account details") }}</h5>
                      </div>
                      <div class="card-body">
                        <form @submit.prevent="updateProfil">
                          <div class="row">
                            <div class="form-group col-md-12">
                              <label
                                >{{ $t("Name") }}
                                <span class="required">*</span></label
                              >
                              <input
                                required=""
                                class="form-control square"
                                name="name"
                                v-model="user.name"
                              />
                            </div>

                            <div class="form-group col-md-12">
                              <label
                                >{{ $t("Email") }}
                                <span class="required">*</span></label
                              >
                              <input
                                required=""
                                class="form-control square"
                                name="email"
                                type="email"
                                v-model="user.email"
                              />
                            </div>
                            <!-- <div class="form-group col-md-12">
                              <label
                                >{{ $t("Password") }}
                                <span class="required">*</span></label
                              >
                              <input
                                required=""
                                class="form-control square"
                                name="password"
                                type="password"
                                v-model="user.password"
                              />
                            </div> -->
                            <div class="form-group col-md-12">
                              <label>{{ $t("New Password") }} </label>
                              <input
                                class="form-control square"
                                name="password"
                                type="password"
                                v-model="user.password"
                              />
                            </div>
                            <div class="form-group col-md-12">
                              <label>{{ $t("Confirm Password") }} </label>
                              <input
                                class="form-control square"
                                name="password_confirmation"
                                type="password"
                                v-model="user.password_confirmation"
                              />
                            </div>
                            <div class="col-md-12">
                              <button
                                type="submit"
                                class="btn btn-primary submit"
                                name="submit"
                                value="Submit"
                              >
                                {{ $t("Save") }}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <GreenBg />
  </div>
</template>

<script>
import PageBanner from "../components/PageBanner.vue";
import GreenBg from "../components/BeforeFooter/GreenBg.vue";
import LocalizedLink from "@/components/LocalizedLink";
import axios from "../libs/axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      data: {},
      user: {},
    };
  },
  mounted() {
    this.fetchData();
  },
  // fetchData() {
  //   const user = JSON.parse(localStorage.getItem("userData"));
  //   console.log('user',user.id);
  // },
  methods: {
    async fetchData() {
      try {
        this.user = JSON.parse(localStorage.getItem("userData"));
        this.user.password = "";
        this.user.password_confirmation = "";
        if (!this.user) {
          this.$router.push(`/${this.$route.params.locale}/login`);
        }
        const response = await axios.get(
          process.env.VUE_APP_BASE_URL + `/api/front/orders/${this.user.id}`
        );
        this.data = response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    logout() {
      localStorage.removeItem("userData");
      this.$router.push(`/${this.$route.params.locale}/login`);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { month: "long", day: "numeric", year: "numeric" };
      return date.toLocaleDateString("en-US", options);
    },

    statusClass(invoiceStatus) {
      if (invoiceStatus === "proccessing") {
        return "text-info";
      } else if (invoiceStatus === "shipped") {
        return "text-success";
      } else if (invoiceStatus === "canceled") {
        return "text-danger";
      } else {
        return "text-warning";
      }
    },

    async updateProfil() {
      try {
        // Create a FormData object to send the form data, including the image file
        const formData = new FormData();
        formData.append("id", this.user.id);
        formData.append("name", this.user.name);
        formData.append("email", this.user.email);
        formData.append("password", this.user.password);
        formData.append(
          "password_confirmation",
          this.user.password_confirmation
        );

        // Make a POST request to the API endpoint for updating
        const response = await axios.post(`/front/updateprofil`, formData);
        if (response.data.success) {
          Swal.fire({
            icon: "success",
          });
          localStorage.setItem(
            "userData",
            JSON.stringify(response.data.userData)
          );
          this.fetchData();
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "error",
          text: error.response.data.message,
        });
        console.error("Error:", error.response.data.message);
      }
    },
  },

  components: { PageBanner, GreenBg, LocalizedLink },
};
</script>

<style scoped>
.dashboard-menu {
  border: 1px solid #e2e9e1;
  border-radius: 5px;
  overflow: hidden;
}
.dashboard-menu ul li:not(:last-child) {
  border-bottom: 1px solid #e2e9e1;
}

.dashboard-menu ul li {
  position: relative;
}
.dashboard-menu ul li a {
  font-size: 14px;
  color: #242424;
  padding: 15px 30px;
}
.dashboard-menu ul li a:hover,
.dashboard-menu ul li a:focus {
  color: var(--primary);
}
.dashboard-menu ul li a.active {
  color: #fff;
  background-color: var(--primary);
}
.dashboard-menu ul li a.active i {
  color: #fff;
}
label {
  margin-bottom: 5px;
}

.dashboard-content a {
  color: #114956;
}

.dashboard-content a.btn.btn-primary.btn-small {
  background-color: #114956;
  color: #fff;
}
</style>
