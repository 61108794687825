<template>
  <!-- <div class="categories">
          <PageBanner />
          <section id="categories_list">
            <div class="container px-4 py-5">
              <div class="row">
                <div class="col-12 mb-5">
                  <h2 class="text-uppercase text-center">
                    {{ $t("Discover Our Products") }}
                  </h2>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-md-3 mb-4"
                  v-for="category in categoriesData"
                  :key="category.id"
                >
                  <LocalizedLink :to="'shop/' + category.slug">
                    <div class="card category-miniature position-relative">
                      <img
                        :src="category.photo"
                        class="card-img-top img-custom-size"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title">
                          <span class="text-dark">{{ category.name }}</span>
                        </h5>
                      </div>
                    </div>
                  </LocalizedLink>
                </div>
              </div>
            </div>
          </section>
          <GreenBg />
        </div> -->
  <div class="categories">
    <PageBanner />
    <div class="top-page-text">
      <div class="div-before">
        <span>CUSTOMIZE > VILLA > DESIGNERS > DESIGNER NAME</span>
      </div>
      <div class="row">
        <div class="col-md-6">
          <h2 class="title-top top-62 custom-new-title">Saleh Alahmad</h2>
          <div class="top-33">
            <span class="blue-dark text-one-customize">2 Years experience</span>
          </div>
          <div class="padding-bottom-60 border-bottom-black">
            <div class="price text-one-customize" style="margin-top: 22px">
              23 Projects
            </div>
          </div>
          <div class="top-45">
            <p class="description-custom-new">
              Furnich is a furniture one-stop destination offering a wide range
              of options for purchasing, designing, manufacturing, and acquiring
              furniture and accessories. Additionally, you can also connect with
              architects, consultants, or handymen to serve you in various needs
              and spaces such as your home, office, hotel, restaurant, and more!
            </p>
          </div>
          <div class="row top-60-header">
            <div class="col-md-6">
              <div class="div-category-first">
                <span class="text-category" @click="categoryShow()">
                  <span v-if="!showcategory">&darr;</span>
                  <span v-if="showcategory">&uarr;</span>

                  {{ $t("Portfolio") }}</span
                >
                <div v-if="showcategory" class="top-10">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault1"
                    />
                    <label class="form-check-label" for="flexCheckDefault1">
                      <span class="text-category">Home</span>
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault2"
                    />
                    <label class="form-check-label" for="flexCheckDefault2">
                      <span class="text-category">Related Designers</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="div-category-item">
                <span class="text-category">&darr; {{ $t("Reviews") }}</span>
              </div>
              <div class="div-category-item border-bottom-black">
                <span class="text-category"
                  >&darr; {{ $t("Related Designers") }}</span
                >
              </div>
            </div>
            <div class="col-md-6 col-btn-shop">
              <button type="submit" class="btn-cart btn btn-block">
                {{ $t("Submit") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6 top-70">
          <img
            src="../../public/assets/img/right.png"
            alt="Furniture"
            class="img-fluid img-custom-size img-one-shop"
          />
        </div>

        <div class="swiper col-md-12 padding-4-custom" style="margin-top: 50px">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="slider in data" :key="slider.id">
              <img
                :src="slider.image"
                alt=""
                style="height: 700px; width: 100%; border-radius: 25px"
                class="img-new-size img-fluid slide"
              />
              <!-- <div class="slider-text left-new">
                {{
                  $route.params.locale === "en"
                    ? slider.title_en
                    : slider.title_ar
                }}
            
              </div> -->
            </div>
          </div>
          <div class="swiper-pagination"></div>
          <!-- <div class="swiper-button-next right-50 white-color"></div>
          <div class="swiper-button-prev left-10 white-color"></div> -->
        </div>
        <div
          class="custom-section-new col-md-12"
          style="padding: 10px 50px 0 0"
        >
          <section class="section-shop top-40 padding-bottom-70">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <h5 class="description-title margin-12-26">Reviews</h5>
                </div>
                <div class="col-md-6 top-62">
                  <div class="padding-bottom-25">
                    <span class="description-item margin-12-24">Ahmad Saleh </span>
                  </div>
                  <p class="description-item-second">
                    We are constantly seeking new and creative ways to improve
                    the shopping experience for our customers and to stay ahead
                    of our competition.
                  </p>
                </div>

                <div class="col-md-6 top-62">
                  <div class="padding-bottom-25">
                    <span class="description-item margin-12-24"
                      >Saleh Ahmad 
                    </span>
                  </div>
                  <p class="description-item-second">
                    We are constantly seeking new and creative ways to improve
                    the shopping experience for our customers and to stay ahead
                    of our competition.
                  </p>
                </div>

                <div class="col-md-6 top-62">
                  <div class="padding-bottom-25">
                    <span class="description-item margin-12-24">Julien Alex  </span>
                  </div>
                  <p class="description-item-second">
                    We are constantly seeking new and creative ways to improve
                    the shopping experience for our customers and to stay ahead
                    of our competition.
                  </p>
                </div>

                <div class="col-md-6 top-62">
                  <div class="padding-bottom-25">
                    <span class="description-item margin-12-24">Faisal Alsalem </span>
                  </div>
                  <p class="description-item-second">
                    We are constantly seeking new and creative ways to improve
                    the shopping experience for our customers and to stay ahead
                    of our competition.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div class="col-md-12 top-50">
          <h2 class="related-title">RELATED DESIGNERS</h2>
        </div>
        <div class="col-md-12 row top-30 padding-customize " >
          <div class="col-md-3 mb-4">
            <LocalizedLink :to="'oneshop'">
              <img
                src="../../public/assets/img/page/avatar-4.jpg"
                alt="Furniture"
                class="img-fluid img-custom-size"
                style="border-radius: 25px; margin-left: 5px"
              />
            </LocalizedLink>
            <div class="product-info-one d-block">
              <h4 class="text-dark">test</h4>
              <div class="blue-dark">2 Years experience</div>

              <div class="price">5 Projects</div>
            </div>
          </div>
          <div class="col-md-3 mb-4">
            <img
              src="../../public/assets/img/page/avatar-4.jpg"
              alt="Furniture"
              class="img-fluid img-custom-size"
              style="border-radius: 25px; margin-left: 15px"
            />
            <div class="product-info-one d-block">
              <h4 class="text-dark">test</h4>
              <div class="blue-dark">2 Years experience</div>

              <div class="price">5 Projects</div>
            </div>
          </div>
          <div class="col-md-3 mb-4">
            <img
              src="../../public/assets/img/page/avatar-4.jpg"
              alt="Furniture"
              class="img-fluid img-custom-size"
              style="border-radius: 25px; margin-left: 15px"
            />
            <div class="product-info-one d-block">
              <h4 class="text-dark">test</h4>
              <div class="blue-dark">2 Years experience</div>

              <div class="price">5 Projects</div>
            </div>
          </div>
          <div class="col-md-3 mb-4">
            <img
              src="../../public/assets/img/page/avatar-4.jpg"
              alt="Furniture"
              class="img-fluid img-custom-size"
              style="border-radius: 25px; margin-left: 20px"
            />
            <div class="product-info-one d-block">
              <h4 class="text-dark">test</h4>
              <div class="blue-dark">2 Years experience</div>

              <div class="price">5 Projects</div>
            </div>
          </div>
        </div>

        <div class="col-md-12 row padding-3">
          <div class="col-md-6">
            <button
              type="submit"
              class="btn btn-dark btn-block new-btn-custom-dark"
            >
              {{ $t("Work with this designer") }}
            </button>
          </div>

          <div class="col-md-6">
            <button
              type="submit"
              class="btn btn-dark btn-block new-btn-custom-dark"
            >
              {{ $t("See more designers") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopularCategories from "../components/HomeComponents/PopularCategories.vue";
import Products from "./product/Products.vue";
import PageBanner from "../components/PageBanner.vue";
import GreenBg from "../components/BeforeFooter/GreenBg";
import LocalizedLink from "@/components/LocalizedLink";
import { ref, onUnmounted } from "vue";
import store from "@/store";
import categoriesStoreModule from "../views/categories/categoriesStoreModule";

import router from "@/router";
import axios from "../libs/axios";

export default {
  components: {
    PageBanner,
    GreenBg,
    LocalizedLink,
    PopularCategories,
    Products,
  },
  data() {
    return {
      showcategory: false,
      data: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    categoryShow() {
      this.showcategory = !this.showcategory;
    },
    async fetchData() {
      try {
        const response = await axios.get(
          process.env.VUE_APP_BASE_URL + "/api/front/homebanners"
        );
        this.data = response.data.data;
        console.log("langg", this.$route.params.locale);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },

  setup() {
    const categoriesData = ref(null);
    const HOME_APP_STORE_MODULE_NAME = "app-category";

    // const router = context.root.$router;

    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) {
      store.registerModule(HOME_APP_STORE_MODULE_NAME, categoriesStoreModule);
    } else {
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
          store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
      });
    }

    store
      .dispatch("app-category/fetchCategories", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        categoriesData.value = response.data.data;
      })
      .catch(() => {});

    return {
      categoriesData,
    };
  },
};
</script>

<style scoped>
.categories .card {
  border-radius: 20px;
  border: none;
}
.title-discover {
  display: none !important;
}
.title-categories {
  margin-top: 40px;
  margin-left: 20px;
}
</style>
