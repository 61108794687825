import Vue from "vue";
import Vuex from "vuex";

// Modules

Vue.use(Vuex);
// window.localStorage.clear()
let cart = window.localStorage.getItem("cart");
let cartCount = window.localStorage.getItem("cartCount");
let user = window.localStorage.getItem("userData");

export default new Vuex.Store({
  state: {
    toggleNav: false,
    cart: cart ? JSON.parse(cart) : [],
    cartCount: cartCount ? parseInt(cartCount) : 0,
    user: user ? JSON.parse(user) : null,
    notifications: [],
    cartSession: [],
  },
  modules: {},

  actions: {
    addNotification({ commit }, notification) {
      commit("PUSH_NOTIFICATION", notification);
    },

    removeNotification({ commit }, notification) {
      commit("REMOVE_NOTIFICATION", notification);
    },
  },
  getters: {
    getToggleNav: (state) => state.toggleNav,
  },

  mutations: {
    setToggleNav: function (state) {
      state.toggleNav = !state.toggleNav;
    },
    PUSH_NOTIFICATION(state, notification) {
      state.notifications.push({
        ...notification,
        id: (Math.random().toString(36) + Date.now().toString(36)).substr(2),
      });
    },
    REMOVE_NOTIFICATION(state, notificationToRemove) {
      state.notifications = state.notifications.filter((notification) => {
        return notification.id != notificationToRemove.id;
      });
    },
    setAuthUser(state, user) {
      console.log(user);
      state.user = user;
    },
    addToCartGlobal(state, item) {
      console.log("old_state", state.cart);
      console.log("item", item);

      const existingItemIndex = state.cart.findIndex(
        (element) =>
          element.id === item.id &&
          element.size === item.size &&
          element.color === item.color
      );

      if (existingItemIndex !== -1) {
        // Si l'élément existe déjà, mettez à jour la quantité et le prix total
        const existingItem = state.cart[existingItemIndex];
        if (
          existingItem.maxQuantity >= existingItem.quantity + item.quantity &&
          existingItem.maxQuantity !== ""
        ) {
          existingItem.quantity += item.quantity;
          existingItem.totalPrice = existingItem.quantity * existingItem.price;
        }
      } else {
        // Si l'élément n'existe pas encore, ajoutez-le au panier
        state.cart.push(item);
        Vue.set(item, "totalPrice", item.price * item.quantity);
      }

      this.commit("saveCart");
    },

    removeFromCart(state, item) {
      console.log(item);
      let index = state.cart.indexOf(item);
      console.log(index);

      if (index > -1) {
        let product = state.cart[index];
        state.cartCount -= product.quantity;

        state.cart.splice(index, 1);
      }
      this.commit("saveCart");
    },
    saveCart(state) {
      window.localStorage.setItem("cart", JSON.stringify(state.cart));
      window.localStorage.setItem("cartCount", state.cartCount);
    },
    increaseQuantity(state, itemToIncrease) {
      const index = state.cart.findIndex(
        (item) =>
          item.id === itemToIncrease.id &&
          item.size === itemToIncrease.size &&
          item.color === itemToIncrease.color
      );

      if (index > -1) {
        const product = state.cart[index];
        if (
          product.maxQuantity === "" ||
          product.quantity < product.maxQuantity
        ) {
          product.quantity += 1;
          state.cartCount += 1;
        }
      }
      this.commit("saveCart");
    },

    decreaseQuantity(state, itemToDecrease) {
      const index = state.cart.findIndex(
        (item) =>
          item.id === itemToDecrease.id &&
          item.size === itemToDecrease.size &&
          item.color === itemToDecrease.color
      );

      if (index > -1) {
        const product = state.cart[index];
        if (product.quantity > 1) {
          product.quantity -= 1;
          state.cartCount -= 1;
        }
      }
      this.commit("saveCart");
    },
  },
});
