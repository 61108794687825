<template>

                <div class="col-lg-6">
                  <div
                    class="
                      login_wrap
                      widget-taber-content
                      p-30
                      background-white
                      border-radius-10
                    "
                  >
                    <div class="padding_eight_all bg-white">
                      <div class="heading_s1">
                        <h3 class="mb-30">{{ $t("Create an Account") }}</h3>
                      </div>
                      <p class="mb-50 font-sm">
                        {{
                          $t(
                            "Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our privacy policy"
                          )
                        }}
                      </p>
                      <form @submit.prevent="register(userData)">
                        <div class="form-group">
                          <input
                            v-model="userData.userName"
                            type="text"
                            required=""
                            name="username"
                            v-validate="'required'"
                            :placeholder="$t('Username')"
                          />
                          <span class="">{{ errors.first("username") }}</span>
                        </div>
                        <div class="form-group">
                          <input
                            v-model="userData.email"
                            type="email"
                            required=""
                            name="email"
                            v-validate="'required|email'"
                            :placeholder="$t('Email')"
                          />
                          <span class="">{{ errors.first("email") }}</span>
                        </div>
                        <div class="form-group">
                          <input
                            v-model="userData.password"
                            type="password"
                            name="password"
                            v-validate="'required'"
                            :placeholder="$t('Password')"
                            ref="password"
                          />
                          <span class="">{{ errors.first("password") }}</span>
                        </div>
                        <div class="form-group">
                          <input
                            v-validate="'required|confirmed:password'"
                            type="password"
                            name="confirmed_password"
                            :placeholder="$t('Confirm password')"
                            data-vv-as="password"
                          />
                          <span
                            v-show="errors.has('confirmed_password')"
                            class="help is-danger"
                            >{{ errors.first("confirmed_password") }}</span
                          >
                        </div>
                        <div class="login_footer form-group">
                          <div class="chek-form">
                            <div class="custome-checkbox">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="checkbox"
                                id="exampleCheckbox12"
                                value=""
                              />
                              <label
                                class="form-check-label"
                                for="exampleCheckbox12"
                                ><span>
                                  {{ $t("I agree to terms & Policy.") }}</span
                                ></label
                              >
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <button
                            type="submit"
                            class="btn btn-primary btn-block hover-up"
                          >
                            {{ $t("Submit & Register") }}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

  </template>
  
  <script>
  import { ref, onUnmounted } from "vue";
  import store from "@/store";
  import loginStoreModule from "./loginStoreModule";
  
  export default {

  
    data() {
      return {
        password: "",
        userEmail: "",
      };
    },
  
    methods: {
      register(userData) {
        this.$validator.validateAll().then((success) => {
          if (success) {
            store
              .dispatch("app-login/register", userData)
              .then(() => {
                this.$toasted.show("user created successfully");
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      },
    
    },
    setup() {
      const userData = ref({
        userName: "",
        email: "",
        password: "",
      });
  
      const HOME_APP_STORE_MODULE_NAME = "app-login";
  
      // const router = context.root.$router;
  
      // Register module
      if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) {
        store.registerModule(HOME_APP_STORE_MODULE_NAME, loginStoreModule);
      } else {
        // UnRegister on leave
        onUnmounted(() => {
          if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
            store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
        });
      }
  
      return {
        userData,
      };
    },
  };
  </script>
  
  <style scoped>
  input:valid {
    border-color:  var(--primary);
  }
  
  input:invalid {
    border-color: red;
  }
  
  textarea:valid {
    border-color:  var(--primary);
  }
  
  textarea:invalid {
    border-color: red;
  }
  
  span {
    color: red;
  }
  </style>