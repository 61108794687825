<template>
  <!-- <div class="categories">
      <PageBanner />
      <section id="categories_list">
        <div class="container px-4 py-5">
          <div class="row">
            <div class="col-12 mb-5">
              <h2 class="text-uppercase text-center">
                {{ $t("Discover Our Products") }}
              </h2>
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-3 mb-4"
              v-for="category in categoriesData"
              :key="category.id"
            >
              <LocalizedLink :to="'shop/' + category.slug">
                <div class="card category-miniature position-relative">
                  <img
                    :src="category.photo"
                    class="card-img-top img-custom-size"
                    alt="..."
                  />
                  <div class="card-body">
                    <h5 class="card-title">
                      <span class="text-dark">{{ category.name }}</span>
                    </h5>
                  </div>
                </div>
              </LocalizedLink>
            </div>
          </div>
        </div>
      </section>
      <GreenBg />
    </div> -->
  <div class="categories">
    <PageBanner />
    <div class="top-page-text">
      <div class="div-before">
        <span>SHOP</span>
      </div>
      <h2 class="first-title-page">
        One-stop destination offering a wide range of options for purchasing,
        designing, manufacturing, and acquiring furniture and accessories.
      </h2>

      <div class="div-after">
        <span>SHOP ALL > PRODUCTS > RUGS > SMALL RUGS </span>
      </div>
      <div class="row padding-products">
        <div class="col-md-3">
          <div class="div-category-first">
            <span class="text-category" @click="categoryShow()">
              <span v-if="!showcategory">&darr;</span>
              <span v-if="showcategory">&uarr;</span>

              {{ $t("Category") }}</span
            >
            <div v-if="showcategory" class="top-10">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault1"
                />
                <label class="form-check-label" for="flexCheckDefault1">
                  <span class="text-category">Home</span>
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault2"
                />
                <label class="form-check-label" for="flexCheckDefault2">
                  <span class="text-category">Office</span>
                </label>
              </div>
            </div>
          </div>
          <div class="div-category-item">
            <span class="text-category">&darr; {{ $t("Products") }}</span>
          </div>
          <div class="div-category-item">
            <span class="text-category">&darr; {{ $t("Brands") }}</span>
          </div>
          <div class="div-category-item">
            <span class="text-category">&darr; {{ $t("Colors") }}</span>
          </div>
          <div class="div-category-item border-bottom-black">
            <span class="text-category">&darr; {{ $t("Price Range") }}</span>
          </div>
        </div>
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-4 mb-4 top-67">
              <LocalizedLink :to="'oneshop'" class="product-link">
                <div class="product-wrapper">
                  <img
                    src="../../public/assets/img/page/avatar-4.jpg"
                    alt="Furniture"
                    class="img-fluid img-custom-size product-image"
                    style="border-radius: 23px"
                  />
                  <img
                    src="../../public/assets/img/icons/wish_icons.png"
                    alt="Wish"
                    class="img-fluid img-custom-size wish-icon"
                    style="border-radius: 23px"
                  />
                </div>
              </LocalizedLink>
              <div class="product-info d-block">
                <h4 class="text-dark">Rug from Italy</h4>
                <div class="price">SAR 1,360</div>
              </div>
            </div>
            <div class="col-md-4 mb-4 top-67">
              <LocalizedLink :to="'oneshop'" class="product-link">
                <div class="product-wrapper">
                  <img
                    src="../../public/assets/img/page/avatar-4.jpg"
                    alt="Furniture"
                    class="img-fluid img-custom-size product-image"
                    style="border-radius: 23px"
                  />
                  <img
                    src="../../public/assets/img/icons/wish_icons.png"
                    alt="Wish"
                    class="img-fluid img-custom-size wish-icon"
                    style="border-radius: 23px"
                  />
                </div>
              </LocalizedLink>
              <div class="product-info d-block">
                <h4 class="text-dark">Rug from Italy</h4>
                <div class="price">SAR 1,360</div>
              </div>
            </div>
            <div class="col-md-4 mb-4 top-67">
              <LocalizedLink :to="'oneshop'" class="product-link">
                <div class="product-wrapper">
                  <img
                    src="../../public/assets/img/page/avatar-4.jpg"
                    alt="Furniture"
                    class="img-fluid img-custom-size product-image"
                    style="border-radius: 23px"
                  />
                  <img
                    src="../../public/assets/img/icons/wish_icons.png"
                    alt="Wish"
                    class="img-fluid img-custom-size wish-icon"
                    style="border-radius: 23px"
                  />
                </div>
              </LocalizedLink>
              <div class="product-info d-block">
                <h4 class="text-dark">Rug from Italy</h4>
                <div class="price">SAR 1,360</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopularCategories from "../components/HomeComponents/PopularCategories.vue";
import Products from "./product/Products.vue";
import PageBanner from "../components/PageBanner.vue";
import GreenBg from "../components/BeforeFooter/GreenBg";
import LocalizedLink from "@/components/LocalizedLink";
import { ref, onUnmounted } from "vue";
import store from "@/store";
import categoriesStoreModule from "../views/categories/categoriesStoreModule";

import router from "@/router";
export default {
  components: {
    PageBanner,
    GreenBg,
    LocalizedLink,
    PopularCategories,
    Products,
  },
  data() {
    return {
      showcategory: false,
    };
  },
  methods: {
    categoryShow() {
      this.showcategory = !this.showcategory;
    },
  },

  setup() {
    const categoriesData = ref(null);
    const HOME_APP_STORE_MODULE_NAME = "app-category";

    // const router = context.root.$router;

    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) {
      store.registerModule(HOME_APP_STORE_MODULE_NAME, categoriesStoreModule);
    } else {
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
          store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
      });
    }

    store
      .dispatch("app-category/fetchCategories", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        categoriesData.value = response.data.data;
      })
      .catch(() => {});

    return {
      categoriesData,
    };
  },
};
</script>

<style scoped>
.categories .card {
  border-radius: 20px;
  border: none;
}
.title-discover {
  display: none !important;
}
.title-categories {
  margin-top: 40px;
  margin-left: 20px;
}
</style>
<style scoped>


</style>
