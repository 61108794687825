<template>
  <section style="margin-top: -12px; margin-bottom: -39px">
    <div class="home-categories">
      <h2
        class="text-uppercase text-center pb-5 title-discover"
        v-if="showTitle"
      >
        {{ $t("Discover Our New Modern Products") }}
      </h2>
      <div class="row">
        <!-- Slider main container -->
        <div
          class="swiper pb-5"
          id="homeCategoriesSlider"
          style="width: 97.7%; margin-top: -38px"
        >
          <div class="swiper-wrapper pb-3">
            <div
              class="swiper-slide"
              v-for="category in categoriesData"
              :key="category.id"
            >
              <LocalizedLink :to="'shop/' + category.slug">
                <div class="category-slide">
                  <img
                    :src="category.photo"
                    alt="Furniture"
                    class="img-fluid img-custom-size"
                  />
                  <div class="slider-title">
                    <h3>
                      <localizedLink
                        :to="'shop/' + category.slug"
                        class="text-capitalize text-custom"
                        >{{ category.name }}</localizedLink
                      >
                    </h3>
                  </div>
                </div>
              </LocalizedLink>
            </div>
          </div>
          <!-- <div class="swiper-pagination"></div> -->
          <div class="swiper-button-next text-white"></div>
          <div class="swiper-button-prev text-white"></div>
        </div>
        <!-- /Slider-->
      </div>
    </div>
  </section>
</template>

<script>
import LocalizedLink from "@/components/LocalizedLink";
export default {
  components: {
    LocalizedLink,
  },

  props: {
    categoriesData: {
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style>
.category-slide {
  position: relative;
}

.slider-title {
  position: absolute;
  top: 10px;
  left: 10px;
}
.slider-title a {
  font-size: 25px;
}
.text-custom {
  color: #ecf0f1 !important;
}
</style>
