<template>
  <div>
    <PageBanner />
    <div class="top-page-text">
      <div class="div-before">
        <span>ABOUT</span>
      </div>
      <h2 class="first-title-page">
        One-stop destination offering a wide range of options for purchasing,
        designing, manufacturing, and acquiring furniture and accessories.
      </h2>
      <div class="div-about-us">
        <section class="section-div-about bg-new-white">
          <div class="row">
            <div class="col-md-12">
              <h5 class="title-abouts text-uppercase">About us</h5>
            </div>
            <div class="col-md-12" style="margin-top: 20px">
              <p class="text-page-about width-91">
                Furnich is a furniture one-stop destination offering a wide
                range of options for purchasing, designing, manufacturing, and
                acquiring furniture and accessories. Additionally, you can also
                connect with architects, consultants, or handymen to serve you
                in various needs and spaces such as your home, office, hotel,
                restaurant, and more!
              </p>
            </div>
          </div>
        </section>

        <section
          class="section-div-aim top-45 text-white"
          :style="{
            backgroundImage: 'url(/assets/img/right.png)',
            backgroundPosition: 'center',
          }"
        >
          <div class="row">
            <div class="col-md-12">
              <h5 class="title-abouts text-uppercase">About us</h5>
            </div>
            <div class="col-md-12" style="margin-top: 280px">
              <p class="text-page-about">
                We are aiming to be your number one and only destination during
                these difficult times, which require a trustful source with a
                wide network that injects confidence into the online shopping
                experience by solving for brands, manufacturers, and customers.
              </p>
            </div>
          </div>
        </section>

        <section class="section-div-values top-45 bg-new-blue">
          <div class="row">
            <div class="col-md-12">
              <h5 class="title-abouts text-uppercase">Our Values</h5>
            </div>
            <div class="col-md-12 top-180"></div>
            <div class="col-md-5">
              <span class="title-values">Innovation</span>
            </div>
            <div class="col-md-7">
              <div class="padding-element-first">
                <span class="text-values"
                  >We are constantly seeking new and creative ways to improve
                  the shopping experience for our customers and to stay ahead of
                  our competition.</span
                >
              </div>
            </div>
            <div class="col-md-12">
              <div class="border-top-black"></div>
            </div>

            <div class="col-md-5">
              <div class="padding-title">
                <span class="title-values">Collaboration</span>
              </div>
            </div>
            <div class="col-md-7">
              <div class="padding-element">
                <span class="text-values"
                  >We are constantly seeking new and creative ways to improve
                  the shopping experience for our customers and to stay ahead of
                  our competition.</span
                >
              </div>
            </div>
            <div class="col-md-12">
              <div class="border-top-black"></div>
            </div>

            <div class="col-md-5">
              <div class="padding-title">
                <span class="title-values">Customer Service</span>
              </div>
            </div>
            <div class="col-md-7">
              <div class="padding-element">
                <span class="text-values"
                  >We are constantly seeking new and creative ways to improve
                  the shopping experience for our customers and to stay ahead of
                  our competition.</span
                >
              </div>
            </div>
            <div class="col-md-12">
              <div class="border-top-black"></div>
            </div>

            <div class="col-md-5">
              <div class="padding-title">
                <span class="title-values">Integrity</span>
              </div>
            </div>
            <div class="col-md-7">
              <div class="padding-element">
                <span class="text-values"
                  >We are constantly seeking new and creative ways to improve
                  the shopping experience for our customers and to stay ahead of
                  our competition.</span
                >
              </div>
            </div>
            <div class="col-md-12">
              <div class="border-top-black"></div>
            </div>

            <div class="col-md-5">
              <div class="padding-title">
                <span class="title-values">Quality</span>
              </div>
            </div>
            <div class="col-md-7">
              <div class="padding-element">
                <span class="text-values"
                  >We are constantly seeking new and creative ways to improve
                  the shopping experience for our customers and to stay ahead of
                  our competition.</span
                >
              </div>
            </div>
            <div class="col-md-12">
              <div class="border-top-black"></div>
            </div>

            <div class="col-md-5">
              <div class="padding-title">
                <span class="title-values">Sustainability</span>
              </div>
            </div>
            <div class="col-md-7">
              <div class="padding-element">
                <span class="text-values"
                  >We are constantly seeking new and creative ways to improve
                  the shopping experience for our customers and to stay ahead of
                  our competition.</span
                >
              </div>
            </div>
        
          </div>
        </section>
      </div>

      <!-- <h2 class="title-top width-custom" style="font-size: 80px">
        Build, Design, Manufacture, and Buy
      </h2>
      <section class="section-div width-96" style="margin-top: 60px">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <h5 class="title-abouts text-uppercase">About Us</h5>
            </div>
            <div class="col-md-12" style="margin-top: 15px">
              <p class="text-about">
                Furnish is a furniture one-stop destination<br />
                offering a wide range of options for purchasing,<br />
                designing, manufacturing , and acquiring furniture<br />
                and accessories.Additonnaly, you can also<br />
                connect with architects , consultants , or handymen<br />
                to serve you in various needs and spaces such as <br />your
                home,office, hotel,restaurant, and more!<br />
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="furniture-div">
        <p class="text-65">
          Furniture <br />
          for <span class="bold-text">life</span>
        </p>
      </section>
      <section>
        <div class="container-fluid">
          <div class="row div-images-fournitures">
            <div class="col-md-3">
              <div class="image-container">
                <img
                  src="../../../public/assets/img/right.png"
                  class="img-radius"
                />
                <span>Offices</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="image-container">
                <img
                  src="../../../public/assets/img/right.png"
                  class="img-radius"
                />
                <span>Hotels</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="image-container">
                <img
                  src="../../../public/assets/img/right.png"
                  class="img-radius"
                />
                <span>Restaurants</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="image-container">
                <img
                  src="../../../public/assets/img/right.png"
                  class="img-radius"
                />
                <span>and more!</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="aim-div width-96"
        :style="{
          backgroundImage: 'url(/assets/img/right.png)',
          marginBottom: '15px',
          backgroundPosition: 'center',
        }"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <h5 class="title-abouts text-uppercase">Our Aim</h5>
            </div>
            <div class="col-md-12" style="margin-top: 15px">
              <p class="text-aim">
                We are aiming to be your number one and <br />
                only destination during these difficult times, <br />
                which require a trustful source with a wide<br />
                network that injects confidence into the <br />
                online shopping experience by solving for <br />
                brands, manufactures, and customers.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="find-div width-96">
        <div class="row">
          <div class="col-md-12">
            <div style="margin-bottom: 30px">
              <p class="text-find">
                Find your<br />
                SENSE OF STYLE
              </p>
            </div>
            <p class="text-find-2">
              Furnish offers outstanding value, quality products,<br />
              and exeptional customer experience, which is<br />
              revealed in its brand identity.We are eager to<br />
              help you find your sense of style cause we believe<br />
              everyone is unique and in their own way.
            </p>
          </div>
        </div>
      </section>

      <section class="values-div width-96">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <h5 class="title-abouts text-uppercase">Our values</h5>
            </div>
            <div class="col-md-12" style="margin-top: 25px">
              <div class="row values-element">
                <div class="col-md-6 padding-0">
                  <div class="margin-15">
                    <span class="left-15">Innovation</span>
                  </div>
                </div>
                <div class="col-md-6 padding-0">
                  <div class="margin-8">
                    <p class="font-15">
                      We are constantly seeking new and creative ways to improve
                      the shopping exêrience for our customers and to stay ahead
                      of our competition
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="row values-element border-top-none border-bottom-none"
              >
                <div class="col-md-6 padding-0">
                  <div class="margin-15">
                    <span class="left-15">Collaboration</span>
                  </div>
                </div>
                <div class="col-md-6 padding-0">
                  <div class="margin-8">
                    <p class="font-15">
                      We are constantly seeking new and creative ways to improve
                      the shopping exêrience for our customers and to stay ahead
                      of our competition
                    </p>
                  </div>
                </div>
              </div>

              <div class="row values-element border-bottom-none">
                <div class="col-md-6 padding-0">
                  <div class="margin-15">
                    <span class="left-15">Customer service</span>
                  </div>
                </div>
                <div class="col-md-6 padding-0">
                  <div class="margin-8">
                    <p class="font-15">
                      We are constantly seeking new and creative ways to improve
                      the shopping exêrience for our customers and to stay ahead
                      of our competition
                    </p>
                  </div>
                </div>
              </div>

              <div class="row values-element border-bottom-none">
                <div class="col-md-6 padding-0">
                  <div class="margin-15">
                    <span class="left-15">Integrity</span>
                  </div>
                </div>
                <div class="col-md-6 padding-0">
                  <div class="margin-8">
                    <p class="font-15">
                      We are constantly seeking new and creative ways to improve
                      the shopping exêrience for our customers and to stay ahead
                      of our competition
                    </p>
                  </div>
                </div>
              </div>

              <div class="row values-element border-bottom-none">
                <div class="col-md-6 padding-0">
                  <div class="margin-15">
                    <span class="left-15">Quality</span>
                  </div>
                </div>
                <div class="col-md-6 padding-0">
                  <div class="margin-8">
                    <p class="font-15">
                      We are constantly seeking new and creative ways to improve
                      the shopping exêrience for our customers and to stay ahead
                      of our competition
                    </p>
                  </div>
                </div>
              </div>

              <div class="row values-element">
                <div class="col-md-6 padding-0">
                  <div class="margin-15">
                    <span class="left-15">Sustainability</span>
                  </div>
                </div>
                <div class="col-md-6 padding-0">
                  <div class="margin-8">
                    <p class="font-15">
                      We are constantly seeking new and creative ways to improve
                      the shopping exêrience for our customers and to stay ahead
                      of our competition
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->
    </div>
  </div>
</template>
<script>
import axios from "../../libs/axios";
import PageBanner from "../../components/PageBanner.vue";
export default {
  components: { PageBanner },
  data() {
    return {
      data: {},
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(
          process.env.VUE_APP_BASE_URL + "/api/websitesetting"
        );
        this.data = response.data.data;
        console.log("sttings", this.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
};
</script>
<style scoped>
/* p {
  font-size: 20px;
}
.width-custom {
  width: 60% !important;
}
.section-div {
  background-color: white;
  padding: 20px 0 50px;
}
.aim-div {
  padding: 20px 0 90px;
}
.text-about {
  font-size: 30px;
  line-height: 2.2rem;
}
.title-abouts {
  font-size: 12px;
}
.width-96 {
  width: 97.5%;
}
.text-65 {
  font-size: 75px;
  font-weight: 100;
  line-height: 5rem;
  letter-spacing: 2.3px;
}
.furniture-div {
  padding: 25px 8px 25px;
}
.bold-text {
  font-weight: bold;
}
.div-images-fournitures {
  padding: 5px 20px 40px;
}
.img-radius {
  border-radius: 50%;
  height: 185px;
  width: 190px;
}

.image-container {
  text-align: center;
}

.image-container img {
  display: block;
  margin: 0 auto;
}

.image-container span {
  display: block;
  margin-top: 35px;
}

.aim-div {
  color: white;
}
.text-aim {
  font-size: 25px;
  line-height: 2.1rem;
  font-weight: 300;
}
.find-div {
  padding: 0 0 50px;
}
.text-find {
  font-size: 85px;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 5.1rem;
}
.text-find-2 {
  font-size: 14px;
  line-height: 1.1rem;
  margin-left: 5px;
}

.values-div {
  background: #e4bca2;
  margin: -30px 20px 40px;
  padding: 20px 0 50px;
}
.values-element {
  width: 99.9%;
  border-top: 1px black solid;
  border-bottom: 1px black solid;
  margin-left: 1px;
}
.left-15 {
  font-size: 55px;
  font-weight: 300;
  text-transform: uppercase;
}
.padding-0 {
  padding: 0;
}
.font-15 {
  font-size: 15px;
}
.margin-15 {
  margin-top: -15px;
}
.margin-8 {
  margin-top: 8px;
}
.border-top-none {
  border-top: none !important;
}
.border-bottom-none {
  border-bottom: none !important;
}
.radius-15 {
  border-radius: 15px;
} */
</style>
