<template>
  <div>
    <!-- Menu content -->
    <div class="menu-content" @click="closeMenu">
      <!-- Your menu content goes here -->
      <div class="row">
        <div
          class="col-md-6 image-menu image-div"
          style="background-color: white"
        >
          <div style="margin-left: 20px; margin-top: -50px">
            <span
              class="close-button"
              style="margin-top: -30px"
              v-if="currentLocale != 'English'"
              >X</span
            >

            <img
              src="../../../public/assets/img/furnichlogo.png"
              alt="Furnich"
              class="img-fluid margin-left-new img-menu"
              width="1300px"
              style="height: 800px"
            />
          </div>
        </div>
        <div class="col-md-6 image-menu menu-text">
          <div class="div general-info my-5 div-language">
            <div class="nav-link">
              <ul class="d-flex justify-content-center">
                <li
                  v-for="locale in locales"
                  :key="locale.code"
                  style="margin-top: -45px"
                >
                  <a
                    @click="changeLocale(locale)"
                    id="locale_btn"
                    class="btn btn-outline-primary"
                    :class="locale.name == currentLocale ? 'active' : ''"
                    >{{ locale.name }}</a
                  >&nbsp;&nbsp;
                </li>
                <li v-if="currentLocale === 'English'">
                  <span class="close-button" style="margin-top: -30px">X</span>
                </li>
              </ul>
            </div>
          </div>
          <div style="margin-top: -80px">
            <a :href="url('allshop')">
              <h3
                class="text-beige text-uppercase title-menu-new letter-spacing margin-menu-item"
              >
                {{ $t("Categories") }}
              </h3>
            </a>

            <div class="row">
              <div
                class="col-md-12 margin-menu-item"
                v-for="category in categoriesData"
                :key="category.id"
              >
                <LocalizedLink :to="'shop/' + category.slug">
                  <span class="text-beige">{{ category.name }} </span>
                </LocalizedLink>
              </div>
            </div>
            <a :href="url('allshop')">
              <h3
                class="text-beige text-uppercase title-menu-new letter-spacing margin-menu-item"
              >
                {{ $t("Shop") }}
              </h3>
            </a>
            <a :href="url('allshop')">
              <h3
                class="text-uppercase title-menu-new letter-spacing margin-menu-item"
                style="color: red"
              >
                {{ $t("Mades") }}
              </h3>
            </a>
            <LocalizedLink :to="'aboutus'">
              <h3
                class="text-beige text-uppercase title-menu-new letter-spacing margin-menu-item"
              >
                {{ $t("About") }}
              </h3>
            </LocalizedLink>
            <ul class="unstyled">
              <li>
                <LocalizedLink :to="'aboutus'">
                  <span class="text-beige margin-menu-item"
                    >{{ $t("About Us") }}
                  </span></LocalizedLink
                >
              </li>

              <li>
                <LocalizedLink :to="'returnrefunds'">
                  <span class="text-beige margin-menu-item"
                    >{{ $t("Returns & Refunds") }}
                  </span></LocalizedLink
                >
              </li>
              <li>
                <LocalizedLink :to="'whyshopus'">
                  <span class="text-beige margin-menu-item"
                    >{{ $t("Why shop with Us") }}
                  </span></LocalizedLink
                >
              </li>
              <li>
                <LocalizedLink :to="'privacypolicy'">
                  <span class="text-beige margin-menu-item"
                    >{{ $t("Privacy policy") }}
                  </span></LocalizedLink
                >
              </li>
              <li>
                <LocalizedLink :to="'termsofservice'">
                  <span class="text-beige margin-menu-item"
                    >{{ $t("Terms of service") }}
                  </span></LocalizedLink
                >
              </li>
            </ul>
            <LocalizedLink :to="'contact'">
              <h3
                class="text-beige text-uppercase title-menu-new letter-spacing margin-menu-item"
                style="padding-bottom: 20px"
              >
                {{ $t("Contact Us") }}
              </h3>
            </LocalizedLink>
            <div
              class="socialLinks px-3"
              style="padding-bottom: 20px"
              :style="{
                'margin-left': currentLocale === 'English' ? '-55px' : '',
                'margin-right': currentLocale !== 'English' ? '-55px' : '',
              }"
            >
              <ul class="social_media d-flex align-items-center">
                <li>
                  <a href="#"
                    ><i class="fa-brands fa-facebook-f text-beige"></i
                  ></a>
                </li>
                <li>
                  <a href="#"
                    ><i class="fa-brands fa-x-twitter text-beige"></i
                  ></a>
                </li>
                <li>
                  <a href="#"
                    ><i class="fa-brands fa-instagram text-beige"></i
                  ></a>
                </li>
                <li>
                  <a href="#"
                    ><i class="fa-brands fa-pinterest-p text-beige"></i
                  ></a>
                </li>
                <li>
                  <a href="#"
                    ><i class="fa-brands fa-youtube text-beige"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getSupportedLocales } from "@/util/i18n/supported-locales";
import LocalizedLink from "@/components/LocalizedLink";
import categoriesStoreModule from "../../views/categories/categoriesStoreModule";
import { ref, onUnmounted } from "vue";
import store from "@/store";
import router from "@/router";

export default {
  props: {
    closeMenu: Function,
  },
  components: {
    LocalizedLink,
  },
  data() {
    return {
      currentLocale: this.$route.params.locale === "ar" ? "عربي" : "English",
      locales: getSupportedLocales(),
    };
  },
  setup() {
    const categoriesData = ref(null);
    const HOME_APP_STORE_MODULE_NAME = "app-category";

    // const router = context.root.$router;

    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) {
      store.registerModule(HOME_APP_STORE_MODULE_NAME, categoriesStoreModule);
    } else {
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
          store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
      });
    }

    store
      .dispatch("app-category/fetchCategories", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        categoriesData.value = response.data.data;
      })
      .catch(() => {});

    return {
      categoriesData,
    };
  },
  methods: {
    changeLocale(e) {
      const path = this.$route.path.substring(
        this.$route.path.indexOf(this.$route.path) + 3,
        this.$route.path.length
      );
      const locale = e.code;
      if (path) {
        this.$router.push(`/${locale}/${path}`);
      } else {
        this.$router.push(`/${locale}`);
      }
      localStorage.setItem("X-localization", locale);
      window.location.reload();
    },
    getDomain() {
      const currentURL = window.location.href;
      const urlParts = new URL(currentURL);

      const protocol = urlParts.protocol;
      const hostname = urlParts.hostname;
      const port = urlParts.port;

      const domain = `${protocol}//${hostname}${port ? `:${port}` : ""}`;
      return domain;
    },

    url(link = "") {
      return this.getDomain() + "/" + this.$route.params.locale + "/" + link;
    },
  },
};
</script>
<style>
[dir="ltr"] .social_media li {
  margin-right: 15px;
}

[dir="rtl"] .social_media li {
  margin-left: 15px;
}

.social_media li a {
  &:hover i,
  &:focus i {
    color: var(--secondary);
    transition: all 0.4;
  }
}
</style>
