<template>
  <div>
    <div class="login">
      <PageBanner />
      <section class="py-5 bg-white">
        <div class="container">
          <div class="row">
            <div class="col-lg-10 m-auto">
              <div class="row">
                <login />

                <div class="col-lg-1"></div>
                <register />
              </div>
            </div>
          </div>
        </div>
      </section>
      <GreenBg />
    </div>
  </div>
</template>

<script>
import Login from "./Login.vue";
import Register from "./Register.vue";
import PageBanner from "../../components/PageBanner.vue";
import GreenBg from "@/components/BeforeFooter/GreenBg";
export default {
  components: {
    Login,
    Register,
    PageBanner,
    GreenBg,
  },
};
</script>