<template>
  <div class="shopping_cart">
    <PageBanner />
    <section class="py-5 bg-white">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <table class="table table-bordered shopping-summery text-center">
                <thead>
                  <tr class="main-heading">
                    <th scope="col" colspan="2">{{ $t("Product") }}</th>
                    <th scope="col">{{ $t("Price") }}</th>
                    <th scope="col">{{ $t("Color") }}</th>
                    <th scope="col">{{ $t("Size") }}</th>
                    <th scope="col">{{ $t("Stock Status") }}</th>
                    <th scope="col">{{ $t("quantity") }}</th>
                    <th scope="col">{{ $t("Remove") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(cart, key) in $store.state.cart" :key="key">
                    <td class="image product-thumbnail">
                      <img :src="cart.image" alt="#" width="100" />
                    </td>
                    <td class="product-des product-name">
                      <h5 class="product-name product-name-cart">
                        <LocalizedLink :to="'product/' + cart.slug">{{
                          cart.name
                        }}</LocalizedLink>
                      </h5>
                      <p class="font-xs">
                        {{ cart.description }}
                      </p>
                    </td>
                    <td class="price" data-title="Price">
                      <span>{{ cart.price }} </span>
                    </td>
                    <td
                      class="price"
                      data-title="Price"
                      :style="'background:' + cart.color"
                    >
                      <span></span>
                    </td>

                    <td class="price" data-title="Price">
                      <span>{{ cart.size }} </span>
                    </td>
                    <td class="text-center" data-title="Stock">
                      <span class="color3 font-weight-bold">In Stock</span>
                    </td>
                    <td class="text-right" data-title="Cart">
                      <div class="detail-qty m-auto">
                        <a class="qty-down" @click="quantityDown(cart)"
                          ><i class="fa-solid fa-minus"></i
                        ></a>
                        <span class="qty-val">{{ cart.quantity }}</span>
                        <a class="qty-up" @click="quantityUp(cart)"
                          ><i class="fa-solid fa-plus"></i
                        ></a>
                      </div>
                    </td>
                    <td class="action" data-title="Remove">
                      <a @click.prevent="removeFromCart(cart)" class="remove"
                        ><i class="fa-solid fa-trash">&nbsp;</i></a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="cart-action text-end">
              <a class="btn btn-primary mr-10 mb-sm-15" @click="updateCart"
                ><i class="fa-light fa-shuffle">&nbsp;</i
                >{{ $t("Proceed to chechkout") }}</a
              >
              <a href="/" class="btn btn-primary"
                ><i class="fa-light fa-bag-shopping"></i>
                {{ $t("Continue Shopping") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <GreenBg />
  </div>
</template>

<script>
import PageBanner from "../components/PageBanner.vue";
import GreenBg from "../components/BeforeFooter/GreenBg.vue";
import LocalizedLink from "../components/LocalizedLink.vue";
import router from "@/router";

export default {
  components: { PageBanner, GreenBg, LocalizedLink },

  methods: {
    increaseQuantity(state, itemToIncrease) {
      const index = state.cart.findIndex(
        (item) =>
          item.id === itemToIncrease.id &&
          item.size === itemToIncrease.size &&
          item.color === itemToIncrease.color
      );

      if (index > -1) {
        const product = state.cart[index];
        if (
          product.maxQuantity === "" ||
          product.quantity < product.maxQuantity
        ) {
          product.quantity += 1;
          state.cartCount += 1;
        }
      }
      this.commit("saveCart");
    },
    quantityUp(item) {
      this.$store.commit("increaseQuantity", item);
    },

    quantityDown(item) {
      this.$store.commit("decreaseQuantity", item);
    },

    removeFromCart(item) {
      this.$store.commit("removeFromCart", item);
    },
    updateCart() {
      window.localStorage.setItem(
        "cart",
        JSON.stringify(this.$store.state.cart)
      );
      window.localStorage.setItem("cartCount", this.$store.state.cartCount);
      router.push({ name: "Checkout" });
    },
  },
  setup() {
    // const router = context.root.$router;

    // Register module
    // if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) {
    //   store.registerModule(HOME_APP_STORE_MODULE_NAME, productsStoreModule);
    // } else {
    //   // UnRegister on leave
    //   onUnmounted(() => {
    //     if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
    //       store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
    //   });
    // }

    return {};
  },
};
</script>

<style lang="scss" scoped>
.remove {
  cursor: pointer;
  &:hover,
  &:focus {
    i {
      color: var(--secondary);
    }
  }
}
.btn-primary {
  &:hover,
  &:focus {
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
  }
}
</style>
