<template>
  <div class="productPage" v-if="productData">
    <PageBanner />

    <section id="main_info" class="bg-white">
      <div class="container py-5">
        <div class="row">
          <div class="col-md-6 product_images">
            <img
              :src="productData.couverture"
              alt="product Name"
              class="img-fluid img-custom-size"
            />
          </div>

          <div class="col-md-6">
            <h1 class="product-name">
              {{ productData.name }}
            </h1>
            <div class="price">SAR {{ productData.price }}</div>
            <!--ratings-->
            <div class="ratings py-2">
              <div class="d-flex align-items-center">
                <div class="ratings">
                  <star-rating
                    v-model="productData.averageRating"
                    active-color="#114956"
                    v-bind:star-size="20"
                    v-bind:read-only="true"
                    v-bind:show-rating="false"
                  />
                </div>
                <h5 class="review-count">
                  ({{ productData.reviews.length }} {{ $t("Customer Review") }})
                </h5>
              </div>
            </div>
            <!--ratings-->
            <div class="short_desc py-lg-4">
              {{ productData.description }}
            </div>

            <div
              class="attr-detail attr-color my-3"
              v-if="productData.productSizes.length > 0"
            >
              <strong class="mr-10 mb-3 d-block text-uppercase">{{
                $t("Size")
              }}</strong>
              <ul class="list-filter">
                <b-form-select
                  v-model="sizeSelected"
                  :options="uniqueIds"
                  @input="changeSize($event)"
                ></b-form-select>

                <!-- <li
             
                > -->
                <!-- <a
                    ><span @click="selectSize(productSize)">{{
                      productSize.size
                    }}</span></a
                  > -->
                <!-- </li> -->
              </ul>
              <strong class="mr-10 my-3 d-block text-uppercase">{{
                $t("Color")
              }}</strong>
              <ul class="list-filter color-filter" v-show="showColor == true">
                <!-- <li class="active"><a href="#" data-color="red"><span class="product-color-green"></span></a></li> -->
                <li
                  v-for="productSize in productSizeData"
                  :key="productSize.id"
                >
                  <a data-color="Yellow"
                    ><span
                      :style="'background:' + productSize.color"
                      @click="
                        changeColor(productSize.color, productSize.quantity)
                      "
                    ></span
                  ></a>
                </li>
                <!-- <li><a href="#" data-color="white"><span class="product-color-white"></span></a></li>
                                <li><a href="#" data-color="Orange"><span class="product-color-orange"></span></a></li>
                                <li><a href="#" data-color="Cyan"><span class="product-color-cyan"></span></a></li>
                                <li><a href="#" data-color="Red"><span class="product-color-red"></span></a></li> -->
                <!-- <li><a href="#" data-color="Purple"><span style="background: #000000"></span></a></li> -->
              </ul>
            </div>
            <!--add to cart-->
            <div
              class="detail-extralink my-3 d-flex align-items-center justify-content-between"
            >
              <div class="detail-qty radius w-auto">
                <a class="qty-down" @click="quantityDown"
                  ><i class="fa-solid fa-angle-down"></i
                ></a>
                <span class="qty-val">{{ count }}</span>
                <a class="qty-up" @click="quantityUp"
                  ><i class="fa-solid fa-angle-up"></i
                ></a>
              </div>
              <div class="product-extra-link2">
                <button
                  class="button btn btn-primary button-add-to-cart"
                  @click="addToCart"
                >
                  {{ $t("Add to cart") }}
                </button>
                <!-- <LocalizedLink
                  aria-label="Add To Wishlist"
                  class="
                    btn
                    action-btn
                    button-add-to-wish
                    btn-outline-primary
                    hover-up
                    text-uppercase
                    mx-2
                  "
                  :to="'whishlist'"
                  >{{ $t("Add To Wishlist") }}</LocalizedLink> -->
                <!-- <button
                  class="btn action-btn button-add-to-wish btn-outline-primary hover-up text-uppercase mx-2"
                >
                  {{ $t("Add To Wishlist") }}
                </button> -->
              </div>
            </div>
          </div>
        </div>
        <!--tabs-->
        <div class="row py-5">
          <div class="tab-style3">
            <ul class="nav nav-tabs text-uppercase">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="Description-tab"
                  data-bs-toggle="tab"
                  href="#Description"
                  >{{ $t("Description") }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="Additional-info-tab"
                  data-bs-toggle="tab"
                  href="#Additional-info"
                >
                  {{ $t("Additional info") }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="Reviews-tab"
                  data-bs-toggle="tab"
                  href="#Reviews"
                >
                  {{ $t("Reviews") }} ({{ productData.reviews.length }})</a
                >
              </li>
            </ul>
            <div class="tab-content shop_info_tab entry-main-content">
              <div class="tab-pane fade show active" id="Description">
                <div class="">
                  {{ productData.description }}
                </div>
              </div>
              <div class="tab-pane fade" id="Additional-info">
                <table class="font-md">
                  <tbody v-if="productData.detail">
                    {{
                      productData.detail
                    }}
                  </tbody>
                </table>
              </div>
              <div class="tab-pane fade" id="Reviews">
                <!--Comments-->
                <div class="comments-area">
                  <div class="row">
                    <div class="col-lg-8">
                      <h4 class="mb-30">
                        {{ $t("Customer questions & answers") }}
                      </h4>
                      <div class="comment-list">
                        <div
                          class="single-comment justify-content-between d-flex"
                          v-for="reviewItem in productData.reviews"
                        >
                          <div class="user justify-content-between d-flex">
                            <div class="thumb text-center">
                              <img
                                src="../../../public/assets/img/user_avatar.png"
                                alt=""
                              />
                              <h6>
                                <a href="#">{{ reviewItem.name }}</a>
                              </h6>
                              <p class="font-xxs">
                                Since
                                {{ getYearFromDate(reviewItem.created_at) }}
                              </p>
                            </div>
                            <div class="desc">
                              <div class="product-rate d-inline-block">
                                <star-rating
                                  v-model="reviewItem.rating"
                                  active-color="#114956"
                                  v-bind:star-size="20"
                                  v-bind:read-only="true"
                                  v-bind:show-rating="false"
                                />
                              </div>
                              <p>
                                {{ reviewItem.comment }}
                              </p>
                              <div class="d-flex justify-content-between">
                                <div class="d-flex align-items-center">
                                  <p class="font-xs mr-30 mb-0">
                                    <!-- December 4, 2020 at 3:12 pm -->
                                    {{ formatFullDate(reviewItem.created_at) }}
                                  </p>
                                  <!-- <a href="#" class="text-brand btn-reply"
                                    >{{ $t("Reply") }}
                                    <i class="fa fa-arrow-right"></i>
                                  </a> -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-lg-4">
                      <h4 class="mb-30">{{ $t("Customer reviews") }}</h4>
                      <div class="d-flex mb-30">
                        <div class="product-rate d-inline-block mr-15">
                          <div class="ratings">
                            <i class="fa fa-star rating-color"></i>
                            <i class="fa fa-star rating-color"></i>
                            <i class="fa fa-star rating-color"></i>
                            <i class="fa fa-star rating-color"></i>
                            <i class="fa fa-star"></i>
                          </div>
                        </div>
                        <h6>4.8 {{ $t("out of") }} 5</h6>
                      </div>
                      <div class="progress">
                        <span>5 star</span>
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style="width: 50%"
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          50%
                        </div>
                      </div>
                      <div class="progress">
                        <span>4 star</span>
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style="width: 25%"
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          25%
                        </div>
                      </div>
                      <div class="progress">
                        <span>3 star</span>
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style="width: 45%"
                          aria-valuenow="45"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          45%
                        </div>
                      </div>
                      <div class="progress">
                        <span>2 star</span>
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style="width: 65%"
                          aria-valuenow="65"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          65%
                        </div>
                      </div>
                      <div class="progress mb-30">
                        <span>1 star</span>
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style="width: 85%"
                          aria-valuenow="85"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          85%
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
                <!--comment form-->
                <div class="comment-form">
                  <h4 class="mb-15">{{ $t("Add a review") }}</h4>
                  <div class="product-rate d-inline-block mb-30">
                    <div class="ratings">
                      <!-- <i class="fa fa-star rating"></i>
                      <i class="fa fa-star rating" ></i>
                      <i class="fa fa-star rating" ></i>
                      <i class="fa fa-star rating" ></i>
                      <i class="fa fa-star rating" ></i> -->
                      <star-rating
                        v-model="review.rating"
                        active-color="#114956"
                        v-bind:star-size="25"
                        v-bind:show-rating="false"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-8 col-md-12">
                      <form
                        class="form-contact comment_form"
                        @submit.prevent="storeReview"
                      >
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <textarea
                                class="form-control w-100"
                                name="comment"
                                id="comment"
                                cols="30"
                                rows="9"
                                v-model="review.comment"
                                required
                                :placeholder="$t('Write Comment')"
                              ></textarea>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <input
                                class="form-control"
                                name="name"
                                id="name"
                                type="text"
                                v-model="review.name"
                                required
                                :placeholder="$t('Name')"
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <input
                                class="form-control"
                                name="email"
                                id="email"
                                type="email"
                                v-model="review.email"
                                required
                                :placeholder="$t('Email')"
                              />
                            </div>
                          </div>
                          <!-- <div class="col-sm-6">
                            <div class="form-group">
                              <input
                                class="form-control"
                                name="website"
                                id="website"
                                type="text"
                                :placeholder="$t('Website')"
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <input
                                class="form-control"
                                name="photo"
                                id="photo"
                                type="file"
                                :placeholder="$t('Upload Photo')"
                              />
                            </div>
                          </div> -->
                        </div>
                        <div class="form-group">
                          <button type="submit" class="button btn btn-primary">
                            {{ $t("Submit Review") }}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-12">
            <h3 class="text-uppercase text-center">
              {{ $t("Related Products") }}
            </h3>
          </div>
        </div> -->
      </div>
    </section>

    <!-- <section id="related_products"> -->
    <!-- <div class="container-fluid px-0">
        <div class="grid-products">
          <swiper class="swiper swiper-container" :options="swiperOption">
            <swiper-slide
              v-for="relatedProduct in relatedProductData"
              :key="relatedProduct.id"
            >
              <img
                :src="relatedProduct.couverture"
                alt="Furniture"
                class="img-fluid img-custom-size"
              />
              <div class="product-info d-block">
                <h4 class="product-name">
                  <LocalizedLink
                    :to="'product/' + relatedProduct.slug"
                    class="text-capitalize"
                    ><span @click="refetchData(relatedProduct)">{{
                      relatedProduct.name
                    }}</span></LocalizedLink
                  >
                </h4>
                <div class="price">SAR {{ relatedProduct.price }}</div>
              </div>
            </swiper-slide>

         pagination-->
    <!-- <div class="swiper-pagination" slot="pagination"></div>
          </swiper> -->

    <!-- <div class="swiper swiper-container" id="relatedProducts">
            <div class="swiper-wrapper" v-if="relatedProductData">
              <div
                v-for="relatedProduct in relatedProductData"
                :key="relatedProduct.id"
                class="swiper-slide"
              >
                <img
                  src="../../../public/assets/img/prods/prod1.png"
                  alt="Furniture"
                  class="img-fluid"
                />
                <div class="product-info d-block">
                  <h4 class="product-name">
                    <LocalizedLink
                      :to="'product/' + relatedProduct.slug"
                      class="text-capitalize"
                      ><span @click="refetchData(relatedProduct)">{{
                        relatedProduct.name
                      }}</span></LocalizedLink
                    >
                  </h4>
                  <div class="price">SAR {{ relatedProduct.price }}</div>
                </div>
              </div>
            </div> -->
    <!-- Add Pagination -->
    <!-- <div class="swiper-pagination"></div>
          </div> -->
    <!-- </div>
      </div>
    </section> -->

    <GreenBg />
  </div>
</template>

<script>
import PageBanner from "../../components/PageBanner.vue";
import GreenBg from "@/components/BeforeFooter/GreenBg";
import LocalizedLink from "@/components/LocalizedLink";
import { ref, onUnmounted, onMounted } from "vue";
import store from "@/store";
import router from "@/router";
import productsStoreModule from "./productsStoreModule";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BFormSelect } from "bootstrap-vue";
import axios from "../../libs/axios";
import Swal from "sweetalert2";
import StarRating from "vue-star-rating";
export default {
  components: {
    PageBanner,
    GreenBg,
    LocalizedLink,
    Swiper,
    SwiperSlide,
    BFormSelect,
    StarRating,
  },
  data() {
    const locale = this.$route.params.locale;
    const rtl = locale == "ar" ? true : false;
    return {
      review: {
        rating: "0",
        name: "",
        email: "",
        comment: "",
      },
      swiperOption: {
        rtl: rtl,
        slidesPerView: 4,
        spaceBetween: 40,
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 5000,
        },
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          // when window width is >= 480px
          992: {
            slidesPerView: 2.5,
            spaceBetween: 30,
          },
          // when window width is >= 640px
          1025: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        isColorActive: false,
      },
    };
  },

  methods: {
    formatFullDate(originalDate) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const date = new Date(originalDate);
      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    getYearFromDate(originalDate) {
      const date = new Date(originalDate);
      return date.getFullYear();
    },

    async storeReview() {
      try {
        // Create a FormData object to send the form data, including the image file
        const formData = new FormData();
        formData.append("product_id", this.productData.id);
        formData.append("name", this.review.name);
        formData.append("email", this.review.email);
        formData.append("comment", this.review.comment);
        formData.append("rating", this.review.rating);

        // Make a POST request to the API endpoint for updating
        const response = await axios.post(`/front/storereview`, formData);
        if (response.data.success) {
          Swal.fire({
            icon: "success",
            timer: 2000,

          });

          setTimeout(function () {
            window.location.reload();
          }, 2000);
          this.review.rating = 0;
          this.review.name = "";
          this.review.email = "";
          this.review.comment = "";

          location.reload();
          // this.fetchData();
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "error",
          text: error.response.data.message,
        });
        console.error("Error:", error.response.data.message);
      }
    },

    // addToWishlist() {
    //   if (store.state.user == null) {
    //     router.push({ name: "Login" });
    //   } else {
    //     this.addToWishlistData.id = this.productData.id;
    //     this.addToWishlistData.name = this.productData.name;
    //     this.addToWishlistData.price = this.productData.price;
    //     this.addToWishlistData.maxQuantity = this.maxQuantity;
    //     this.addToWishlistData.image = this.productData.couverture;
    //     this.addToWishlistData.description = this.productData.description;
    //     this.addToWishlistData.slug = this.productData.slug;

    //     console.log(this.addToWishlistData, "aaaaa");
    //     store
    //       .dispatch("app-detail-product/addToWishlist", this.addToWishlistData)
    //       .then((response) => {
    //         console.log(response);
    //       });
    //   }
    // },
  },

  // filters: {
  //   strippedContent: function (string) {
  //     return string.replace(/<\/?[^>]+>/gi, " ");
  //   },
  // },

  setup() {
    const productData = ref(null);
    const relatedProductData = ref(null);
    const HOME_APP_STORE_MODULE_NAME = "app-detail-product";
    const count = ref(1);
    const maxQuantity = ref(null);
    const sizeData = ref([]);
    const uniqueIds = ref([]);
    const productSizeData = ref(null);
    const showColor = ref(false);

    const sizeSelected = ref(null);
    const addToCartData = ref({
      id: "",
      name: "",
      quantity: "",
      price: "",
      color: "",
      size: "",
      maxQuantity: "",
      image: "",
      description: "",
      slug: "",
    });

    const addToWishlistData = ref({
      id: "",
      name: "",
      quantity: "",
      price: "",
      color: "",
      size: "",
      maxQuantity: "",
      image: "",
      description: "",
      slug: "",
    });
    const SizeSearched = ref([]);

    onMounted(async () => {
      await store
        .dispatch("app-detail-product/fetchProduct", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          productData.value = response.data.data;
          if (productData.value.productSizes.length != 0) {
            response.data.data.productSizes.forEach((elem) => {
              sizeData.value.push({
                value: elem.size,
                text: elem.size,
              });
            });
            sizeData.value.filter((element) => {
              const isDuplicate = uniqueIds.value.includes(element.text);

              if (!isDuplicate) {
                uniqueIds.value.push(element.text);

                return true;
              }

              return false;
            });
          } else {
            maxQuantity.value = productData.value.quantity;
          }

          relatedProducts(productData.value.category.slug);
        })
        .catch(() => {});
    });
    // const router = context.root.$router;

    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) {
      store.registerModule(HOME_APP_STORE_MODULE_NAME, productsStoreModule);
    } else {
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
          store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
      });
    }

    const relatedProducts = (slug) => {
      store
        .dispatch("app-detail-product/relatedProducts", slug)
        .then((response) => {
          relatedProductData.value = response.data.data;
        });
    };

    const quantityUp = () => {
      if (count.value < maxQuantity.value && maxQuantity.value != null) {
        count.value += 1;
      }
    };
    const quantityDown = () => {
      if (count.value >= 2) {
        count.value -= 1;
      }
    };

    const addToCart = () => {
      if (!sizeSelected.value) {
        return false;
      }
      const newItem = {
        id: productData.value.id,
        name: productData.value.name,
        color: addToCartData.value.color,
        size: sizeSelected.value, // Ajoutez la taille à l'objet newItem
        quantity: count.value,
        price: productData.value.price,
        maxQuantity: maxQuantity.value,
        image: productData.value.couverture,
        description: productData.value.description,
        slug: productData.value.slug,
      };

      console.log("send", newItem);
      store.commit("addToCartGlobal", newItem);

      count.value = 1;

      Swal.fire({
        icon: "success",
        showConfirmButton: false,
        timer: 500,
      });
    };

    const changeSize = (val) => {
      store
        .dispatch("app-detail-product/fetchProductSize", {
          id: productData.value.id,
          size: val,
        })
        .then((response) => {
          productSizeData.value = response.data.data;
          showColor.value = true;
          addToCartData.value.color = response.data.data[0].color;
          addToWishlistData.value.size = response.data.data[0].size;
          addToWishlistData.value.color = response.data.data[0].color;
          maxQuantity.value =
            maxQuantity.value != ""
              ? response.data.data[0].quantity
              : productData.quantity;
        })
        .catch(() => {});
    };

    const changeColor = (color, quantity) => {
      addToCartData.value.color = color;
      maxQuantity.value = quantity;
      count.value = 1;
    };

    const refetchData = (rproduct) => {
      productData.value = rproduct;
    };

    return {
      productData,
      relatedProductData,
      count,
      quantityUp,
      quantityDown,
      addToCartData,
      addToCart,

      refetchData,
      sizeData,
      sizeSelected,
      uniqueIds,
      changeSize,
      SizeSearched,
      productSizeData,
      showColor,
      maxQuantity,
      changeColor,
      addToWishlistData,
    };
  },
};
</script>

<style scoped>
[dir="rtl"] .btn-reply i {
  transform: rotate(180deg);
}
[dir="rtl"] .comments-area .thumb {
  margin-left: 20px;
  margin-right: 0;
}
.form-control[type="file"] {
  line-height: 32px;
  padding-right: 10px;
}
.btn-outline-primary {
  padding: 10px 40px;
}
.progress-bar {
  background-color: var(--secondary);
}
@media screen and (max-width: 767px) {
  .detail-extralink {
    flex-wrap: wrap;
  }
  .product-extra-link2 {
    display: flex;
  }
  .btn-outline-primary {
    padding: 10px 20px;
  }
}
</style>
