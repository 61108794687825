<template>
  <div class="new-product-section bg-ocean-blue-custom ">
    <div class="row" >
      <div class="col-md-6 bg_darkBeige bg-ocean-blue">
        <div class="title-product-custom">
          {{ $t("Products") }}
        </div>
        <div class="top-65">
          <h2 class="title-product-custom-desc">
            <!-- {{ $t("All our products can be customized to fit your space.") }} -->
            {{ $route.params.locale === "en" ? data.title_en : data.title_ar }}
          </h2>
        </div>
        <div class="top-65 width-95">
          <!-- {{
              $t(
                "Paragraphs and body typeface, for ease of identifying the font format, a default is set consisting of a group of model sentences that are text in a form close to the real text and not phrases such as: “Here there is text content”."
              )
            }} -->
          <span class="title-product-custom-desc-long">
            {{
              $route.params.locale === "en"
                ? data.description_en
                : data.description_ar
            }}
          </span>
        </div>
        <!-- <div class="call_to-action div-discover">
            <span class="long-arrow">&rarr;</span>
          </div> -->
        <div class="fleche-img-div">
          <LocalizedLink class="text-dark-custom " :to="'/allshop'">
          <img
            src="../../../public/assets/img/icons/product_fleche.png"
            style="width: 117px"
          />
          </LocalizedLink>
        </div>
      </div>

      <div
        class="col-md-6 bg-right-banner img-right-radius"
        :style="{ backgroundImage: 'url(' + data.image + ')' }"
      ></div>
    </div>
  </div>
</template>
<script>
import http from "../../libs/axios";
import LocalizedLink from "@/components/LocalizedLink";

export default {
  components: {LocalizedLink},
  data() {
    return {
      data: {},
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await http.get(
          process.env.VUE_APP_BASE_URL + "/api/front/productdecription"
        );
        this.data = response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
};
</script>
<style>
.img-right-radius {
  border-top-left-radius: 23px;
  border-bottom-left-radius: 23px;
}
</style>
